import { createSlice } from '@redux'

export const initialState = {
  orderFetched: false,
  mobileView: false,
  drawdownSuccess: false,
  repaymentSuccess: false,
  twoFaOpen: false,
  veriffOpen: false,
}

const uiStateHelperSlice = createSlice({
  name: 'uiStateHelper',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return payload
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = uiStateHelperSlice.actions

export const setFetchOrdersState = s => async (dispatch, getState) => {
  const { uiStateHelper } = getState()
  dispatch(set({ ...uiStateHelper, orderFetched: s }))
}

export const setMobileViewState = s => async (dispatch, getState) => {
  const { uiStateHelper } = getState()
  dispatch(set({ ...uiStateHelper, mobileView: s }))
}

export const setDrawdownSuccess = s => async (dispatch, getState) => {
  const { uiStateHelper } = getState()
  dispatch(set({ ...uiStateHelper, drawdownSuccess: s }))
}

export const setRepaymentSuccess = s => async (dispatch, getState) => {
  const { uiStateHelper } = getState()
  dispatch(set({ ...uiStateHelper, repaymentSuccess: s }))
}

export const setTwoFaOpen = s => async (dispatch, getState) => {
  const { uiStateHelper } = getState()
  dispatch(set({ ...uiStateHelper, twoFaOpen: s }))
}

export const setVeriffOpen = s => async (dispatch, getState) => {
  const { uiStateHelper } = getState()
  dispatch(set({ ...uiStateHelper, veriffOpen: s }))
}

export default uiStateHelperSlice.reducer
