import * as React from 'react'
import classnames from 'classnames'

import cls from './divider.module.scss'

const UikDivider = ({ className, direction, vertical, margin, ...rest }: UikDividerProps) => (
  <div
    className={classnames(
      {
        [cls.vertical]: vertical || direction === 'vertical',
        [cls.horizontal]: !(vertical || direction === 'vertical'),
        [cls.margin]: margin,
      },
      className
    )}
    {...rest}
  />
)

UikDivider.defaultProps = {
  className: null,
  direction: 'horizontal',
  margin: false,
  vertical: false,
}

export default UikDivider
