import api from '@api'
import { setLoans, setLoansDataFlag, setOverDueLoans } from './index.js'
import { pageSize } from './index.js'

export const getLoansData = () => async (dispatch, getState) => {
  var page = 1
  var doneFetching = false
  const loans = []
  try {
    await dispatch(setLoansDataFlag(false))
    while (!doneFetching) {
      const res = await api.get(`/v1/lendings/loans?page=${page}&per_page=${pageSize}`)
      if (res.data.length > 0) loans.push(...res.data)
      if (res.data.length < pageSize) doneFetching = true
      page++
    }
    await dispatch(setLoans(loans))
    await dispatch(
      setOverDueLoans(
        loans
          .map(loan => {
            if (loan?.sfLendingContract?.sfContractRepayments?.length > 0) {
              const lendingContractName = loan.sfLendingContract.name
              return loan.sfLendingContract.sfContractRepayments
                .map(repayment => {
                  if ('sfContractDue' in repayment) {
                    if (repayment.sfContractDue.isDueFulfilled === false) {
                      return { contractName: lendingContractName, ...repayment.sfContractDue }
                    } else {
                      return null
                    }
                  } else {
                    return null
                  }
                })
                .filter(overDueList => overDueList !== null)
            } else {
              return null
            }
          })
          .filter(overDueList => overDueList?.length > 0) || []
      )
    )
    await dispatch(setLoansDataFlag(true))
    return loans
  } catch (error) {
    doneFetching = true
    console.error(error)
  }
}

export default getLoansData
