import axios from 'axios'
import humps from 'humps'

import getBaseUrl from './getBaseUrl'
import { handleResponseError } from './responseInterceptor'
import { handleRequestSuccess } from './requestInterceptor'

import { buildVersion, appVersion } from '../version'
export const { BASE_URL, AUTH_BASE_URL } = getBaseUrl()

const config = {
  timeout: 60000,
  transformRequest: [
    data => {
      if (data) {
        // if ('isKikka' in data) {
        //   delete data.isKikka
        //   return data
        // } else {
        return humps.decamelizeKeys(data)
        //}
      } else {
        return data
      }
    },
    ...axios.defaults.transformRequest,
  ],
  transformResponse: [...axios.defaults.transformResponse, data => humps.camelizeKeys(data)],
  withCredentials: true,
  headers: {
    'X-Propell-Name': 'Propell',
    'X-Propell-Platform': 'Web',
    'X-Propell-Version': appVersion,
    'X-Propell-Build': buildVersion,
  },
}

export const auth = axios.create({
  ...config,
  baseURL: AUTH_BASE_URL,
})

export const api = axios.create({
  ...config,
  baseURL: BASE_URL,
})

export const ext = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.response.use(
  res => res,
  error => handleResponseError(error)
)

api.interceptors.request.use(
  config => handleRequestSuccess(config),
  error => Promise.reject(error)
)

export default api
