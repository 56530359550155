import { createSlice } from '@redux'
import { replace } from 'connected-react-router'

export const initialState = []

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      state.push({ ...action.payload, id: state.length })
    },
    removeAlert: (state, action) => {
      return state.filter(({ id }) => id !== action.payload)
    },
  },
})

export const { addAlert, removeAlert } = alertsSlice.actions

export default alertsSlice.reducer

export const setAlerts = () => (dispatch, getState) => {
  const {
    router: {
      location: { state },
    },
  } = getState()

  if (state?.unauthorized) {
    dispatch(
      addAlert({
        message: `You've been logged out because of a security issue. Please login again.`,
        error: true,
        dismiss: true,
        top: true,
      })
    )

    // clear state - notification show only once
    dispatch(
      replace({
        state: {
          unauthorized: undefined,
        },
      })
    )
  }

  if (state?.invalidUrl) {
    dispatch(
      addAlert({
        message: `The page you've tried to reach is not available`,
        error: true,
        dismiss: true,
        top: true,
      })
    )
    // clear state - notification show only once
    dispatch(
      replace({
        state: {
          invalidUrl: undefined,
        },
      })
    )
  }

  if (state?.passwordResetSuccess) {
    dispatch(
      addAlert({
        message: `Password reset. Please log in with your new password`,
        success: true,
        dismiss: false,
        top: true,
      })
    )
    // clear state - notification show only once
    dispatch(
      replace({
        state: {
          passwordResetSuccess: undefined,
        },
      })
    )
  }

  if (state?.followInviteSuccess) {
    dispatch(
      addAlert({
        message: `Account successfully created. Please log in with your new password`,
        success: true,
        dismiss: false,
        top: true,
      })
    )
    // clear state - notification show only once
    dispatch(
      replace({
        state: {
          followInviteSuccess: undefined,
        },
      })
    )
  }

  if (state?.emailConfirmed) {
    dispatch(
      addAlert({
        message: `Account successfully confirmed. Please log in with your password`,
        success: true,
        dismiss: false,
        top: true,
      })
    )
    // clear state - notification show only once
    dispatch(
      replace({
        state: {
          emailConfirmed: undefined,
        },
      })
    )
  }
}
