import React from 'react'
import cls from './index.module.scss'
import { generateCurve } from './curveFunctions'

//const boxSz = { w: 378, h: 186, topMargin: 32, bottomMargin: 32 }
//const boxSz = { w: 390, h: 84, topMargin: 12, bottomMargin: 12 }
//const boxSz = { w: 390, h: 115, topMargin: 20, bottomMargin: 28 }
const boxSz = { w: 390, h: 115, topMargin: 5, bottomMargin: 5 }

const generatePoints = (line, posXs, scale, fullHeight, offset) => {
  return line.map((v, idx) => {
    const y = v >= 0 ? v * scale : Math.abs(v) * scale
    const ypos = v >= 0 ? fullHeight - y - offset : fullHeight - offset + y
    return [posXs[idx], ypos > 0 ? ypos : boxSz.topMargin / 2]
  })
}

const SvgCurve = (line, posXs, scale, fullHeight, offset, color, idx) => {
  const mLine = line
  const points = generatePoints(mLine, posXs, scale, fullHeight, offset)
  const curvePoints = generateCurve(points)

  return (
    <path
      key={`path#${idx}`}
      d={curvePoints}
      fill="none"
      strokeOpacity="0.8"
      stroke={color}
      strokeWidth="4"
      filter="url(#lineDropShadow)"
    />
  )
}

export const SvgCurveChartNoTooltips = ({ instanceId, lines: mLines, max, min }) => {
  const scale = (boxSz.h - (boxSz.bottomMargin + boxSz.topMargin)) / (max - min)
  const offset = min * scale * -1 + boxSz.topMargin
  const width = boxSz.w / (mLines[0]?.length - 0.5)
  const Xs = mLines[0]?.length > 0 ? mLines[0].map((o, idx) => idx + 1) : []

  const posXs = Xs.map((v, idx) => {
    return idx * width + width / 4
  })
  const lines = mLines.map(line => line.map(pt => pt.value))

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${boxSz.w} ${boxSz.h}`} className={cls.svgContainer}>
      <defs>
        <filter id="lineDropShadow" colorInterpolationFilters="sRGB">
          <feGaussianBlur stdDeviation="0.8" />
          <feDropShadow dx="3" dy="3" stdDeviation="3" floodColor="#EF404A" floodOpacity="0.4" />
        </filter>
      </defs>
      {lines.map((line, idx) => {
        return SvgCurve(line, posXs, scale, boxSz.h, offset, '#EF404A', idx)
      })}
    </svg>
  )
}

export default SvgCurveChartNoTooltips
