import { useState, useEffect } from 'react'
import { throttle } from 'lodash'

const getBreakpoint = width => {
  if (width < 400) {
    return 0
  } else if (width >= 400 && width <= 600) {
    return 1
  } else if (width >= 600 && width <= 800) {
    return 2
  } else if (width >= 800 && width <= 1000) {
    return 3
  } else if (width >= 1000 && width <= 1200) {
    return 4
  } else if (width >= 1200) {
    return 5
  }
}

const useBreakpoint2 = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = throttle(() => setBreakpoint(getBreakpoint(window.innerWidth)), 200)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return breakpoint
}
export default useBreakpoint2
