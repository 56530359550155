import React, { useState, useEffect } from 'react'
import {
  getLoansData,
  getTransactionsData,
  setTransactionsFlag,
  setLoansDataFlag,
  getLoansConsolidationRequest,
  getLoansPrecheckPending,
} from '@features/tradeLendings'
import { useSelector, useAction } from '@redux'

export const PropellTradeLendingDataCtx = React.createContext(null)
export const usePropellTradeLendingDataCtx = () => {
  const {
    loansDataRdy,
    apiLoans,
    loans,
    propellTradeLoans,
    hasOverDue,
    loanFilter,
    setLoanFilter,
    LoanType,
    refreshLoanData,
    refreshLoanAfterLoanRepayment,
    noPendingLoan,
    pendingLoansStatus,
    lastRequestLessThan10mins,
    refinanceRequestSuccess,
    setRefinanceRequestSuccess,
    disable6mLoc,
    disable12mLoc,
    hasDelinquent,
    totalDelinquentAmountWithFee,
    hasContractedLoans,
  } = React.useContext(PropellTradeLendingDataCtx)
  return {
    loansDataRdy,
    apiLoans,
    loans,
    propellTradeLoans,
    hasOverDue,
    loanFilter,
    setLoanFilter,
    LoanType,
    refreshLoanData,
    refreshLoanAfterLoanRepayment,
    noPendingLoan,
    pendingLoansStatus,
    lastRequestLessThan10mins,
    refinanceRequestSuccess,
    setRefinanceRequestSuccess,
    disable6mLoc,
    disable12mLoc,
    hasDelinquent,
    totalDelinquentAmountWithFee,
    hasContractedLoans,
  }
}

// fetch all transactions based on provide loans Ids array
const fetchAllTransactions = async (ids, fetchTransactionsData) => {
  const len = ids.length
  for (let i = 0; i < ids.length; i++) {
    await fetchTransactionsData(ids[i], i + 1 === len)
  }
}

const PropellTradeLendingDataProvider = ({ children }) => {
  const apiLoans = useSelector(({ tradeLending }) => tradeLending.loans)
  const apiConsolidatedLoans = useSelector(({ tradeLending }) => tradeLending.consolidatedLoans)
  const loansDataFlag = useSelector(({ tradeLending }) => tradeLending.loansDataFlag)
  const account = useSelector(({ business }) => business?.sfAccount)
  const lastLoanRequestedAt = useSelector(({ business }) => business.sfFacilityLending?.lastLoanRequestedAt)
  const sfFacilityLendings = useSelector(({ business }) => business?.sfAccount?.sfFacilityLendings)
  const last12mLoanRequestedAt = useSelector(
    ({ business }) => business.sfFacilityLending12Month?.lastLoanRequestedAt
  )
  const fetchLoansData = useAction(getLoansData)
  const fetchLoansConsolidationData = useAction(getLoansConsolidationRequest)
  const fetchTransactionsData = useAction(getTransactionsData)
  const fetchTransactionsDataFlg = useAction(setTransactionsFlag)
  const fetchGetLoansPendingPrecheck = useAction(getLoansPrecheckPending)
  const setLoansDataFlagCall = useAction(setLoansDataFlag)
  //const convertsLoans2Pages = useAction(loans2Pages)
  const [lastRequestLessThan10mins, setLastRequestLessThan10mins] = useState(true)
  const [loansDataRdy, setLoansDataRdy] = useState(false)
  const [hasOverDue, setHasOverDue] = useState(false)
  const [loans, setLoans] = useState([])
  const [propellTradeLoans, setPropellTradeLoans] = useState([])
  const LoanType = { all: 0, overDue: 1, paidInFull: 2 }
  const [loanFilter, setLoanFilter] = useState(LoanType.all)
  const [noPendingLoan, setNoPendingLoan] = useState(false)
  const [pendingLoansStatus, setPendingLoansStatus] = useState([])
  const [refinanceRequestSuccess, setRefinanceRequestSuccess] = useState(false)
  const [disable6mLoc, setDisable6mLoc] = useState(false)
  const [disable12mLoc, setDisable12mLoc] = useState(false)
  const [hasDelinquent, setHasDelinquent] = useState(false)
  const [totalDelinquentAmountWithFee, setTotalDelinquentAmountWithFee] = useState(0)
  const [hasContractedLoans, sethasContractedLoans] = useState(false)
  // refresh loan record if last repayment done more 10s
  const refreshLoanAfterLoanRepayment = async handleFunction => {
    const lastRepaymentTime = localStorage.getItem('lastLoanRepaymentTime')
    //console.log(' lastRepaymentTime ' + lastRepaymentTime)
    if (lastRepaymentTime) {
      const diffInSecond = (new Date().getTime() - lastRepaymentTime) / 1000
      //console.log(' diffInSecond ' + diffInSecond)
      if (diffInSecond > 30) {
        localStorage.removeItem('lastLoanRepaymentTime')
        localStorage.removeItem('lastLoanRepaymentIdx')
        handleFunction()
      }
    }
  }
  const updatePendingLoansStatus = async () => {
    const pendingStatus = []
    for (var i = 0; i < sfFacilityLendings?.length; i++) {
      const r = sfFacilityLendings[i]
      const rc = await fetchGetLoansPendingPrecheck({
        loanType:
          r?.recordType === 'lending_6month'
            ? '6 month'
            : r?.recordType === 'lending_12month'
            ? '12 month'
            : r?.recordType === 'business_loan'
            ? 'business loan'
            : r?.recordType === 'lending_24month'
            ? '24 month'
            : r?.recordType === 'lending_36month'
            ? '36 month'
            : null,
      })
      if (rc?.error) {
        pendingStatus.push({ name: r.recordType, status: rc?.error })
      }
    }
    setPendingLoansStatus(pendingStatus)
  }

  // on page load, fetch all loans record
  useEffect(() => {
    ;(async () => {
      if (!loansDataFlag) {
        await fetchTransactionsDataFlg(false)
        await updatePendingLoansStatus()
        await fetchLoansData()
        await fetchLoansConsolidationData()
      }
    })()
    // eslint-disable-next-line
  }, [])

  // on redux loans data flg set, start fetching all transactions record
  useEffect(() => {
    ;(async () => {
      if (loansDataFlag && !loansDataRdy && loans.length > 0) {
        setLoansDataRdy(true)
        //await convertsLoans2Pages()
        if (loans?.length > 0) {
          const loansIds = loans.map(({ id, sfLendingContract }) => {
            if (sfLendingContract) return id
            else return undefined
          })
          fetchAllTransactions(
            loansIds.filter(id => id !== undefined),
            fetchTransactionsData
          )
        } else {
          fetchTransactionsDataFlg(true)
        }
      }
    })()
    // eslint-disable-next-line
  }, [loansDataFlag, loansDataRdy, loans])

  // on every api loan record update or filter state changed, update loans state variable to trigger UI to rerender
  useEffect(() => {
    var allLoanOk = true
    let facilitiesHasDelinquent
    let mTotalDelinquentAmountWithFee = 0
    const mLoans = apiLoans
      ?.map(loan => {
        const today = new Date()
        const fundedActive =
          (loan?.status === 'Funded' && loan?.sfLendingContract?.status === 'Active') ||
          loan?.status === 'In Progress' ||
          loan?.status === 'External(Under review)'
        const paidInFull = loan?.status === 'Funded' && loan?.sfLendingContract?.status === 'Paid In Full'
        const nextDueDate = fundedActive ? new Date(loan?.sfLendingContract?.nextPaymentDueDate) : today
        const payoffAmountToday = loan.sfLendingContract?.payoffAmountToday
        const sfid = loan.sfLendingContract?.sfid
        //const isRefinanced = loan.sfLendingContract?.isRefinanced
        const refinanceStatus = {
          consolidatable: loan.name.slice(0, 2) === 'LC' || loan?.status === 'External(Under review)',
          underRefinance: false || loan?.status === 'External(Under review)',
          refinanceName: '',
        }
        const nextPaymentAmount =
          Number(loan.sfLendingContract?.nextPaymentDueAmount) > Number(payoffAmountToday)
            ? payoffAmountToday
            : loan.sfLendingContract?.nextPaymentDueAmount

        const repaymentRecords =
          loan?.status === 'Funded' ? loan?.sfLendingContract?.sfContractRepayments || [] : []
        const dueRecords = repaymentRecords.filter(r => r?.sfContractDue).map(r => r?.sfContractDue) || []
        const hasDelinquent = parseFloat(loan?.sfLendingContract?.totalDelinquentAmountWithFee || 0) > 0
        const overDues = hasDelinquent ? dueRecords?.filter(r => r.isDueFulfilled === false) : []
        const overDueState = hasDelinquent ? 'overdue' : 'unknown'
        facilitiesHasDelinquent |= hasDelinquent
        mTotalDelinquentAmountWithFee += parseFloat(
          loan?.sfLendingContract?.totalDelinquentAmountWithFee || 0
        )
        const nameAppContract = loan.sfLendingContract?.name
        var inbounddPaymentsStatus =
          loan?.sfLendingContract?.sfInboundPayments.map(payment => payment.paymentStatus) || []
        var incompletedPayments = 0
        inbounddPaymentsStatus.forEach(p => {
          if (!(p === 'Processed' || p === 'Error' || p === 'Void' || p === 'Reversed')) incompletedPayments++
        })
        const settlementAmount = loan?.sfLendingContract?.payoffAmountToday
        allLoanOk =
          allLoanOk && (loan.status === 'Funded' || loan.status === 'Voided' || loan.status === 'Rejected')

        if (apiConsolidatedLoans?.length > 0 && refinanceStatus.consolidatable) {
          for (var i = 0; i < apiConsolidatedLoans?.length; i++) {
            const rl = apiConsolidatedLoans[i]
            if (
              rl?.refinanceLoan1 === sfid ||
              rl?.refinanceLoan2 === sfid ||
              rl?.refinanceLoan3 === sfid ||
              rl?.refinanceLoan4 === sfid ||
              rl?.refinanceLoan5 === sfid
            ) {
              refinanceStatus.underRefinance = true
              refinanceStatus.refinanceName = rl?.name
              break
            }
          }
        }

        return {
          ...loan,
          fundedActive,
          paidInFull,
          nextDueDate,
          nextPaymentAmount,
          nameAppContract,
          overDues,
          overDueState,
          hasPaymentInProgress: incompletedPayments > 0,
          settlementAmount: settlementAmount,
          settlementAmountFormatted: Number(settlementAmount).toFixed(2),
          refinanceStatus,
        }
      })
      .filter(
        l =>
          l.sfLendingContract?.isRefinanced === false ||
          l?.isExternalRefinanced === false ||
          l?.status === 'In Progress'
      )
    const contractedLoans = mLoans.filter(l => l?.sfLendingContract)
    sethasContractedLoans(contractedLoans?.length > 0)
    //console.log('mLoans.len' + mLoans?.length)
    setHasDelinquent(facilitiesHasDelinquent)
    // setTotalDelinquentAmount(mTotalDelinquentAmount)
    setTotalDelinquentAmountWithFee(mTotalDelinquentAmountWithFee)
    if (allLoanOk !== noPendingLoan) setNoPendingLoan(allLoanOk)
    setHasOverDue(mLoans.find(l => l?.overDueState === 'overdue') !== undefined)
    if (loanFilter === LoanType.all) {
      setLoans(mLoans || [])
    } else if (loanFilter === LoanType.overDue) {
      const overDueLoans = mLoans.filter(loan => {
        return loan?.overDues?.length > 0
      })
      setLoans(overDueLoans || [])
    } else if (loanFilter === LoanType.paidInFull) {
      const paidInFullLoans = mLoans.filter(loan => {
        return loan?.paidInFull
      })
      setLoans(paidInFullLoans || [])
    } else {
      setLoans(mLoans || [])
    }
    const TlLoans = apiLoans
      ?.map(loan => {
        const today = new Date()
        const fundedActive =
          (loan?.status === 'Funded' && loan?.sfLendingContract?.status === 'Active') ||
          loan?.status === 'In Progress' ||
          loan?.status === 'External(Under review)'
        const paidInFull = loan?.status === 'Funded' && loan?.sfLendingContract?.status === 'Paid In Full'
        const nextDueDate = fundedActive ? new Date(loan?.sfLendingContract?.nextPaymentDueDate) : today
        const payoffAmountToday = loan.sfLendingContract?.payoffAmountToday
        const sfid = loan.sfLendingContract?.sfid
        //const isRefinanced = loan.sfLendingContract?.isRefinanced
        const refinanceStatus = {
          consolidatable: loan.name.slice(0, 2) === 'LC' || loan?.status === 'External(Under review)',
          underRefinance: false || loan?.status === 'External(Under review)',
          refinanceName: '',
        }
        const nextPaymentAmount =
          Number(loan.sfLendingContract?.nextPaymentDueAmount) > Number(payoffAmountToday)
            ? payoffAmountToday
            : loan.sfLendingContract?.nextPaymentDueAmount

        const repaymentRecords =
          loan?.status === 'Funded' ? loan?.sfLendingContract?.sfContractRepayments || [] : []
        const dueRecords = repaymentRecords.filter(r => r?.sfContractDue).map(r => r?.sfContractDue) || []
        const hasDelinquent = parseFloat(loan?.sfLendingContract?.totalDelinquentAmountWithFee || 0) > 0
        const overDues = hasDelinquent ? dueRecords?.filter(r => r.isDueFulfilled === false) : []
        const overDueState = hasDelinquent ? 'overdue' : 'unknown'
        facilitiesHasDelinquent |= hasDelinquent
        mTotalDelinquentAmountWithFee += parseFloat(
          loan?.sfLendingContract?.totalDelinquentAmountWithFee || 0
        )
        const nameAppContract = loan.sfLendingContract?.name
        var inbounddPaymentsStatus =
          loan?.sfLendingContract?.sfInboundPayments.map(payment => payment.paymentStatus) || []
        var incompletedPayments = 0
        inbounddPaymentsStatus.forEach(p => {
          if (!(p === 'Processed' || p === 'Error' || p === 'Void' || p === 'Reversed')) incompletedPayments++
        })
        const settlementAmount = loan?.sfLendingContract?.payoffAmountToday
        allLoanOk =
          allLoanOk && (loan.status === 'Funded' || loan.status === 'Voided' || loan.status === 'Rejected')

        if (apiConsolidatedLoans?.length > 0 && refinanceStatus.consolidatable) {
          for (var i = 0; i < apiConsolidatedLoans?.length; i++) {
            const rl = apiConsolidatedLoans[i]
            if (
              rl?.refinanceLoan1 === sfid ||
              rl?.refinanceLoan2 === sfid ||
              rl?.refinanceLoan3 === sfid ||
              rl?.refinanceLoan4 === sfid ||
              rl?.refinanceLoan5 === sfid
            ) {
              refinanceStatus.underRefinance = true
              refinanceStatus.refinanceName = rl?.name
              break
            }
          }
        }

        return {
          ...loan,
          fundedActive,
          paidInFull,
          nextDueDate,
          nextPaymentAmount,
          nameAppContract,
          overDues,
          overDueState,
          hasPaymentInProgress: incompletedPayments > 0,
          settlementAmount: settlementAmount,
          settlementAmountFormatted: Number(settlementAmount).toFixed(2),
          refinanceStatus,
        }
      })
      .filter(
        l =>
          l.sfLendingContract?.isRefinanced === false ||
          l?.isExternalRefinanced === false ||
          // l?.status === 'In Progress' ||
          l?.name.slice(0, 2) === 'TL'
      )
    setLoans(TlLoans)
    setPropellTradeLoans(TlLoans)
    // eslint-disable-next-line
  }, [apiLoans, apiConsolidatedLoans, loanFilter])

  // logic to monitor last loan drawdown request
  useEffect(() => {
    if (lastLoanRequestedAt || last12mLoanRequestedAt) {
      const currentTime = new Date().getTime() / 1000
      const last6MRequestTime = new Date(lastLoanRequestedAt).getTime() / 1000 || 0
      const last12MRequestTime = new Date(last12mLoanRequestedAt).getTime() / 1000 || 0
      const lastRequestTime = last12MRequestTime >= last6MRequestTime ? last12MRequestTime : last6MRequestTime
      const diffTimeSec = currentTime - lastRequestTime
      const diffTimeInMins = diffTimeSec / 60
      //console.log('setLastRequestLessThan10mins = ' + diffTimeInMins)
      setLastRequestLessThan10mins(diffTimeInMins < 10)
    } else {
      setLastRequestLessThan10mins(false)
    }
  }, [lastLoanRequestedAt, last12mLoanRequestedAt])

  useEffect(() => {
    if (parseFloat(account?.overallLoanAvailable6month) < 1000) setDisable6mLoc(true)
    if (parseFloat(account?.overallLoanAvailable12month) < 5000) setDisable12mLoc(true)
  }, [account])

  return (
    <PropellTradeLendingDataCtx.Provider
      value={{
        loansDataRdy,
        apiLoans,
        loans,
        propellTradeLoans,
        hasOverDue,
        loanFilter,
        setLoanFilter,
        LoanType,
        refreshLoanData: async () => {
          // reset api and local state variable re-fetch loans transactions data again
          await setLoansDataFlagCall(false)
          setLoansDataRdy(false)
          setLoans([])
          await fetchTransactionsDataFlg(false)
          // fetch loans data from API
          await updatePendingLoansStatus()
          await fetchLoansData()
          await fetchLoansConsolidationData()
          // after api call, LoansDataFlag will set and trigger effect to reload loans state variable again
        },
        refreshLoanAfterLoanRepayment,
        noPendingLoan,
        pendingLoansStatus,
        lastRequestLessThan10mins,
        refinanceRequestSuccess,
        setRefinanceRequestSuccess,
        disable6mLoc,
        disable12mLoc,
        hasDelinquent,
        totalDelinquentAmountWithFee,
        hasContractedLoans,
      }}
    >
      {children}
    </PropellTradeLendingDataCtx.Provider>
  )
}
export default PropellTradeLendingDataProvider
