import * as React from 'react'
import classnames from 'classnames'

import cls from './tutorial-list.module.scss'

const TutorialList = ({ children, className, ...rest }: TutorialListProps) => (
  <div className={classnames(cls.wrapper, className)} {...rest}>
    {children}
  </div>
)

TutorialList.defaultProps = {
  className: null,
}

export default TutorialList
