import React from 'react'
import cls from './index.module.scss'

//const tooltipSz = { width: 30, height: 20 }

export const SvgBarChart = ({ instanceId, data, max, min, customBarGap, customwidth, showTooltip }) => {
  const boxSz = { w: 400, h: 200 }
  const rects = []
  boxSz.w = data.length > 16 ? 10 * data.length : 300
  //console.log('max : min = ' + max + ':' + min)
  //const vMarginPercent = 0.05
  const vMarginPercent = 0.08
  const scale = boxSz.h / (max - min + 2 * vMarginPercent)
  //console.log('scale ' + scale)
  const vMargin = vMarginPercent * scale
  const barGap = customBarGap ? customBarGap : 13
  const width = customwidth ? customwidth : 4.5

  //const totalWidth = (width + barGap) * data?.length * (7.4 / 10)
  const totalWidth = (width + barGap) * data?.length + barGap
  data.forEach(({ v, label, pol, dateLabel }, idx) => {
    const id = `rect${idx}`
    const height = pol ? v * scale : v * scale * -1
    //console.log('height: ' + height)
    const offset = min * scale * -1

    const pos = { x: idx * (barGap + width) + barGap, y: pol ? boxSz.h - height - offset : boxSz.h - offset }
    pos.y = pos.y - vMargin
    const tooltip = {
      x: idx * barGap,
      y: boxSz.h - height - 20,
      width: label.length * 16,
      height: 30,
      label,
      v,
      color: 'lightblue',
    }

    // const mdate = parse(dateLabel, 'yyyyMMdd', today)
    // const mdateOfWk = dateOfWk[mdate?.getDay()]
    //console.log('date' + dateLabel)
    //console.log('date' + mdateOfWk + ':' + mdate)
    rects.push({ id, width, height, pos, pol, tooltip, date: { label: dateLabel || '', y: boxSz.h - 2 } })
  })
  //console.log('data.length' + data.length)
  //console.log('boxSz.length' + data.length)
  //console.log(' reacts : ' + JSON.stringify(rects))
  const barColor = '#00A0E9'
  const barColorNeg = 'red'
  return (
    <svg
      height="100%"
      //width={`${totalWidth}px`}
      viewBox={`0 0 ${totalWidth < boxSz.w ? boxSz.w : totalWidth} ${boxSz.h}`}
      className={cls.svgContainer}
    >
      <defs>
        <filter id="SvgMultiCirclesGaugeshawdow" colorInterpolationFilters="sRGB">
          <feGaussianBlur stdDeviation="0.5" />
          <feDropShadow dx="1" dy="1" stdDeviation="3" floodColor="#bdbdbd" floodOpacity="0.4" />
        </filter>
        <filter id="shadow" colorInterpolationFilters="sRGB" x="-0.3" y="-0.3" width="150%" height="150%">
          <feDropShadow dx="0.1" dy="0.1" stdDeviation="3" floodOpacity="0.3" />
        </filter>
      </defs>
      {rects.map(({ id, width, height, tooltip, pos, pol }) => {
        return (
          <g key={`${instanceId}bar#${id}`} id={`${instanceId}bar#${id}`}>
            <rect
              x={pos.x}
              y={pos.y}
              width={width}
              height={height}
              //stroke={barColor}
              fill={pol ? barColor : barColorNeg}
              strokeWidth="0"
              onPointerEnter={() => {
                if (showTooltip) showTooltip(tooltip?.label)
              }}
              onPointerLeave={() => {
                if (showTooltip) showTooltip('')
              }}
              //rx="5px"
            />
          </g>
        )
      })}
      {rects.map(({ pos, date }, idx) => {
        return (
          <text
            key={`labelBB${idx}`}
            x={pos.x}
            y={date.y}
            fill={'#959AA9'}
            style={{
              fontFamily: 'Roboto',
              fontSize: 10,
              fontWeight: '400',
            }}
          >
            {date.label}
          </text>
        )
      })}

      {/* {rects.map(({ id, width, pos, pol }, idx) => {
        //const alignRight = idx > 20 //idx > (rects.length * 2) / 3
        const alignRight = idx > rects.length / 2
        const fontSz = 8
        const label = data[idx].label
        const rectWidth = (label?.length * fontSz * 2) / 3
        const xoffset = width + width / 2
        const posX = alignRight ? pos.x - rectWidth - xoffset : pos.x + xoffset + fontSz / 3
        const rectHeight = tooltipSz.height
        const trianglePos = alignRight
          ? [
              { x: posX, y: pos.y },
              { x: posX + rectWidth, y: pos.y },
              { x: posX + rectWidth, y: pos.y + rectHeight / 2 - fontSz / 2 },
              { x: posX + rectWidth + fontSz / 2, y: pos.y + rectHeight / 2 },
              { x: posX + rectWidth, y: pos.y + rectHeight / 2 + fontSz / 2 },
              { x: posX + rectWidth, y: pos.y + rectHeight },
              { x: posX, y: pos.y + rectHeight },
            ]
          : [
              { x: posX + rectWidth, y: pos.y },
              { x: posX + rectWidth, y: pos.y + rectHeight },
              { x: posX, y: pos.y + rectHeight },
              { x: posX, y: pos.y + rectHeight / 2 - fontSz / 2 },
              { x: posX - fontSz / 2, y: pos.y + rectHeight / 2 },
              { x: posX, y: pos.y + rectHeight / 2 + fontSz / 2 },
              { x: posX, y: pos.y },
            ]

        return (
          <g key={`tooltips${id}`} id={id} visibility="hidden">
            <set
              attributeName="visibility"
              to="visible"
              begin={`${instanceId}bar#${id}.mouseenter`}
              end={`${instanceId}bar#${id}.mouseleave`}
            />
            <polygon
              //points={`${trianglePos[0].x} ${trianglePos[0].y},${trianglePos[1].x} ${trianglePos[1].y},${trianglePos[2].x} ${trianglePos[2].y}`}
              points={`${trianglePos[0].x} ${trianglePos[0].y},${trianglePos[1].x} ${trianglePos[1].y},${trianglePos[2].x} ${trianglePos[2].y}
              ,${trianglePos[3].x} ${trianglePos[3].y}
              ,${trianglePos[4].x} ${trianglePos[4].y}
              ,${trianglePos[5].x} ${trianglePos[5].y}
              ,${trianglePos[6].x} ${trianglePos[6].y}
              `}
              stroke="none"
              fill={'white'}
              strokeWidth="5"
              filter="url(#shadow)"
            />
            <text
              x={posX + fontSz / 2}
              y={pos.y + (tooltipSz.height * 2) / 3}
              fill={'#505D6F'}
              style={{
                fontFamily: 'Roboto',
                fontSize: `${fontSz}`,
                fontWeight: '500',
              }}
            >
              {label}
            </text>
          </g>
        )
      })} */}
    </svg>
  )
}

export default SvgBarChart
