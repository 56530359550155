import classnames from 'classnames'
import * as React from 'react'

import cls from './social-header.module.scss'

/* components */
import UikTabItem from '../UikTabItem'
import UikTabContainer from '../UikTabContainer'
import UikAvatar from '../UikAvatar'
import UikButton from '../UikButton'
import UikWidget from '../UikWidget'
import Uikon from '../Uikon'

const UikSocialHeader = ({ className, Component, imgUrl, avatar, name, ...rest }: UikSocialHeaderProps) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    <div
      className={cls.cover}
      style={{
        backgroundImage: `url(${imgUrl})`,
      }}
    >
      <UikButton className={cls.btnUpdateCover} dark icon={<Uikon>camera</Uikon>}>
        Edit Cover
      </UikButton>
      <UikAvatar className={cls.avatar} size="jumbo" {...avatar} />
      <h1 className={cls.name}>{name}</h1>
    </div>
    <div className={cls.content}>
      <div className={cls.tabWrapper}>
        <UikTabContainer>
          {[
            {
              text: 'Timeline',
              extra: null,
            },
            {
              text: 'About',
              extra: null,
            },
            {
              text: 'Friends',
              extra: '580',
            },
            {
              text: 'Photos',
              extra: null,
            },
          ].map(item => (
            <UikTabItem key={item.text} className={item.text === 'About' && 'active'} {...item} />
          ))}
        </UikTabContainer>
      </div>
    </div>
  </Component>
)

UikSocialHeader.defaultProps = {
  className: null,
  Component: UikWidget,
  imgUrl: null,
}

export default UikSocialHeader
