import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
//import ZipLogo from '@assets/Zip-Pay_Display_728x90_Generic_White_Transparent.png'
//import ZipLogo from '@assets/Zip-OnLight.svg'
import ZipLogo from '@assets/ZipLogo-OnLight.svg'
import { Flex, Box, Switch, FormLabel } from '@chakra-ui/react'
import { Loading } from '@components'
import { Button } from '@components'
import { InputCreditCard as Input } from '@uik'
import { sleep } from '@lib'
import { useRouter, useBreakpoint } from '@hooks'
import { emailValidation } from '@lib/formUtils'
import { altPaymentPreparation, altPaymentFinalization, altPaymentFinalizeZipOrder } from '@features/payments'
import { useAction, useSelector } from '@redux'
import cls from './index.module.scss'
import { addAlert } from '@features/alerts'
import { useDispatch } from '@redux'
import { uuidv4 } from '@lib'
import { Link } from 'react-router-dom'

const LoadingStatus = ({ children }) => {
  return (
    <Flex pt="1rem">
      <Box>
        <Loading />
      </Box>
      <Flex
        fontWeight="600"
        align="center"
        style={{
          fontFamily: 'Rubik',
          fontSize: '1rem',
          //  backgroundColor: '#21285e'
        }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

const ZipPayInput = ({
  redirectedQuery,
  finalizeState,
  //onSubmit,
  buttonText = 'SUBMIT',
  children,
  initialValues,
  selected,
  zipLanding,
}) => {
  const [formKey, setFormKey] = useState(0)
  const incFormKey = () => setFormKey(v => v + 1)
  const dispatch = useDispatch()
  const [uiState, setUiState] = useState(0)
  const {
    params: { oid },
  } = useRouter()
  const { reference, total, business } = useSelector(({ payments }) => {
    if (payments?.current) {
      //console.log('oid' + oid)
      return payments?.current[oid] || {}
    } else return {}
    //return {}
  })
  const zipTenderTypesAbilityId =
    business?.tenderTypesAbilities?.filter(ability => ability.name === 'Zip')[0].id || 0
  const requestAltPaymentPreparation = useAction(altPaymentPreparation)
  const requestAltPaymentFinalization = useAction(altPaymentFinalization)
  const requestAltPaymentFinalizeZipOrder = useAction(altPaymentFinalizeZipOrder)
  const [selectedTab, setSelectedTab] = useState(1)
  const [finalizationId, setFinalizationId] = useState()
  const [emailReadOnly, setEmailReadyOnly] = useState(false)
  const bp = useBreakpoint()
  //console.log('business  ' + JSON.stringify(business))
  useEffect(() => {
    if (initialValues) {
      setEmailReadyOnly(initialValues?.email?.length > 0 ? true : false)
    }
  }, [initialValues])

  useEffect(() => {
    if (finalizationId) {
      setUiState(4)
    }
  }, [finalizationId])

  const showAlertUi = (type, message) => {
    dispatch(
      addAlert({
        message,
        error: type === 'error' ? true : false,
        dismiss: false,
        top: true,
      })
    )
  }

  const prepareZipPayment = async () => {
    const prepareResult = await requestAltPaymentPreparation({
      total,
      businessId: business.id,
      tenderTypesAbilityId: zipTenderTypesAbilityId,
      orderId: oid,
    })
    const prepareResultString = JSON.stringify(prepareResult)
    localStorage.setItem('prepareResultString', prepareResultString)
    //console.log('result' + prepareResultString)
    return prepareResult?.uri
  }

  const finalizeZipPayment = async () => {
    //await sleep(5000)
    //console.log('finalizeZipPayment business ' + business)
    // ensure Bid ready before sumbitting finalise request
    for (var i = 0; i < 10; i++) {
      if ('id' in business !== true) {
        await sleep(500)
      } else {
        break
      }
    }
    if (i === 9 && 'id' in business !== true) {
      setUiState(6)
      return null
    }

    const prepareResult = localStorage.getItem('prepareResultString')
    let preparationObj = null
    if (prepareResult) {
      preparationObj = JSON.parse(prepareResult)
      //console.log('preparationObj' + JSON.stringify(preparationObj))
    }
    const finalizationObj = {
      tenderTypesAbilityId: zipTenderTypesAbilityId,
      tenderTypesPreparationId: preparationObj.id,
      businessId: business.id,
      clientKey: uuidv4(),
      total,
    }
    //console.log('finalize Zip payment Obj ' + JSON.stringify(finalizationObj))
    const result = await requestAltPaymentFinalization(finalizationObj)
    if ('id' in result) {
      setFinalizationId(result.id)
    } else {
      console.error('finalize Zip payment' + JSON.stringify(result))
      setUiState(6)
    }
    //console.log('finalize Zip payment' + JSON.stringify(result))
  }

  const submitPropellOrder = async () => {
    var zipOrderObj
    const zipOrderParms = localStorage.getItem('zipOrderParms')
    if (zipOrderParms) {
      zipOrderObj = JSON.parse(zipOrderParms)
      //console.log('zipOrderObj' + JSON.stringify(zipOrderObj))
    }
    const finalizePaymentObj = {
      locationId: business.locations[0].id,
      finalizationId,
      total,
      orderId: oid,
      orderReference: reference,
      businessId: business.id,
      tenderTypesAbilityId: zipTenderTypesAbilityId,
      clientKey: uuidv4(),
      sendReceipt: zipOrderObj?.sendReceipt,
      recipientEmail: zipOrderObj?.email,
      recipientPhone: zipOrderObj?.phone,
    }
    await requestAltPaymentFinalizeZipOrder(finalizePaymentObj)
  }

  const mOnSubmit = async values => {
    //console.log('mOnSubmit 0' + JSON.stringify(values))
    localStorage.setItem('zipOrderParms', JSON.stringify(values))
    setUiState(2)
    //console.log('mOnSubmit 1')
  }

  useEffect(() => {
    if (uiState === 0 && selected) {
      if (finalizeState) {
        setSelectedTab(1)
        setUiState(3)
      } else {
        ;(async () => {
          await sleep(500)
          setUiState(1)
        })()
      }
    }
    // eslint-disable-next-line
  }, [selected, uiState])

  useEffect(() => {
    //console.log('state machine : buz obj' + business)
    if (business) {
      switch (uiState) {
        case 0: // loading
          break
        case 1: // wait for User input
          break
        case 2: // preparation state
          ;(async () => {
            const zipUri = await prepareZipPayment()
            if (zipUri) {
              showAlertUi('info', `Redirecting to ZipPay Portal.`)
              await sleep(500)
              //            localStorage.setItem('zipRedirected', true)
              window.location.href = zipUri
            } else {
              showAlertUi(
                'error',
                `ZipPay cannot be setup at this moment. Please try again or select other payment options.`
              )
            }
          })()
          break
        case 3: // finalization state
          //        localStorage.removeItem('zipRedirected')
          ;(async () => {
            //console.log('redirectedQuery' + JSON.stringify(redirectedQuery))
            if (redirectedQuery?.result === 'cancelled') {
              setUiState(5)
            } else if (redirectedQuery?.result === 'approved') {
              await finalizeZipPayment()
            } else {
              setUiState(6)
            }
          })()
          break
        case 4: // submit Propell Order
          ;(async () => {
            //console.log('redirectedQuery' + JSON.stringify(redirectedQuery))
            await submitPropellOrder()
            const mhref = window.location.href.split('?')[0]
            //await sleep(2000)
            window.location.href = mhref
          })()
          break
        case 5: // zip cancel
          ;(async () => {
            //console.log('redirectedQuery' + JSON.stringify(redirectedQuery))
            const mhref = window.location.href.split('?')[0]
            const href = mhref + `?zPublicKey=${localStorage.getItem('zPublicKey')}`
            await sleep(2000)
            window.location.href = href
          })()
          break
        case 6: // zip error
          break
        default:
          break
      }
    }
    // eslint-disable-next-line
  }, [uiState, business])

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log('zipRedirected checked')
  //     console.log('zipRedirected' + localStorage.getItem('zipRedirected'))
  //     if (localStorage.getItem('zipRedirected') === 'true' && uiState === 0) {
  //       console.log('force reload')
  //       localStorage.removeItem('zipRedirected')
  //       window.location.reload()
  //     }
  //   }, 2000)
  //   // eslint-disable-next-line
  // }, [])

  useEffect(() => {
    setSelectedTab(zipLanding)
  }, [zipLanding])

  const Tab = ({ label, selected, setSelected }) => {
    return (
      <div
        className={selected ? cls.tabSelected : cls.tab}
        onClick={() => {
          setSelected()
        }}
      >
        {label}
      </div>
    )
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className={cls.tabContainer}>
        <Tab
          label={'Apply'}
          selected={selectedTab === 0}
          setSelected={() => {
            setSelectedTab(0)
          }}
        />
        <Tab
          label={'Pay'}
          selected={selectedTab === 1}
          setSelected={() => {
            setSelectedTab(1)
          }}
        />
      </div>
      <div className={selectedTab === 0 ? cls.showPage : cls.hidePage}>{children}</div>
      <div className={selectedTab === 1 ? cls.paymentPageContainer : cls.paymentPageContainerHide}>
        <Form
          key={formKey}
          onSubmit={mOnSubmit}
          initialValues={{ sendReceipt: true, ...initialValues }}
          render={({ handleSubmit, form, submitting, pristine, values, active }) => {
            return (
              <form
                onSubmit={values => {
                  const promise = handleSubmit(values)
                  promise &&
                    promise.then(() => {
                      incFormKey()
                    })
                  return promise
                }}
                style={{ maxWidth: '100%', width: '80%' }}
                noValidate
                id="externalZipSubmitFormTarget"
              >
                {/* {children} */}
                <Flex justifyContent="center">
                  <div style={{ display: uiState === 0 ? 'block' : 'none' }}>
                    <Box maxW={400}>
                      <Loading />
                    </Box>
                  </div>
                  <div
                    style={{
                      display: uiState > 0 ? 'flex' : 'none',
                      //height: '120px',
                      padding: '0 1.5rem',
                      boxSizing: 'content-box',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <img
                      src={ZipLogo}
                      alt={'Logo'}
                      className={cls.ZipLogo}
                      // style={{ height: '120px', padding: '0 3rem', boxSizing: 'content-box' }}
                    />
                  </div>
                </Flex>
                <Box pt="1rem">{''}</Box>

                <div style={{ display: uiState === 1 ? 'block' : 'none' }}>
                  <div
                    style={{
                      display: values.sendReceipt ? 'block' : 'none',
                    }}
                  >
                    <Box
                      pt="0.5rem"
                      pb="0.5rem"
                      //maxW={400}
                      w="100%"
                    >
                      {values.sendReceipt ? (
                        <Field
                          name="email"
                          type="email"
                          validate={values.sendReceipt ? emailValidation : () => undefined}
                        >
                          {({ input, meta }) => {
                            return (
                              <Input
                                className={cls.inputBox}
                                label="EMAIL"
                                placeholder="Email"
                                errorMessage={meta.error && meta.touched && meta.error}
                                noValidate
                                {...input}
                                readOnly={emailReadOnly}
                              />
                            )
                          }}
                        </Field>
                      ) : null}
                    </Box>
                  </div>
                  <Box pt="0.5rem" pb="0.5rem" />

                  <div className={cls.buttonContainer}>
                    <Button
                      type="submit"
                      disabled={submitting}
                      isLoading={submitting}
                      // onClick={() => {
                      //   window.location.href = zipUri
                      // }}
                      // style={{
                      //   fontFamily: 'Rubik',
                      //   fontSize: '1rem',
                      //   //  backgroundColor: '#21285e'
                      // }}
                      className={cls.btn}
                      w={bp < 2 ? '100%' : '50%'}
                    >
                      {buttonText}
                    </Button>
                    <Field name="sendReceipt" type="checkbox">
                      {({ input, meta }) => (
                        <Flex className={cls.sendReceiptSwitch}>
                          <FormLabel htmlFor={input.name}>
                            <div className={cls.zipFontLabel}>SEND RECEIPT</div>
                          </FormLabel>
                          <Switch
                            color={'green'}
                            id={`${input.name}-Zip`}
                            isChecked={input.checked}
                            {...input}
                          />
                        </Flex>
                      )}
                    </Field>
                  </div>
                </div>

                <div style={{ display: uiState === 2 ? 'block' : 'none' }}>
                  <LoadingStatus>Redirecting to Zip Portal</LoadingStatus>
                </div>

                <div style={{ display: uiState === 3 ? 'block' : 'none' }}>
                  <LoadingStatus>
                    Zip Payment request was approved. We will finalize payment transaction.
                  </LoadingStatus>
                </div>

                <div style={{ display: uiState === 4 ? 'block' : 'none' }}>
                  <LoadingStatus>Finalize Zip Payment payment transaction.</LoadingStatus>
                </div>

                <div style={{ display: uiState === 5 ? 'block' : 'none' }}>
                  <LoadingStatus>
                    Zip Payment was cancelled. You may try again or select other payment option.
                  </LoadingStatus>
                </div>

                <div style={{ display: uiState === 6 ? 'block' : 'none' }}>
                  <Flex pt="1rem">
                    <Flex
                      fontWeight="600"
                      align="center"
                      style={{
                        fontFamily: 'Rubik',
                        fontSize: '1rem',
                        //  backgroundColor: '#21285e'
                        marginBottom: '1rem',
                      }}
                    >
                      <Link style={{ display: 'inline' }} to="https://propell.zendesk.com/">
                        <div style={{ textAlign: 'center' }}>Something went wrong.</div>{' '}
                        <div style={{ textAlign: 'center' }}>
                          For details, please contact our{' '}
                          <span style={{ fontWeight: '700', textDecoration: 'underline' }}>
                            customer support
                          </span>
                          .
                        </div>
                        <div style={{ textAlign: 'center' }}>You may click restart to try again.</div>
                      </Link>{' '}
                    </Flex>
                  </Flex>

                  <Flex justifyContent="center">
                    <div className={cls.buttonContainer}>
                      <Button
                        style={{
                          fontFamily: 'Rubik',
                          fontSize: '1rem',
                          //  backgroundColor: '#21285e'
                        }}
                        onClick={() => {
                          const mhref = window.location.href.split('?')[0]
                          window.location.href = mhref
                        }}
                      >
                        Restart
                      </Button>
                    </div>
                  </Flex>
                </div>
              </form>
            )
          }}
        />
      </div>
    </div>
  )
}

export default ZipPayInput
