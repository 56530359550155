import React from 'react'
import { Flex } from '@chakra-ui/react'
import { useBreakpoint } from '@hooks'
import cls from './index.module.scss'
import { mainLogoCoal900, brandName } from '@theme'
import { ButtonV1 as Button, IconsPropell } from '@components'
import { usePropellSupportModalCtx } from '@components'

const ChatLogo = () => {
  const bp = useBreakpoint()
  return (
    <svg
      width={bp > 1 ? '287' : '100'}
      height={bp > 1 ? '314' : '117'}
      viewBox="0 0 287 314"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M268.992 102.758C268.992 102.758 226.817 139.778 275.38 219.051C318.509 289.454 221.568 319.971 179.795 311.8C125.68 301.214 94.6102 232.816 53.2271 254.488C11.8439 276.161 -29.153 177.954 31.0427 141.817C105.924 96.8633 57.8661 70.7403 64.5915 37.7792C69.4185 14.1233 138.107 -22.451 175.144 19.2692C206.311 54.3762 221.595 41.8164 235.839 37.1297C256.384 30.3702 301.229 65.5303 268.992 102.758Z"
        fill="#E7F3FF"
      />
      <path
        d="M149.579 144.476L135.761 201.155C135.248 203.257 135.154 205.439 135.485 207.577C135.815 209.715 136.564 211.767 137.688 213.616C138.812 215.465 140.289 217.074 142.035 218.352C143.781 219.629 145.762 220.55 147.864 221.062L216.587 237.816C218.354 257.217 234.409 258.651 240.744 259.316C240.806 259.319 240.868 259.302 240.92 259.267C240.971 259.232 241.01 259.18 241.029 259.121C241.048 259.061 241.047 258.997 241.026 258.938C241.005 258.88 240.966 258.829 240.913 258.796C236.364 256.023 235.006 248.422 234.703 242.233L239.805 243.476C241.907 243.989 244.089 244.083 246.227 243.753C248.365 243.422 250.417 242.673 252.266 241.549C254.115 240.425 255.724 238.948 257.001 237.202C258.279 235.456 259.2 233.475 259.712 231.373L273.53 174.694C274.043 172.592 274.137 170.41 273.806 168.272C273.475 166.134 272.726 164.082 271.602 162.233C270.479 160.384 269.001 158.775 267.255 157.497C265.509 156.22 263.529 155.299 261.426 154.787L169.486 132.373C167.384 131.86 165.202 131.766 163.063 132.096C160.925 132.427 158.873 133.176 157.025 134.3C155.176 135.424 153.567 136.901 152.289 138.647C151.011 140.393 150.09 142.374 149.579 144.476Z"
        fill="#66DAD8"
      />
      <path
        d="M263.572 172.437L225.144 163.068C224.891 163.007 224.636 163.162 224.574 163.415L222.602 171.501C222.541 171.754 222.696 172.009 222.949 172.071L261.377 181.44C261.63 181.501 261.885 181.346 261.947 181.093L263.918 173.007C263.98 172.754 263.825 172.498 263.572 172.437Z"
        fill="white"
      />
      <path
        d="M258.085 194.944L153.966 169.561C153.713 169.499 153.458 169.654 153.396 169.907L151.425 177.994C151.363 178.247 151.518 178.502 151.771 178.564L255.89 203.947C256.143 204.009 256.398 203.854 256.46 203.601L258.431 195.514C258.493 195.261 258.338 195.006 258.085 194.944Z"
        fill="#D4E5FF"
      />
      <path
        d="M252.598 217.453L148.48 192.069C148.227 192.008 147.971 192.163 147.91 192.416L145.938 200.502C145.877 200.755 146.032 201.01 146.285 201.072L250.403 226.455C250.656 226.517 250.911 226.362 250.973 226.109L252.944 218.022C253.006 217.769 252.851 217.514 252.598 217.453Z"
        fill="#D4E5FF"
      />
      <path
        d="M188.271 76.4842L210.866 149.046C211.705 151.737 212.005 154.567 211.75 157.373C211.495 160.18 210.689 162.909 209.379 165.405C208.069 167.9 206.279 170.113 204.114 171.917C201.948 173.72 199.448 175.08 196.757 175.917L108.777 203.313C108.065 228.681 87.308 231.858 79.1267 233.239C79.0455 233.249 78.9631 233.233 78.8925 233.191C78.8219 233.15 78.7672 233.086 78.737 233.01C78.7068 232.934 78.703 232.85 78.726 232.771C78.7491 232.692 78.7977 232.624 78.8643 232.576C84.5515 228.599 85.6962 218.606 85.5851 210.535L79.0529 212.569C76.3622 213.407 73.5327 213.707 70.7259 213.452C67.9192 213.197 65.1903 212.391 62.695 211.081C60.1997 209.771 57.9871 207.982 56.1834 205.816C54.3797 203.65 53.0204 201.151 52.1831 198.46L29.5872 125.898C28.7485 123.207 28.4482 120.377 28.7034 117.57C28.9586 114.763 29.7643 112.034 31.0746 109.539C32.3849 107.044 34.174 104.831 36.3397 103.027C38.5055 101.223 41.0054 99.864 43.6966 99.0267L161.4 62.3748C164.09 61.5361 166.92 61.2358 169.727 61.491C172.534 61.7462 175.263 62.5519 177.758 63.8622C180.254 65.1725 182.466 66.9616 184.27 69.1273C186.074 71.2931 187.433 73.793 188.271 76.4842Z"
        fill="#385095"
      />
      <path
        d="M105.143 102.474L42.1125 122.075C41.8638 122.153 41.7248 122.417 41.8022 122.666L45.1073 133.294C45.1847 133.542 45.449 133.681 45.6978 133.604L108.728 114.002C108.977 113.925 109.116 113.66 109.038 113.411L105.733 102.784C105.656 102.535 105.391 102.396 105.143 102.474Z"
        fill="white"
      />
      <path
        d="M184.684 109.21L51.1183 150.803C50.8696 150.88 50.7308 151.145 50.8082 151.393L54.1169 162.018C54.1944 162.267 54.4588 162.406 54.7075 162.329L188.273 120.735C188.522 120.658 188.661 120.393 188.583 120.145L185.275 109.52C185.197 109.271 184.933 109.132 184.684 109.21Z"
        fill="#D4E5FF"
      />
      <path
        d="M193.643 138.171L60.0461 179.719C59.7974 179.796 59.6584 180.061 59.7358 180.31L63.0409 190.937C63.1183 191.186 63.3826 191.325 63.6314 191.247L197.229 149.7C197.477 149.622 197.616 149.358 197.539 149.109L194.234 138.481C194.156 138.233 193.892 138.094 193.643 138.171Z"
        fill="#D4E5FF"
      />
    </svg>
  )
}

const OppsModal = ({
  isOpen,
  onClose,
  title,
  text,
  sourcePage,
  extraAction,
  extraActionText,
  extraAction2,
  extraActionText2,
}) => {
  const bp = useBreakpoint()
  const { openHelpModal } = usePropellSupportModalCtx()
  return (
    <div
      style={{
        display: isOpen ? 'flex' : 'none',
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        left: '0px',
        top: '0px',
        background: 'rgba(64,64,64,0.5)',
        zIndex: '100',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'scroll',
      }}
    >
      <Flex direction={'column'} bg="#FDFBF9" maxWidth={'900px'} marginTop={bp > 1 ? '5rem' : '0'}>
        <Flex className={cls.headerBar}>
          <Flex justify="center" w="100%">
            <img src={mainLogoCoal900} alt={`${brandName} Logo`} />
          </Flex>
          {onClose ? (
            <Flex
              p="16px"
              onClick={() => {
                onClose()
              }}
              cursor="pointer"
            >
              <IconsPropell name="CloseBtn" />
            </Flex>
          ) : null}
        </Flex>
        <Flex>
          <Flex className={cls.container}>
            <div className={cls.mobileShow}>
              <Flex w="100%" p="1rem" justify="center">
                <ChatLogo />
              </Flex>
            </div>

            <Flex className={cls.containerTitle}>{title}</Flex>
            <Flex className={cls.containerSubTitle}>
              {text ? text : "Please contact us and we'll fix this for you"}
            </Flex>
            <Flex m="50px 0">
              <Button
                onClick={() => {
                  openHelpModal() // window.zE('webWidget', 'open')
                }}
                //isLoading={isSubmitting}
                loadingText="Submitting"
              >
                {/* CONFIRM & SUBMIT */}
                CONTACT US
              </Button>
              {extraAction ? (
                <Flex m="0 0 0 8px">
                  <Button onClick={extraAction} loadingText="Submitting">
                    {extraActionText}
                  </Button>
                </Flex>
              ) : null}
              {extraAction2 ? (
                <Flex m="0 0 0 8px">
                  <Button onClick={extraAction2} loadingText="Submitting">
                    {extraActionText2}
                  </Button>
                </Flex>
              ) : null}
            </Flex>
          </Flex>

          <div className={cls.desktopShow}>
            <Flex w="287px" h="314px" mt="50px" mb="50px" mr="65px">
              <ChatLogo />
            </Flex>
          </div>
        </Flex>
      </Flex>
    </div>
  )
}

export default OppsModal
