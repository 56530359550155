import React from 'react'

export const PropellCenter = ({ children, w, h }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: w ? `${w}px` : '16px',
        height: h ? `${h}px` : '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export const PropellIconBack = () => {
  return (
    <PropellCenter>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.24023 9.97641C7.24023 10.1864 7.32324 10.367 7.48437 10.5282L11.2344 14.1854C11.3613 14.3123 11.5176 14.3807 11.7031 14.3807C12.084 14.3807 12.3867 14.078 12.3867 13.702C12.3867 13.5164 12.3135 13.3407 12.1768 13.2039L8.85645 9.98129L12.1768 6.75375C12.3086 6.61704 12.3867 6.44614 12.3867 6.25571C12.3867 5.87973 12.084 5.577 11.7031 5.577C11.5176 5.577 11.3613 5.64536 11.2295 5.77231L7.48437 9.42954C7.31836 9.60043 7.24023 9.77133 7.24023 9.97641Z"
          fill="#69788C"
        />
      </svg>
    </PropellCenter>
  )
}

export const PropellIconForward = () => {
  return (
    <PropellCenter>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.3867 9.98129C12.3867 9.77133 12.3037 9.59067 12.1426 9.42954L8.39258 5.77231C8.26562 5.64536 8.10937 5.577 7.92383 5.577C7.54297 5.577 7.24023 5.87973 7.24023 6.25571C7.24023 6.44125 7.31348 6.61704 7.4502 6.75375L10.7705 9.97641L7.4502 13.2039C7.31836 13.3407 7.24023 13.5116 7.24023 13.702C7.24023 14.078 7.54297 14.3807 7.92383 14.3807C8.10937 14.3807 8.26562 14.3123 8.39746 14.1854L12.1426 10.5282C12.3086 10.3573 12.3867 10.1864 12.3867 9.98129Z"
          fill="#69788C"
        />
      </svg>
    </PropellCenter>
  )
}
