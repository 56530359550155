import { createSlice } from '@redux'

export const initialState = {
  lending: null,
  mPOS: null,
  virtualTerminal: null,
  smartTerminal: null,
  trust: null,
  classMembership: null,
  trustSettlors: null,
  differentPaymentTime: null,
  noReturnPolicy: null,
  paidInAdvance: null,
  directorIsNotSelfEmployed: null,
  currentOtherBusiness: null,
  previousOtherBusiness: null,
  settlementIsBankAccount: null,
}

const choiceSlice = createSlice({
  name: 'originations/choice',
  initialState,
  reducers: {
    setChoice: (state, { payload: { field, value } }) => ({
      ...state,
      [field]: value,
    }),
    resetChoice: () => initialState,
    enablePaymentType: (state, { payload: { paymentType } }) => ({
      ...state,
      mPOS: paymentType === 'mPOS',
      virtualTerminal: paymentType === 'virtualTerminal',
      smartTerminal: paymentType === 'smartTerminal',
    }),
  },
})

export const { enablePaymentType, setChoice, resetChoice } = choiceSlice.actions

export default choiceSlice.reducer
