// @flow
import * as React from 'react'
import classnames from 'classnames'

import UikContentTitle from '../UikContentTitle'

import cls from './index.module.scss'
import Uikon from '../Uikon'
class Input extends React.PureComponent<UikInputProps> {
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
    icon: null,
    clear: false,
    iconPosition: 'left',
    errorMessage: null,
    forwardRef: null,
    inputPatternRegex: null,
  }

  // setRef = node => {
  //   this.input = node
  // }

  setValue = value => {
    this.input.value = value
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      icon,
      label,
      focus, // eslint-disable-line
      clear,
      iconPosition,
      errorMessage,
      forwardRef,
      inputPatternRegex,
      disabledErrMsg,
      altStyle,
      altStyle2,
      altStyle3,
      touched,
      type,
      forceTick,
      // size
      ...rest
    } = this.props

    //console.log(' this.props' + JSON.stringify(this.props))
    return (
      <div
        {...wrapperProps}
        className={classnames(wrapperProps.className, cls.formGroup, {
          [cls.clear]: clear,
        })}
      >
        {label ? (
          altStyle ? (
            <div className={cls.altStyle}>{label}</div>
          ) : altStyle2 ? (
            <div className={cls.altStyle2}>{label}</div>
          ) : altStyle3 ? (
            <div className={cls.altStyle3}>{label}</div>
          ) : (
            <UikContentTitle>{label}</UikContentTitle>
          )
        ) : null}
        <div className={classnames(cls.inputWrapper)}>
          {icon && (
            <span
              className={classnames(cls.iconWrapper, {
                [cls.iconWrapperRight]: iconPosition === 'right',
              })}
            >
              {icon}
            </span>
          )}
          <div className={cls.relativeWrapper}>
            <input
              ref={forwardRef}
              className={classnames(className, cls.input, {
                [cls.errorHighlight]: errorMessage,
              })}
              name={name}
              {...rest}
              type={type ? 'tel' : 'text'}
              onKeyPress={e => {
                if (inputPatternRegex) {
                  if (inputPatternRegex.test(e.key) === false) e.preventDefault()
                }
              }}
            />
            {/* {(altStyle2 && errorMessage === null && touched === true) || forceTick ? ( */}
            {((altStyle2 || altStyle3) && errorMessage === null && touched === true) || forceTick ? (
              <div className={cls.absoluteWrapper}>
                <Uikon className={cls.validationIcon}>check</Uikon>
              </div>
            ) : null}
          </div>
        </div>
        {errorMessage && !disabledErrMsg && <p className={cls.errorMessage}>{errorMessage}</p>}
        {altStyle ? <div style={{ height: '6px' }} /> : null}
      </div>
    )
  }
}

export default Input
