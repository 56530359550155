import React, { useRef } from 'react'
import cls from './index.module.scss'
import { generateCurve } from './curveFunctions'
import { formatCurrency } from '@lib'

const SvgCurveGen = (points, color, idx) => {
  const curvePoints = generateCurve(points)
  return (
    <path
      key={`path#${idx}`}
      d={curvePoints}
      fill="none"
      strokeOpacity="0.5"
      stroke={color}
      strokeWidth="2px"
    />
  )
}
const tooltipsStyle = {
  fontFamily: 'Roboto',
  fontSize: '6px',
  lineHeight: '8px',
  fontWeight: '400',
}
const tooltipsSz = { width: '60', height: '35' }

export const SvgInOutBarLineBarChart = ({ data, max, min }) => {
  const boxSz = { w: 378, h: 186, lengendOffset: 55, topMargin: 32, bottomMargin: 32 }
  const rectsPlus = []
  const rectsMinus = []
  const labels = []
  const monthLabels = []
  const lengendFontSz = 9 / 2
  const ref = useRef()
  const scale = (boxSz.h - (boxSz.bottomMargin + boxSz.topMargin)) / (max - min)
  const offset = min * scale * -1 + boxSz.topMargin
  const width = (boxSz.w - boxSz.lengendOffset) / (4 * 12)
  const tooltips = []
  data.forEach(({ vPlus, vMinus, label, pol, date }, idx) => {
    const id = `rectPlus${idx}`
    const plusHeight = vPlus * scale
    const minusHeight = vMinus * scale * -1

    const plusPos = { x: idx * 4.5 * width + width + boxSz.lengendOffset, y: boxSz.h - plusHeight - offset }
    rectsPlus.push({ id, width, plusHeight, plusPos, pol: true, date })

    const minPos = { x: idx * 4.5 * width + width + boxSz.lengendOffset, y: boxSz.h - offset }
    rectsMinus.push({ id: `rectMinus${idx}`, width, minusHeight, minPos, pol: false, date })

    const monthLabelPos = {
      x: idx * 4.5 * width + width + boxSz.lengendOffset,
      y: boxSz.h - boxSz.topMargin / 2, // + boxSz.bottomMargin + boxSz.topMargin,
    }
    monthLabels.push({ id, pos: monthLabelPos, label: label })
    tooltips.push({ id, pos: plusPos, values: [vPlus, vMinus, vPlus + vMinus] })
  })
  const label0Pos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: boxSz.h + min * scale - boxSz.topMargin,
  }
  const labelMaxPos = { x: boxSz.lengendOffset - lengendFontSz, y: boxSz.bottomMargin }
  const labelMaxHalfPos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: boxSz.bottomMargin + (max / 2) * scale,
  }
  const labelMinPos = { x: boxSz.lengendOffset - lengendFontSz, y: boxSz.h - boxSz.topMargin }
  const labelMinHalfPos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: boxSz.h - boxSz.topMargin + (min / 2) * scale,
  }
  labels.push({ id: 0, labelPos: label0Pos, label: '$0' })
  labels.push({ id: 1, labelPos: labelMaxPos, label: `${formatCurrency(max * 100)}` })
  labels.push({ id: 2, labelPos: labelMinPos, label: `${formatCurrency(min * 100)}` })
  labels.push({ id: 3, labelPos: labelMaxHalfPos, label: `${formatCurrency((max / 2) * 100)}` })
  labels.push({ id: 4, labelPos: labelMinHalfPos, label: `${formatCurrency((min / 2) * 100)}` })
  const barColor = '#446DB5'
  const barColorNeg = '#FBB9BD'
  const curveColor = '#00C9C6'
  // construct diff line
  const diffsRaw = data.map(({ vPlus, vMinus }) => {
    return vPlus + vMinus
  })
  const diffsPos = diffsRaw.map((diffsRaw, idx) => {
    const y = diffsRaw >= 0 ? diffsRaw * scale : Math.abs(diffsRaw) * scale
    const ypos = diffsRaw >= 0 ? boxSz.h - y - offset : boxSz.h - offset + y
    return [rectsPlus[idx].plusPos.x + width / 2, ypos]
  })

  const DiffCurve = SvgCurveGen(diffsPos, curveColor, 0)
  return (
    // <div ref={ref} style={{ width: '100%', height: '100%', position: 'relative' }}>
    <svg
      id="testSvg"
      ref={ref}
      width="100%"
      height="100%"
      viewBox={`0 0 ${boxSz.w} ${boxSz.h}`}
      className={cls.svgContainer}
    >
      <defs>
        <filter id="SvgMultiCirclesGaugeshawdow" colorInterpolationFilters="sRGB">
          <feGaussianBlur stdDeviation="0.5" />
          <feDropShadow dx="1" dy="1" stdDeviation="3" floodColor="#bdbdbd" floodOpacity="0.4" />
        </filter>
        <filter id="shadow" colorInterpolationFilters="sRGB" x="-0.3" y="-0.3" width="150%" height="150%">
          <feDropShadow dx="0.1" dy="0.1" stdDeviation="3" floodOpacity="0.3" />
        </filter>
      </defs>

      {rectsPlus.map(({ id, width, plusHeight, plusPos, pol, date }, idx) => {
        return (
          <g key={id} id={id} style={{ cursor: 'pointer' }}>
            <rect
              x={plusPos.x}
              y={plusPos.y}
              width={width}
              height={plusHeight}
              fill={pol ? barColor : barColorNeg}
              strokeWidth="0"
              rx={width / 2}
            />
            <rect
              x={plusPos.x}
              y={plusPos.y + width / 2}
              width={width}
              height={Math.abs(plusHeight - width / 2)}
              fill={pol ? barColor : barColorNeg}
              strokeWidth="0"
            />
          </g>
        )
      })}
      {rectsMinus.map(({ id, width, minusHeight, minPos, pol, date }, idx) => {
        return (
          <g key={id} id={id} style={{ cursor: 'pointer' }}>
            <rect
              x={minPos.x}
              y={minPos.y}
              width={width}
              height={minusHeight}
              fill={pol ? barColor : barColorNeg}
              strokeWidth="0"
              rx={width / 2}
            />
            <rect
              x={minPos.x}
              y={minPos.y}
              width={width}
              height={Math.abs(minusHeight - width / 2)}
              fill={pol ? barColor : barColorNeg}
              strokeWidth="0"
            />
          </g>
        )
      })}
      {labels.map(({ id, labelPos, label }) => {
        return (
          <g key={id} id={id}>
            <text
              x={labelPos.x}
              y={labelPos.y}
              fill={'#6B6967'}
              textAnchor="end"
              style={{
                fontFamily: 'Roboto',
                fontSize: '9px',
                fontWeight: '400',
              }}
            >
              {label}
            </text>
            <line
              x1={labelPos.x + 4}
              y1={labelPos.y}
              x2={`${boxSz.w}`}
              y2={labelPos.y}
              stroke="#7F7C7E"
              strokeWidth="0.5"
              strokeDasharray="3 1"
            />
          </g>
        )
      })}
      {monthLabels.map(({ id, pos, label }) => {
        return (
          <text
            key={id}
            x={pos.x}
            y={pos.y}
            fill={'#959AA9'}
            textAnchor="start"
            style={{
              fontFamily: 'Roboto',
              fontSize: '13px',
              fontWeight: '400',
            }}
          >
            {label}
          </text>
        )
      })}
      {DiffCurve}
      {tooltips.map(({ pos, values }, idx) => {
        return (
          <g key={`tooltips#${idx}`} visibility="hidden">
            <set
              attributeName="visibility"
              to="visible"
              begin={`rectPlus${idx}.mouseenter`}
              end={`rectPlus${idx}.mouseleave`}
            />
            <set
              attributeName="visibility"
              to="visible"
              begin={`rectMinus${idx}.mouseenter`}
              end={`rectMinus${idx}.mouseleave`}
            />
            <rect
              x={pos.x + 5}
              y={pos.y - 25}
              width={tooltipsSz.width}
              height={tooltipsSz.height}
              stroke="none"
              fill="white"
              strokeWidth="5"
              filter="url(#shadow)"
            ></rect>
            {values.map((v, idx) => {
              return (
                <g key={`circle${idx}`}>
                  <circle
                    cx={pos.x + 14}
                    cy={pos.y + (idx + 1) * 10 - 27}
                    r="2"
                    fill={idx === 0 ? barColor : idx === 1 ? barColorNeg : curveColor}
                  />
                  <text x={pos.x + 20} y={pos.y + (idx + 1) * 10 - 25} fill={'#505D6F'} style={tooltipsStyle}>
                    {`${formatCurrency(v * 100)} `}
                  </text>
                </g>
              )
            })}
          </g>
        )
      })}
    </svg>
    // </div>
  )
}

export default SvgInOutBarLineBarChart
