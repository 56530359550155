import React from 'react'
import cls from './index.module.scss'
import mixpanel from 'mixpanel-browser'
import { PropellMixPanelEnabled } from '@Apps/..'
import { Spinner } from '@chakra-ui/react'

const ButtonV3A = ({ children, primary, ...props }) => {
  const btnProps = Object.assign({}, props)
  delete btnProps.isLoading

  return (
    <button
      {...btnProps}
      className={primary ? cls.btnPrimary : cls.btn}
      //style={props.disabled ? { cursor: 'not-allowed' } : null}
      style={{ cursor: props.disabled ? 'not-allowed' : null, width: props.width ? props.width : null }}
      onClick={e => {
        if (PropellMixPanelEnabled) mixpanel.track('ButtonOnClick', { source: `${children}` })
        if ('onClick' in props) props.onClick(e)
      }}
    >
      {/* {props.isLoading === 'true' ? <Spinner size="xs" /> : children} */}
      {props.isLoading ? (
        <div className={cls.loading}>
          <Spinner size="xs" />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default ButtonV3A
