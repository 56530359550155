//import * as React from 'react'
import React, { useEffect } from 'react'
import { Flex } from '@chakra-ui/react'
import classnames from 'classnames'

import { useBreakpoint } from '@hooks'
import cls from './index.module.scss'
import mixpanel from 'mixpanel-browser'
import { PropellMixPanelEnabled } from '@Apps/..'

const PageContainer = ({
  title,
  subTitle,
  mobileAppMode,
  noSpace,
  aside = null,
  atop = null,
  children,
  className,
  titleColor,
  ...props
}) => {
  const breakpoint = useBreakpoint()
  useEffect(() => {
    if (PropellMixPanelEnabled) mixpanel.track('Page Load', { source: `${title}` })
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className={
        mobileAppMode
          ? noSpace
            ? cls.mainContentMobileAppNoSpace
            : cls.mainContentMobileApp
          : noSpace
          ? cls.mainContentNoSpace
          : cls.mainContent
      }
    >
      {!mobileAppMode ? (
        <>
          {titleColor ? (
            <h1 className={cls.title} style={{ color: titleColor }}>{`${title}`}</h1>
          ) : (
            <h1 className={cls.title}>{`${title}`}</h1>
          )}
        </>
      ) : null}

      {subTitle ? <h2 className={cls.subTitle}>{`${subTitle}`}</h2> : null}
      {atop ?? null}
      <Flex>
        <div className={classnames(cls.childrenWrapper, className)} {...props}>
          {children}
        </div>
        {breakpoint > 3 && aside ? <div className={cls.aside}>{aside}</div> : null}
      </Flex>
    </div>
  )
}
export default PageContainer
