import { createSlice } from '@redux'
import { BASE_URL } from '@api'
import api from '@api'
//import { abnProxyUrl } from '@theme'

export const initialState = {
  type: null,
  error: null,
  results: [],
  result: {
    abn: '',
    abnStatus: '',
    acn: '',
    addressDate: null,
    addressPostcode: '',
    addressState: '',
    businessName: [],
    entityName: '',
    entityTypeCode: '',
    entityTypeName: '',
    gst: null,
  },
}

const lookupSlice = createSlice({
  name: 'originationsForm',
  initialState,
  reducers: {
    lookupSuccess: (state, { payload }) => {
      //const { type, meta, ...result } = payload
      return 'names' in payload
        ? {
            ...initialState,
            type: 'name',
            results: payload.names,
          }
        : { ...initialState, type: 'abn', result: payload }
    },
    setError: (state, { payload }) => {
      return {
        ...initialState,
        error: payload,
      }
    },
  },
})

export const { lookupSuccess, setError } = lookupSlice.actions

export default lookupSlice.reducer

export const lookup = rawQuery => async dispatch => {
  try {
    // const { data } = await ext.post(abnProxyUrl, { query })
    // if (data.error) {
    //   dispatch(setError(data.error))
    // } else {
    //   dispatch(lookupSuccess(data))
    // }
    const inQuery = rawQuery.trim()
    const isString = /A-Za-z/.test(inQuery)
    const isNumberOnly = /[0-9]\s/.test(inQuery)
    var query
    if (isString) {
      query = inQuery
    } else if (isNumberOnly) {
      query = inQuery.replace(/\s/g, '')
    } else {
      query = inQuery
    }

    await api
      //.get(`https://api.development.tappr.io/v1/utils/abn-lookup?query=${query}`)
      .get(BASE_URL + `/v1/utils/abn-lookup?query=${query}`)
      .then(({ data }) => {
        if (data.error) {
          dispatch(setError(data.error))
        } else {
          dispatch(lookupSuccess(data))
        }
      })
      .catch(err => {
        console.error(err)
      })
  } catch (error) {
    console.error(error)
    dispatch(setError(error))
  }
}
