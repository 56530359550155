const getBaseUrl = environment => {
  switch (environment) {
    case 'development':
    case 'development-experiment':
    case 'test':
      return {
        BASE_URL: `https://api.development.propellme.com`,
        AUTH_BASE_URL: `https://api.development.propellme.com/v1/user`,
      }
    //case 'development':
    case 'staging':
    case 'staging-experiment':
      return {
        BASE_URL: `https://api.staging.propellme.com`,
        AUTH_BASE_URL: `https://api.staging.propellme.com/v1/user`,
      }
    case 'uat':
      return {
        BASE_URL: `https://api.uat.propellme.com`,
        AUTH_BASE_URL: `https://api.uat.propellme.com/v1/user`,
      }
    case 'production':
    case 'production-experiment':
      return {
        BASE_URL: `https://myapi.propellme.com`,
        AUTH_BASE_URL: `https://myapi.propellme.com/v1/user`,
      }
    default:
      const msg = `API stage must be one of ["development", "test", "uat","staging", "production"], got "${environment}"`
      console.error(msg)
      throw new Error(msg)
  }
}

export default getBaseUrl.bind(null, process.env.REACT_APP_API_STAGE || process.env.NODE_ENV)
