import * as React from 'react'
import classnames from 'classnames'

import cls from './index.module.scss'

const Section = ({
  children,
  className,
  innerClassName,
  header = null,
  bigHeader = false,
  contentNoPadding = false,
  centeredHeader = false,
  flexHeader = false,
  hasBorder = true,
  bare = false,
  separator = true,
  ...props
}) => {
  return (
    <div
      className={classnames(
        cls.wrapper,
        {
          [cls.wrapperBare]: bare,
        },
        className
      )}
      {...props}
    >
      {header ? (
        <div
          className={classnames({
            [cls.bareHeader]: bare,
            [cls.flexHeader]: flexHeader,
            [cls.bigHeader]: bigHeader,
            [cls.centeredHeader]: centeredHeader,
            [cls.smallHeader]: !bigHeader,
            [cls.separator]: separator,
          })}
        >
          {header}
        </div>
      ) : null}
      <div
        className={classnames({
          [cls.content]: true,
          [cls.contentBig]: bigHeader,
          [cls.contentBare]: bare,
          [cls.contentNoPadding]: contentNoPadding,
          [cls.innerClassName]: cls.innerClassName,
        })}
      >
        {children}
      </div>
    </div>
  )
}
export default Section
