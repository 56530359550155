import React, { useState, useEffect, useRef } from 'react'
//import Spinner from 'react-spinkit'
import cls from './index.module.scss'
import { Spinner } from '../Spinner'

const Loading = () => {
  const [ready, setReady] = useState(false)
  const ref = useRef(0)

  useEffect(() => {
    ref.current = 1
    setTimeout(() => {
      if (ref.current === 1) {
        setReady(true) // component still exist set update component state
      }
    }, 400)
    return () => {
      ref.current = 2
    }
    // eslint-disable-line
  }, [])

  return (
    <div className={cls.loaderContainer}>
      <div style={{ display: ready ? 'block' : 'none', width: '4rem', height: '4rem' }}>
        <Spinner />
      </div>
    </div>
  )
}

export default Loading
