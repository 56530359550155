import * as React from 'react'
import classnames from 'classnames'

import UikAvatar from '../UikAvatar'

import cls from './social-about-item.module.scss'

const UikSocialFriendList = ({ className, Component, children, data, ...rest }: UikSocialFriendListProps) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    <UikAvatar key={data.name} {...data} />
  </Component>
)

UikSocialFriendList.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default UikSocialFriendList
