import { push } from 'connected-react-router'

import { clear as clearBusiness } from '@features/business'
import { clear as clearEmployees } from '@features/employees'
import { clear as clearLayout } from '@Dashboard/layout'
import { clear as clearLending } from '@features/lendings'
//import { clear as clearPaymentsChart } from '@Payments/pages/Overview/Chart/chart'
// import { clear as clearPaymentsFilters } from '@Payments/pages/Overview/Filters/filters'
// import { clear as clearPaymentsSummary } from '@Payments/pages/Overview/PaymentsSummary/summary'
// import { clear as clearPaymentsTenders } from '@Payments/pages/Overview/Table/tenders'
import { clear as clearUser } from '@features/user'

import { clear as clearOriginationForm } from '@features/originations/form'
import { clear as clearOriginationStep } from '@features/originations/step'
import { clear as clearOriginationBusinessForm } from '@features/originations/businessForm'
import { clear as clearTermsAndConditions } from '@features/termConditions'
import { clear as clearUiStateHelper } from '@features/uiStateHelper'
import { clear as clearInsights } from '@features/insights'

//import api from '@api'

export const logout = (state, shouldredirect = true) => async dispatch => {
  try {
    //const res = await api.delete('/v1/user/logout')
    const res = { status: 200 }
    //console.log('logout :' + JSON.stringify(res))
    if (res.status === 200) {
      localStorage.clear()
      dispatch(clearBusiness())
      dispatch(clearEmployees())
      dispatch(clearLayout())
      dispatch(clearLending())
      //      dispatch(clearPaymentsChart())
      // dispatch(clearPaymentsFilters())
      // dispatch(clearPaymentsSummary())
      // dispatch(clearPaymentsTenders())
      dispatch(clearUser())
      dispatch(clearOriginationForm())
      dispatch(clearOriginationStep())
      dispatch(clearOriginationBusinessForm())
      dispatch(clearTermsAndConditions())
      dispatch(clearUiStateHelper())
      dispatch(clearInsights())

      return dispatch(
        push({
          pathname: '/login',
          state,
        })
      )
    }
  } catch (error) {
    console.error(error)
    localStorage.clear()
    dispatch(clearBusiness())
    dispatch(clearEmployees())
    dispatch(clearLayout())
    dispatch(clearLending())
    //    dispatch(clearPaymentsChart())
    // dispatch(clearPaymentsFilters())
    // dispatch(clearPaymentsSummary())
    // dispatch(clearPaymentsTenders())
    dispatch(clearUser())
    dispatch(clearOriginationForm())
    dispatch(clearOriginationStep())
    dispatch(clearOriginationBusinessForm())
    dispatch(clearTermsAndConditions())
    dispatch(clearUiStateHelper())
    dispatch(clearInsights())
    return dispatch(
      push({
        pathname: '/login',
        state,
      })
    )
  }
}
