import api from '@api'
import { addAlert } from '@features/alerts'

const receiptResend = values => async (dispatch, getState) => {
  const { clientKey, locationId, orderId, tenderId, method, destination } = values
  //   console.log(
  //     'collectResend ' +
  //       JSON.stringify({
  //         locationId,
  //         orderId,
  //       })
  //   )
  //POST /v1/locations/{location_id}/orders/{order_id}/tenders/{tender_id}/receipts
  const res = await api
    .post(`/v1/locations/${locationId}/orders/${orderId}/tenders/${tenderId}/receipts`, {
      clientKey,
      locationId,
      orderId,
      tenderId,
      method,
      destination,
    })
    .then(() => {
      return dispatch(
        addAlert({
          message: 'Payment Receipt request sent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    })
    .catch(err => {
      console.error(err)
      return dispatch(
        addAlert({
          message: 'Failed to resend payment receipt',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    })

  return res.data
}
export default receiptResend
