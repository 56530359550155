import React from 'react'
// import classnames from 'classnames'

import { UikWidget, UikWidgetContent } from '@uik'

import cls from './index.module.scss'

const AuthContainer = ({ children, child2, small, style, ...props }) => {
  return (
    <div className={cls.authContainer}>
      <UikWidget className={cls.authContent} style={style}>
        <UikWidgetContent>{children}</UikWidgetContent>
      </UikWidget>
      {child2 ? <div className={cls.authContent2}>{child2}</div> : null}
    </div>
  )
}
export default AuthContainer
