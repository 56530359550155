import { createSlice } from '@redux'
import getBaseUrl from '@api/getBaseUrl'
import { tenentId } from '@theme'
export const initialState = {
  broker: null,
  abn: null,
  choices: null,
}

const slice = createSlice({
  name: 'brokerConnectUi',
  initialState,
  reducers: {
    setBrokerConnectUi: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      }
    },
    clear: () => {
      return initialState
    },
  },
})

export const postConnectDocUpload = payloadObj => async (dispatch, getState) => {
  var res
  try {
    const { BASE_URL } = getBaseUrl()
    const formData = new FormData()
    //const accessToken = localStorage.getItem('accessToken')
    formData.append('file', payloadObj.file[0])
    formData.append('broker_connection_id', payloadObj.id)
    // formData.append('id_document_back', payloadObj.idDocument_back[0])
    //console.log('post BusinessDirector Form' + JSON.stringify(payloadObj))
    const apiObj = {
      method: 'POST',
      body: formData,
      headers: {
        'X-Propell-Name': 'Propell',
        'X-Propell-Platform': 'Web',
        'X-Propell-Version': 'appVersion',
        'X-Propell-Build': 'buildVersion',
        //authorization: `Bearer ${accessToken}`,
        'Tenant-Id': `${tenentId}`,
      },
    }
    res = await fetch(BASE_URL + '/v1/broker-connections/file-upload', apiObj)

    return { status: res.status }
  } catch (error) {
    console.error('postBusinessDirector id upload Error#' + JSON.stringify(error?.response))
    return { status: 'ID upload Errors', error: 'File upload error. File must be less than 10MB.' }
  }
}

export const postBrokeConnect = payloadObj => async (dispatch, getState) => {
  var res
  try {
    const { BASE_URL } = getBaseUrl()
    const formData = new FormData()
    formData.append('broker_name', payloadObj.brokerName)
    formData.append('abn', payloadObj.abn)
    const apiObj = {
      method: 'POST',
      body: formData,
      headers: {
        'X-Propell-Name': 'Propell',
        'X-Propell-Platform': 'Web',
        'X-Propell-Version': 'appVersion',
        'X-Propell-Build': 'buildVersion',
        //authorization: `Bearer ${accessToken}`,
        'Tenant-Id': `${tenentId}`,
      },
    }
    res = await fetch(BASE_URL + '/v1/broker-connections', apiObj)

    return await res.json()
  } catch (error) {
    console.error('postBusinessDirector id upload Error#' + JSON.stringify(error?.response))
    return { status: 'ID upload Errors', error: 'File upload error. File must be less than 10MB.' }
  }
}

export const { clear, setBrokerConnectUi } = slice.actions
export default slice.reducer
