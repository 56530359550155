import React from 'react'
import cls from './index.module.scss'
import { generateCurve } from './curveFunctions'

const boxSz = { w: 378, h: 186, lengendOffset: 40, topMargin: 32, bottomMargin: 32 }
const tooltipsSz = { width: '40', height: '20' }
const tooltipsStyle = {
  fontFamily: 'Roboto',
  fontSize: '8px',
  lineHeight: '10px',
  fontWeight: '500',
}
const generatePoints = (line, posXs, scale, fullHeight, offset) => {
  return line.map((v, idx) => {
    const y = v >= 0 ? v * scale : Math.abs(v) * scale
    const ypos = v >= 0 ? fullHeight - y - offset : fullHeight - offset + y
    return [posXs[idx], ypos > 0 ? ypos : boxSz.topMargin / 2]
  })
}

const SvgCurve = (line, posXs, scale, fullHeight, offset, color, idx) => {
  const mLine = line
  const points = generatePoints(mLine, posXs, scale, fullHeight, offset)
  //console.log('Points' + JSON.stringify(points))
  const curvePoints = generateCurve(points)
  //console.log('curve' + JSON.stringify(curvePoints))
  return (
    <path
      key={`path#${idx}`}
      d={curvePoints}
      fill="none"
      strokeOpacity="0.5"
      stroke={color}
      strokeWidth="2px"
    />
  )
}

// const generatePath = (line, posXs, scale, fullHeight, offset, color, key) => {
//   const ratioPos = line.map((v, idx) => {
//     const y = v >= 0 ? v * scale : Math.abs(v) * scale
//     const ypos = v >= 0 ? fullHeight - y - offset : fullHeight - offset + y
//     return [posXs[idx], ypos]
//   })
//   var svgRatioPath = 'M'
//   ratioPos.forEach(p => {
//     svgRatioPath += p[0] + ',' + p[1] + ' '
//   })
//   return (
//     <path
//       key={`path#${key}`}
//       d={svgRatioPath}
//       fill="none"
//       strokeOpacity="0.5"
//       stroke={color}
//       strokeWidth="2px"
//     ></path>
//   )
// }

export const SvgInOutLineChart = ({ instanceId, lines: mLines, months, max, min }) => {
  const labels = []
  //const monthLabelTexts = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
  const monthLabels = []
  const lengendFontSz = 9 / 2
  const scale = (boxSz.h - (boxSz.bottomMargin + boxSz.topMargin)) / (max - min)
  const offset = min * scale * -1 + boxSz.topMargin
  const width = (boxSz.w - boxSz.lengendOffset) / (3 * 12)
  const posXs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((v, idx) => {
    return idx * 3 * width + width + boxSz.lengendOffset
  })
  const lines = mLines.map(line => line.map(pt => pt.value))
  const tooltipLabel = mLines.map(line => line.map(pt => pt.label))
  const dataPoints = lines.map((line, idx) => {
    const points = generatePoints(line, posXs, scale, boxSz.h, offset)
    return points
  })
  // construct marker line and label

  // construct marker line and label
  const label0Pos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: boxSz.h + min * scale - boxSz.topMargin,
  }
  const labelTargetPosY = boxSz.h + min * scale - boxSz.topMargin - 10 * scale
  const labelTargetPos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: labelTargetPosY > 0 ? labelTargetPosY : boxSz.topMargin / 2,
  }
  const labelMaxPos = { x: boxSz.lengendOffset - lengendFontSz, y: boxSz.bottomMargin }
  const labelMaxHalfPos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: boxSz.bottomMargin + (max / 2) * scale,
  }
  const labelMinPos = { x: boxSz.lengendOffset - lengendFontSz, y: boxSz.h - boxSz.topMargin }
  const labelMinHalfPos = {
    x: boxSz.lengendOffset - lengendFontSz,
    y: boxSz.h - boxSz.topMargin + (min / 2) * scale,
  }
  // console.log(
  //   `[label0Pos labelTargetPos   ] = ${label0Pos.y} ${labelTargetPos.y}  diff = ${label0Pos.y -
  //     labelTargetPos.y}`
  // )
  if (label0Pos.y - labelTargetPos.y < 7) labelTargetPos.labelOffset = true

  labels.push({ id: 0, labelPos: label0Pos, label: '0%' })
  labels.push({ id: 1, labelPos: labelMaxPos, label: `${Number(max).toFixed(2)}%` })
  labels.push({
    id: 2,
    labelPos: labelMinPos,
    label: `${min > 0 ? '' : '-'}${Number(Math.abs(min)).toFixed(2)}%`,
  })
  labels.push({ id: 3, labelPos: labelMaxHalfPos, label: `${Number(max / 2).toFixed(2)}%` })
  labels.push({
    id: 4,
    labelPos: labelMinHalfPos,
    label: `${min > 0 ? '' : '-'}${Number(Math.abs(min / 2)).toFixed(2)}%`,
  })
  labels.push({ id: 5, labelPos: labelTargetPos, label: `10%` })

  const lowestLabelPosY = labels.map(l => l.labelPos.y).sort((a, b) => b - a)
  // console.log('lowestLabelPosY' + JSON.stringify(lowestLabelPosY))

  months.forEach((month, idx) => {
    const id = `rectPlus${idx}`
    const monthLabelPos = {
      x: idx * 3 * width + width / 2 + boxSz.lengendOffset,
      //y: boxSz.h - boxSz.topMargin / 2,
      y: lowestLabelPosY[0] + 13 + 1,
    }

    monthLabels.push({ id, pos: monthLabelPos, label: month })
  })

  // construct ratio line
  // const ratioPos = data.map((v, idx) => {
  //   const y = v >= 0 ? v * scale : Math.abs(v) * scale
  //   const ypos = v >= 0 ? boxSz.h - y - offset : boxSz.h - offset + y
  //   return [posXs[idx], ypos]
  // })
  // console.log(' max, min :' + max + ':' + min)
  // console.log('ratioPos :' + JSON.stringify(ratioPos))
  // var svgRatioPath = 'M'
  // ratioPos.forEach(p => {
  //   svgRatioPath += p[0] + ',' + p[1] + ' '
  // })

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${boxSz.w} ${boxSz.h}`} className={cls.svgContainer}>
      <defs>
        <filter id="SvgMultiCirclesGaugeshawdow" colorInterpolationFilters="sRGB">
          <feGaussianBlur stdDeviation="0.5" />
          <feDropShadow dx="1" dy="1" stdDeviation="3" floodColor="#bdbdbd" floodOpacity="0.4" />
        </filter>
      </defs>
      {labels.map(({ id, labelPos, label }) => {
        // console.log('labelPos.labelOffset ' + labelPos.labelOffset)
        return (
          <g key={id} id={id}>
            <text
              x={labelPos.x}
              //y={labelPos.y - lengendFontSz / 2}
              y={labelPos.labelOffset ? labelPos.y - 3 : labelPos.y}
              fill={'#6B6967'}
              textAnchor="end"
              style={{
                fontFamily: 'Roboto',
                fontSize: '9px',
                fontWeight: '400',
              }}
            >
              {label}
            </text>
            <line
              x1={labelPos.x + 4}
              y1={labelPos.y}
              x2={`${boxSz.w}`}
              y2={labelPos.y}
              stroke="#7F7C7E"
              strokeWidth="0.5"
              strokeDasharray="3 1"
            />
          </g>
        )
      })}
      {monthLabels.map(({ id, pos, label }) => {
        return (
          <text
            key={id}
            x={pos.x}
            y={pos.y}
            fill={'#959AA9'}
            textAnchor="start"
            style={{
              fontFamily: 'Roboto',
              fontSize: '13px',
              fontWeight: '400',
            }}
          >
            {label}
          </text>
        )
      })}
      {/* {lines.map((line, idx) => {
        return generatePath(line, posXs, scale, boxSz.h, offset, idx === 0 ? '#446DB5' : '#00BBB6', idx)
      })} */}
      {lines.map((line, idx) => {
        return SvgCurve(line, posXs, scale, boxSz.h, offset, idx === 0 ? '#446DB5' : '#00BBB6', idx)
      })}
      {dataPoints.map((line, lineIdx) => {
        return line.map((points, pointIdx) => {
          return (
            <g
              key={`${instanceId}line#${lineIdx}Point${pointIdx}`}
              id={`${instanceId}line#${lineIdx}Point${pointIdx}`}
            >
              <circle cx={points[0]} cy={points[1]} r="1" fill={lineIdx === 0 ? '#446DB5' : '#00BBB6'} />
              <circle
                cx={points[0]}
                cy={points[1]}
                r="6"
                fill={'transparent'}
                style={{ cursor: 'pointer' }}
              />
            </g>
          )
        })
      })}
      {dataPoints.map((line, lineIdx) => {
        return line.map((points, pointIdx) => {
          const rectWidth = tooltipLabel[lineIdx][pointIdx]?.length * 5
          const rectHeight = tooltipsSz.height
          const rectOffset = { x: -1 * (rectWidth / 2), y: -1 * (4 + parseInt(tooltipsSz.height)) }
          const textOffset = { x: rectOffset.x + 6, y: -rectHeight / 2 - 2 }
          const rectPos = { x: points[0] + rectOffset.x, y: points[1] + rectOffset.y }
          const trianglePos = [
            { x: rectPos.x + rectWidth / 2 - 2.5, y: rectPos.y + parseInt(rectHeight) },
            { x: rectPos.x + rectWidth / 2 + 2.5, y: rectPos.y + parseInt(rectHeight) },
            { x: rectPos.x + rectWidth / 2, y: rectPos.y + parseInt(rectHeight) + 2 },
          ]
          return (
            <g key={`tooltips#${lineIdx}Point${pointIdx}`} visibility="hidden">
              <set
                attributeName="visibility"
                to="visible"
                begin={`${instanceId}line#${lineIdx}Point${pointIdx}.mouseenter`}
                end={`${instanceId}line#${lineIdx}Point${pointIdx}.mouseleave`}
              />
              <rect
                x={rectPos.x}
                y={rectPos.y}
                width={rectWidth}
                height={rectHeight}
                stroke="none"
                fill={lineIdx === 0 ? '#446DB5' : '#00BBB6'}
                strokeWidth="5"
                filter="url(#shadow)"
              ></rect>
              <circle cx={points[0]} cy={points[1]} r="4" fill={lineIdx === 0 ? '#446DB5' : '#00BBB6'} />
              <polygon
                points={`${trianglePos[0].x} ${trianglePos[0].y},${trianglePos[1].x} ${trianglePos[1].y},${trianglePos[2].x} ${trianglePos[2].y}`}
                stroke="none"
                fill={lineIdx === 0 ? '#446DB5' : '#00BBB6'}
                strokeWidth="5"
                filter="url(#shadow)"
              />
              <text
                x={points[0] + textOffset.x}
                y={points[1] + textOffset.y}
                fill={'white'}
                style={tooltipsStyle}
              >
                {tooltipLabel[lineIdx][pointIdx]}
              </text>
            </g>
          )
        })
      })}
    </svg>
  )
}

export default SvgInOutLineChart
