import { useEffect, useState } from 'react'
import { useSelector } from '@redux'
import { setInsightsUiData, updateInsightsUiData } from '@features/insightsUi'
import { useDispatch } from 'react-redux'
//import { DAY, generateBarChartData } from './utils'
import { DAY } from './utils'

/* 
  Hook - construst bar char data 
*/
const useBarChartData = bid => {
  const insights = useSelector(({ insights }) => insights)
  const dispatch = useDispatch()
  const [barChartData, setBarChartData] = useState([])
  const [barChartMaxValue, setBarChartMaxValue] = useState(0)
  const [barChartMinValue, setBarChartMinValue] = useState(0)
  const [barChartAvValue, setBarChartAvValue] = useState(0)

  useEffect(() => {
    if (insights?.overallBankDailyBalances) {
      const data = insights.overallBankDailyBalances.map(record => record.balance)
      const balanceMax = Math.max(...data)
      const balanceMin = Math.min(...data)
      const sum = [...data].reduce((a, b) => parseInt(a) + parseInt(b), 0)
      const avg = sum / (data?.length || 1) || 0
      const dataClone = insights.overallBankDailyBalances
        .map((record, id) => {
          const mDate = new Date(record.date)
          return {
            value: record.balance,
            label: mDate.toDateString(),
            short: DAY[mDate.getDay()],
            date: mDate.getTime(),
            id: id,
          }
        })
        .reverse()
      ;(async () => {
        await dispatch(setInsightsUiData(dataClone))
        await dispatch(updateInsightsUiData())
      })()
      setBarChartData(
        insights.overallBankDailyBalances
          .map((record, id) => {
            const mDate = new Date(record.date)
            return {
              value: record.balance,
              label: mDate.toDateString(),
              short: DAY[mDate.getDay()],
              date: mDate,
              id: id,
            }
          })
          .reverse()
      )
      setBarChartMaxValue(balanceMax)
      setBarChartMinValue(balanceMin)
      setBarChartAvValue(avg)
    }
    // eslint-disable-next-line
  }, [insights])

  return { barChartData, barChartMaxValue, barChartMinValue, barChartAvValue }
}
export default useBarChartData
