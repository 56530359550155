import React from 'react'

export const PropellCenter = ({ children, w, h }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: w ? `${w}px` : '16px',
        height: h ? `${h}px` : '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export const PropellIconDown = () => {
  return (
    <PropellCenter>
      <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.27881 1L5.25257 5L1.22633 1"
          stroke="#292826"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </PropellCenter>
  )
}

export const PropellIconUp = () => {
  return (
    <PropellCenter>
      <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.15137 5.64709L5.1776 1.4118L9.20384 5.64709"
          stroke="#2E3238"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </PropellCenter>
  )
}
