import api from '@api'
// import { getUnixTime } from 'date-fns'
// import { setFetchOrdersState } from '@features/uiStateHelper'
import { setMerchants, setConnectedMerchant, setCustomers } from './index.js'
import { addAlert } from '@features/alerts'

export const getMerchants = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/merchants`)
    dispatch(setMerchants(res.data))
    // dispatch(setFetchOrdersState(true))
    // dispatch(setOrders(mockData))
  } catch (error) {
    console.error(error)
  }
}

export const getMerchantsConnect = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/merchants/connected`)
    dispatch(setConnectedMerchant(res.data))
    // dispatch(setFetchOrdersState(true))
    // dispatch(setOrders(mockData))
  } catch (error) {
    console.error(error)
  }
}

export const getMerchantsCustomer = () => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/merchants/customers`)
    dispatch(setCustomers(res.data))
    // dispatch(setFetchOrdersState(true))
    // dispatch(setOrders(mockData))
  } catch (error) {
    console.error(error)
  }
}

export const connectMerchant = merchantId => async (dispatch, getState) => {
  try {
    const res = await api.post(`/v1/merchants/${merchantId}`, {
      merchant_id: merchantId,
    })
    if (res.status === 201) {
      await dispatch(
        addAlert({
          message: 'Connect merchant request sent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    }
    return res.data
    //dispatch(setMerchants(res.data))
    // dispatch(setFetchOrdersState(true))
    // dispatch(setOrders(mockData))
  } catch (error) {
    console.error(error)
    await dispatch(
      addAlert({
        message: 'Fail to connect merchant',
        error: true,
        dismiss: true,
        top: true,
        dismissTimeout: 10 * 1000,
      })
    )
    return null
  }
}

export const inviteCustomer = ({ employeeId, email }) => async (dispatch, getState) => {
  try {
    const { business } = getState()
    const res = await api.post(`/v1/businesses/${business.id}/employees/${employeeId}/share_referral_code`, {
      businessId: business.id,
      id: employeeId,
      inviteeEmail: email,
    })
    if (res.status === 201) {
      await dispatch(
        addAlert({
          message: 'Customer invite email sent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    }
    return res.status
  } catch (error) {
    console.error(error)
    return null
  }
}
// POST /v1/lendings/precheck-propell-trade
export const precheckPropellTrade = ({ email, totalMinorUnits }) => async (dispatch, getState) => {
  try {
    const res = await api.post(`/v1/lendings/precheck-propell-trade`, {
      email,
      totalMinorUnits,
    })
    if (res.status === 200) {
      await dispatch(
        addAlert({
          message: 'Customer Propell Precheck Success',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    }
    return res
  } catch (error) {
    console.error(error.response)
    await dispatch(
      addAlert({
        message: error.response.data.error,
        error: true,
        dismiss: true,
        top: true,
        dismissTimeout: 10 * 1000,
      })
    )
    return error.response
  }
}
