import { createSlice } from '@redux'

export const pageSize = 8
export const initialState = {
  loans: [],
  pages: [],
  overDueLoans: [],
  pageSize,
}

const slice = createSlice({
  name: 'tradeLending',
  initialState,
  reducers: {
    setConsolidateLoans: (state, { payload }) => {
      state.consolidatedLoans = payload
    },
    setConsolidatedLoansDataFlag: (state, { payload }) => {
      state.consolidatedLoansDataFlag = payload
    },
    setLoans: (state, { payload }) => {
      state.loans = payload
    },
    addLoans: (state, { payload }) => {
      const loans = [...state.loans, payload]
      state.loans = loans
    },
    updateLoan: (state, { payload }) => {
      //const filteredLoans = state.loans.filter(loan => loan.id !== payload.id)
      const loans = [...state.loans]
      const idx = loans.findIndex(loan => loan.id === payload.id)
      loans[idx] = payload
      state.loans = loans
      //state.loans = [...filteredLoans, payload]
    },
    setOverDueLoans: (state, { payload }) => {
      state.overDueLoans = payload
    },
    setLoansDataFlag: (state, { payload }) => {
      state.loansDataFlag = payload
    },
    setTransactionsFlag: (state, { payload }) => {
      state.transactionsFlag = payload
    },
    setPages: (state, { payload }) => {
      state.pages = payload
    },
    clear: () => {
      return initialState
    },
  },
})

// export { default as getLoan } from './getLoan'
export { default as makePayment } from './makePayment'
export { default as getCcToken } from './getCcToken'
export { default as makeCcPayment } from './makeCcPayment'
export { default as getCcTransactionStatus } from './getCcTransactionStatus'
export { default as getLoansData } from './getLoansData'
export { default as getTransactionsData } from './getTransactionsData'
export { default as loans2Pages } from './loans2Pages'
export { default as increaseLocRequest } from './increaseLocRequest'
export { default as getCreditWatchScores } from './getCreditWatchScores'
export { default as getLoansPrecheck } from './getLoansPrecheck'
export { default as getLoansPrecheckPending } from './getLoansPrecheckPending'
export { default as postLoansConsolidationRequest } from './postLoansConsolidationRequest'
export { default as getLoansConsolidationRequest } from './getLoansConsolidationRequest'
export { default as updateLoanName } from './updateLoanName'
export { default as getRefinance } from './getRefinance'
export { default as postPreContractDisclosureQuestionnaire } from './postPreContractDisclosureQuestionnaire'
export { default as getPreContractsPending } from './getPreContractsPending.js'
export const {
  clear,
  setConsolidateLoans,
  setConsolidatedLoansDataFlag,
  setLoans,
  addLoans,
  setOverDueLoans,
  updateLoan,
  setPages,
  setTransactionsFlag,
  setLoansDataFlag,
} = slice.actions
export default slice.reducer
