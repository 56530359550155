import React, { useState } from 'react'
import cls from './index.module.scss'
import { Flex } from '@chakra-ui/react'
import { PropellIconDown, PropellIconUp } from './icons'
import { useBreakpoint } from '@hooks'
const TableHeader = ({ fieldObjs, setSortingState, sortingState, isCollapsable }) => {
  return (
    <Flex className={cls.rowHeader}>
      {fieldObjs.map((obj, idx) => {
        return (
          <Flex
            key={idx}
            w={`${obj.width}%`}
            className={cls.rowCell}
            onClick={() => {
              if (setSortingState && sortingState) {
                if (obj.field === sortingState.field) {
                  setSortingState({ field: sortingState.field, direction: !sortingState.direction })
                } else {
                  setSortingState({ field: obj.field, direction: false })
                }
              }
            }}
          >
            {obj.label}
          </Flex>
        )
      })}
      {isCollapsable ? <Flex w="24px " className={cls.rowCell} /> : null}
    </Flex>
  )
}

const TableRow = ({ idx, record, fieldObjs, isCollapsable, mobileViewStyle }) => {
  const fields = fieldObjs.map(o => o.field)
  const [openState, setOpenState] = useState(false || record?.initOpenState)
  const breakpoint = useBreakpoint()

  return breakpoint > 2 ? (
    <Flex key={idx} direction="column">
      <Flex className={openState ? cls.rowOpen : cls.row}>
        {fields.map((f, index) => {
          return (
            <Flex key={index} w={`${fieldObjs[index].width}%`} className={cls.rowCell}>
              {record[f].customUI ? record[f].customUI : record[f].value}
            </Flex>
          )
        })}
        {isCollapsable ? (
          <Flex
            className={cls.rowCellBtn}
            onClick={() => {
              setOpenState(!openState)
            }}
          >
            {openState ? <PropellIconUp /> : <PropellIconDown />}
          </Flex>
        ) : null}
      </Flex>
      {/* Collapsable area */}
      {openState ? (
        <Flex key={idx} className={cls.rowCollapsableArea}>
          {record.collapsableUI}
        </Flex>
      ) : null}
    </Flex>
  ) : (
    <>
      {mobileViewStyle === 1 ? (
        <Flex
          key={`mobile-${idx}`}
          //style={{ flexDirection: 'row', width: '100%', background: 'white', border: '1px green solid' }}
          className={cls.mobileViewStyle1Row}
        >
          <Flex
            //style={{ flexDirection: 'column', width: '100%', border: '1px green solid' }}
            className={cls.mobileViewStyle1RowCell}
          >
            {fields.map((f, index) => {
              return (
                //<Flex key={index} w="100%" className={cls.rowCellMobile} border="1px solid purple">
                <Flex key={index} w="100%" className={cls.mobileViewStyle1RowCellSubCell}>
                  {record[f].customUI ? record[f].customUI : record[f].value}
                </Flex>
              )
            })}
            {isCollapsable ? (
              <Flex
                className={cls.isCollapsableBtn}
                onClick={() => {
                  setOpenState(!openState)
                }}
              >
                {openState ? <PropellIconUp /> : <PropellIconDown />}
              </Flex>
            ) : null}
          </Flex>
          {/* {isCollapsable ? (
            <Flex
              w="48px"
              align="flex-start"
              justify="center"
              pt="16px"
              mr="16px"
              //border="1px green solid"
              onClick={() => {
                setOpenState(!openState)
              }}
            >
              {openState ? <PropellIconUp /> : <PropellIconDown />}
            </Flex>
          ) : null} */}
        </Flex>
      ) : (
        <Flex
          key={`mobile-${idx}`}
          //style={{ flexDirection: 'row', width: '100%', background: 'white', border: '1px green solid' }}
          className={cls.mobileViewRow}
        >
          <Flex
            //style={{ flexDirection: 'column', width: '100%', border: '1px green solid' }}
            className={cls.mobileViewRowCell}
          >
            {fields.map((f, index) => {
              return (
                //<Flex key={index} w="100%" className={cls.rowCellMobile} border="1px solid purple">
                <Flex key={index} w="100%" className={cls.mobileViewRowCellSubCell}>
                  {record[f].customUI ? record[f].customUI : record[f].value}
                </Flex>
              )
            })}
          </Flex>
          {isCollapsable ? (
            <Flex
              w="48px"
              align="flex-start"
              justify="center"
              pt="16px"
              //border="1px green solid"
              mr="16px"
              onClick={() => {
                setOpenState(!openState)
              }}
            >
              {openState ? <PropellIconUp /> : <PropellIconDown />}
            </Flex>
          ) : null}
        </Flex>
      )}

      {/* Collapsable area */}
      {openState ? (
        <Flex key={idx} className={cls.rowCollapsableArea}>
          {record.collapsableUI}
        </Flex>
      ) : null}
    </>
  )
}

const Component = ({
  fieldObjs,
  records,
  setSortingState,
  sortingState,
  isCollapsable,
  disableHeader,
  CustomTableHeader,
  mobileViewStyle,
}) => {
  if (!records?.length) return null
  return (
    <Flex w="100%" direction="column">
      {!disableHeader ? (
        CustomTableHeader ? (
          <CustomTableHeader
            fieldObjs={fieldObjs}
            setSortingState={setSortingState}
            sortingState={sortingState}
            isCollapsable={isCollapsable}
          />
        ) : (
          <TableHeader
            fieldObjs={fieldObjs}
            setSortingState={setSortingState}
            sortingState={sortingState}
            isCollapsable={isCollapsable}
          />
        )
      ) : null}
      {records.map((record, index) => {
        return (
          <TableRow
            key={index}
            idx={index}
            record={record}
            fieldObjs={fieldObjs}
            isCollapsable={isCollapsable}
            mobileViewStyle={mobileViewStyle}
          />
        )
      })}
    </Flex>
  )
}

export default Component
