import api from '@api'
import { addAlert } from '@features/alerts'

const result = values => async (dispatch, getState) => {
  const res = await api
    .post(`/v1/lendings/loans/${values.id}/repayments`, values)
    .then(result => {
      dispatch(
        addAlert({
          message: 'Make Payment request sent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      return result
    })
    .catch(err => {
      console.error(err.response.data.error)
      api.post(`/v1/crashes?data=${err.response.data.error}`)
      dispatch(
        addAlert({
          message: err.response.data.error,
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      return err.response
    })

  //console.log('loanCcRepaymentCallback status=' + res.status + 'res.data=' + JSON.stringify(res.data))
  if (res.data) {
    // For reading transaction results from Webview
    if (typeof window.loanCcRepaymentCallback === 'function') {
      window.loanCcRepaymentCallback({ result: res.status === 201 ? 'success' : 'fail', data: res.data })
    }

    const androidString = JSON.stringify({ result: res.status === 201 ? 'success' : 'fail', data: res.data })
    /*eslint-disable */
    if (typeof Android === 'object' && typeof Android.loanCcRepaymentCallback === 'function') {
      Android.loanCcRepaymentCallback(androidString)
    }
    /*eslint-enable */
  }

  return res.data
}
export default result
