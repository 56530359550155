import { createSlice } from '@redux'
import api from '@api'
import { set as setBusiness } from '@features/business'
import { addAlert } from '@features/alerts'
import { set2FaEnabled } from '../auth/login'

export const initialState = {
  email: null,
  firstName: null,
  id: null,
  lastName: null,
  customerId: null,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCustomerId: (state, { payload }) => {
      return {
        ...state,
        customerId: payload || null,
      }
    },

    set: (state, { payload }) => {
      const {
        firstName,
        lastName,
        id,
        email,
        mobilePhone,
        employments,
        acceptanceRequiredOnNewTerms,
        insightsVersionDate,
        insightsRead,
        showRequestIncrease,
        utmSource,
        propellSource,
        veriffStatus,
        veriffRequestLink,
      } = payload
      return {
        ...state,
        email,
        firstName,
        id,
        lastName,
        mobilePhone:
          employments[0]?.roleDescription === 'Administrator'
            ? mobilePhone
              ? mobilePhone
              : employments[0]?.mobilePhone
            : employments[0]?.mobilePhone || null,
        role: employments[0]?.roleDescription || null,
        acceptanceRequiredOnNewTerms,
        insightsVersionDate,
        insightsRead,
        showRequestIncrease,
        utmSource,
        propellSource,
        veriffStatus,
        veriffRequestLink,
      }
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear, setCustomerId } = slice.actions

export default slice.reducer

export const getUser = () => async dispatch => {
  const res = await api.get('/v1/user')
  dispatch(set(res.data))
  dispatch(setBusiness(res.data?.employments[0]?.business))
  return res.data
}

export const putUser = values => async (dispatch, getState) => {
  try {
    await api.put(`/v1/user`, values)
    await dispatch(set2FaEnabled(true))
    await dispatch(addAlert({ message: '2FA enabled', info: true, dismiss: true, top: true }))
    return true
  } catch (error) {
    console.error(error)
    await dispatch(addAlert({ message: error?.response?.data?.error, error: true, dismiss: true, top: true }))
    return false
    // return dispatch(
    //   addAlert({ message: `Business setting fail to update`, error: true, dismiss: true, top: true })
    // )
  }
}
