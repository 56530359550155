import { createSlice } from '@redux'
export const initialState = {
  currentStep: -1,
  subStep: 0,
  numOfSubStep: { step1: 2, step2: 1 },
}

const originationsSlice = createSlice({
  name: 'originations/step',
  initialState,
  reducers: {
    incCurrentStep: (state, { payload }) => {
      if (payload?.ignoreSubStep === true) {
        state.currentStep++
      }
      // else if (state.currentStep === 1 && state.subStep === 0) {
      //   state.subStep++
      // } else if (state.currentStep === 1 && state.subStep === 1) {
      //   state.subStep = initialState.subStep
      //   state.currentStep++
      //   // } else if (state.currentStep === 2) {
      //   //   if (state.numOfSubStep.step2 - 1 === state.subStep) {
      //   //     state.currentStep++
      //   //   } else {
      //   //     state.subStep++
      //   //   }
      //   //
      // }
      else {
        state.currentStep++
        state.subStep = 0
      }
      //localStorage.setItem('origination', JSON.stringify(state))
    },
    decCurrentStep: (state, { payload }) => {
      if (payload?.ignoreSubStep === true) {
        state.currentStep--
      } else if (state.currentStep === 1 && state.subStep === 1) {
        state.subStep--
      } else if (state.currentStep === 1 && state.subStep === 0) {
        state.subStep = initialState.subStep
        state.currentStep--
      } else if (state.currentStep === 2) {
        if (state.subStep === 0) {
          state.currentStep--
          state.subStep = state.numOfSubStep.step1 - 1
        } else {
          state.subStep--
        }
      } else {
        state.currentStep--
      }
      //localStorage.setItem('origination', JSON.stringify(state))
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload
      //localStorage.setItem('origination', JSON.stringify(state))
    },
    setSubStep: (state, { payload }) => {
      state.subStep = payload
      //localStorage.setItem('origination', JSON.stringify(state))
    },
    setNumOfSubStep: (state, { payload }) => {
      state.numOfSubStep = { ...state.numOfSubStep, ...payload }
      //localStorage.setItem('origination', JSON.stringify(state))
    },
    // restoreState: state => {
    //   const previousState = localStorage.getItem('origination')
    //   if (previousState !== null) {
    //     state.currentStep = JSON.parse(previousState).currentStep
    //     state.subStep = JSON.parse(previousState).subStep
    //   }
    // },
    clear: () => {
      return initialState
    },
  },
})

export const {
  incCurrentStep,
  decCurrentStep,
  setCurrentStep,
  setSubStep,
  setNumOfSubStep,
  clear,
  //  restoreState,
} = originationsSlice.actions

export default originationsSlice.reducer
