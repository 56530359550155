import React from 'react'
import cls from './index.module.scss'

export const PropellControlledRadioBtn = ({ disabled, state, updateState, children }) => {
  return (
    <div
      className={cls.container}
      onClick={() => {
        if (!disabled && updateState) {
          updateState()
        }
      }}
    >
      <div className={cls.btnContainer}>
        {disabled ? (
          <div style={{ cursor: 'not-allowed' }}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.5 19C15.4706 19 19.5 14.9706 19.5 10C19.5 5.02944 15.4706 1 10.5 1C5.52944 1 1.5 5.02944 1.5 10C1.5 14.9706 5.52944 19 10.5 19Z"
                fill="#F0F0F0"
                stroke="#6AACEC"
                strokeWidth="1.1875"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        ) : (
          <div className={cls.svgContainer}>
            {state === true ? (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="6" strokeWidth="4" stroke="#6AACEC" />
              </svg>
            ) : (
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="8" strokeWidth="0.75" stroke="#cccccc" />
              </svg>
            )}
          </div>
        )}
      </div>
      <div className={cls.childContainer}>{children}</div>
    </div>
  )
}

export default PropellControlledRadioBtn
