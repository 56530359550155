import React from 'react'
import cls from './index.module.scss'
import mixpanel from 'mixpanel-browser'
import { PropellMixPanelEnabled } from '@Apps/..'

const ButtonV4 = ({ children, primary, ...props }) => {
  return (
    <div
      {...props}
      className={primary ? cls.btnPrimary : cls.btn}
      style={props?.disabled ? { cursor: 'not-allowed' } : null}
      onClick={e => {
        if (PropellMixPanelEnabled) mixpanel.track('ButtonOnClick', { source: `${children}` })
        if ('onClick' in props && props?.disabled !== true) props.onClick(e)
      }}
    >
      {children}
    </div>
  )
}

export default ButtonV4
