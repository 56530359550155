import * as React from 'react'
import classnames from 'classnames'

import cls from './nav-link-secondary.module.scss'

const UikNavLink = ({ rightEl, children, className, highlighted, Component, isActive, ...rest }: UikNavLinkProps) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [cls.highlighted]: highlighted,
      [cls.active]: isActive,
    })}
    {...rest}
  >
    <span className={cls.text}>{children}</span>
    {rightEl && <span className={cls.rightEl}>{rightEl}</span>}
  </Component>
)

UikNavLink.defaultProps = {
  className: null,
  rightEl: null,
  highlighted: false,
  children: null,
  Component: 'a',
}

export default UikNavLink
