import React from 'react'
import { Flex } from '@chakra-ui/react'
import { UikTopBar, UikTopBarSection, UikTopBarTitle, UikContainerHorizontal } from '@uik'
import { mainLogo, brandName } from '@theme'
import cls from './index.module.scss'

const Maintenance = () => (
  <div>
    <UikTopBar>
      <UikTopBarSection>
        <UikTopBarTitle className={cls.mainLogo}>
          <img src={mainLogo} alt={`${brandName} Logo`} style={{ width: '100%' }} />
        </UikTopBarTitle>
      </UikTopBarSection>
    </UikTopBar>
    <UikContainerHorizontal>
      <Flex className={cls.mainSection}>
        <div>
          <h2> We'll be back soon!</h2>
          <div>
            Sorry for the inconvenience but we're performing some maintenance at the moment. If you need to
            you can always{' '}
            <a
              className={cls.linkText}
              target="_blank"
              rel="noopener noreferrer"
              href="https://propellme.com/contact-us/"
            >
              contact us
            </a>
            , otherwise, we'll be back online shortly!
          </div>
          <br></br>
          <div>&#x2014; The Propell Team</div>
        </div>
      </Flex>
    </UikContainerHorizontal>
  </div>
)

export default Maintenance
