import React from 'react'
import cls from './index.module.scss'
import mixpanel from 'mixpanel-browser'
import { PropellMixPanelEnabled } from '@Apps/..'
import { Spinner } from '@chakra-ui/react'

const ButtonV3 = ({ children, primary, ...props }) => {
  return (
    <button
      {...props}
      className={primary ? cls.btnPrimary : cls.btn}
      style={props.disabled ? { cursor: 'not-allowed' } : null}
      onClick={e => {
        if (PropellMixPanelEnabled) mixpanel.track('ButtonOnClick', { source: `${children}` })
        if ('onClick' in props) props.onClick(e)
      }}
    >
      {props.isloading === 'true' ? <Spinner size="xs" /> : null}
      {children}
    </button>
  )
}

export default ButtonV3
