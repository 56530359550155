import { useAction } from '@redux'
import { useEffect, useState } from 'react'
import { getBankInitialiseLink } from '@features/bankFeeds'

/* 
  Hook - for fetch illion iframe URL 
   setIllionPath        :   method to setIllion path state variable
   illionPath           :   state variable for storing illion iframe path
   requestingIllionPath :   state variable to store iframe requesting state and also trigger api request
   requestIllionPath    :   method to set requestingIllionPath state variable
*/
const useBankInit = (bid, path) => {
  const [requestingIllionPath, requestIllionPath] = useState(false)
  const addInitErrCnt = { err: 0 }
  const [illionPath, setIllionPath] = useState()
  const [bankConnectionId, setBankConnectionId] = useState()
  const getBankInitialiseLinkAction = useAction(getBankInitialiseLink)

  const addOrSelectBankAccount = async (bid, bankConnectionId) => {
    try {
      const initData = await getBankInitialiseLinkAction(bid, bankConnectionId, path)
      if (initData) {
        if ('iframeUrl' in initData) {
          if (initData.iframeUrl.search('https') > -1) {
            setIllionPath(initData.iframeUrl)
            requestIllionPath(false)
          }
        }
      } else {
        addInitErrCnt.err++
        console.error('BankInitialiseErr #' + addInitErrCnt)
        if (addInitErrCnt < 3) {
          addOrSelectBankAccount(bid, bankConnectionId)
        } else {
          console.error('BankInitialiseErr #' + addInitErrCnt + 'Exceed err limit')
        }
      }
    } catch (e) {
      addInitErrCnt.err++
      console.log('BankInitialiseErr #' + addInitErrCnt.err + JSON.stringify(e))
      if (addInitErrCnt.err < 3) {
        addOrSelectBankAccount(bid, bankConnectionId)
      } else {
        console.error('BankInitialiseErr #' + addInitErrCnt.err + 'Exceed err limit')
        const errCnt = Number(localStorage.getItem('bankFeedErrCnt')) + 1
        localStorage.setItem('bankFeedErrCnt', errCnt)
        localStorage.setItem('bankFeedErr', 'fail to initialise external connection')
        setTimeout(() => {
          window.location.reload()
        }, 5000)
      }
    }
  }

  // start requesting iframe PATH from illion API
  useEffect(() => {
    if (requestingIllionPath) {
      //console.log('requesting bankfeed path')
      addOrSelectBankAccount(bid, bankConnectionId)
    }
    // eslint-disable-next-line
  }, [requestingIllionPath])

  return {
    setIllionPath,
    illionPath,
    requestingIllionPath,
    requestIllionPath,
    bankConnectionId,
    setBankConnectionId,
  }
}
export default useBankInit
