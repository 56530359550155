import { createSlice } from '@redux'
import api from '@api'
import { addAlert } from '@features/alerts'
import getBaseUrl from '@api/getBaseUrl'
import { tenentId } from '@theme'
import { sleep } from '@lib'

export const initialState = {
  name: null,
  id: null,
  locationId: null,
  sfFacilityLending: null,
  sfOpportunity: null,
  stage: null,
  tenderTypesAbilities: null,
  defaultPassOnFeeEnabled: false,
  passOnFeeChangeble: false,
}

const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        const {
          name,
          id,
          locations,
          sfAccount,
          sfOpportunity,
          stage,
          tenderTypesAbilities,
          stripePublicKey,
          bankConnected,
          defaultPassOnFeeEnabled,
          passOnFeeChangeble,
          gstFeeEnabled,
        } = payload
        const sfFacilityLending6Month = sfAccount
          ? sfAccount?.sfFacilityLendings.filter(lending => {
              return lending.recordType === 'lending_6month'
            })[0]
          : null
        const sfFacilityLending12Month = sfAccount
          ? sfAccount?.sfFacilityLendings.filter(lending => {
              return lending.recordType === 'lending_12month'
            })[0]
          : null
        return {
          ...state,
          name,
          id,
          locationId: locations[0].id,
          sfAccount,
          sfFacilityLending: {
            ...sfFacilityLending6Month,
            overallLoanAvailable: sfAccount?.overallLoanAvailable6month,
          },
          sfFacilityLending12Month: {
            ...sfFacilityLending12Month,
            overallLoanAvailable: sfAccount?.overallLoanAvailable12month,
          },
          lendingInCollection: sfAccount?.inCollection,
          sfOpportunity: sfOpportunity,
          stage: stage,
          tenderTypesAbilities,
          stripePublicKey,
          bankConnected,
          defaultPassOnFeeEnabled,
          passOnFeeChangeble,
          gstFeeEnabled,
        }
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions

export default slice.reducer

export const getBusiness = () => async (dispatch, getState) => {
  const { business } = getState()
  try {
    const res = await api.get(`/v1/businesses/${business.id}`)
    dispatch(set({ ...res.data }))
  } catch (error) {
    console.error(error)
  }
}

export const putBusiness = values => async (dispatch, getState) => {
  const { business } = getState()
  try {
    await api.put(`/v1/businesses/${business.id}`, values)
    return dispatch(
      addAlert({ message: 'Business setting successfully updated', info: true, dismiss: true, top: true })
    )
  } catch (error) {
    console.error(error)
    return dispatch(
      addAlert({ message: `Business setting fail to update`, error: true, dismiss: true, top: true })
    )
  }
}

export const getFees = () => async (dispatch, getState) => {
  const { business } = getState()

  const res = await api.get(`/v1/businesses/${business.id}/fees`)
  dispatch(set({ ...business, fees: res.data }))
}

export const putFee = values => async (dispatch, getState) => {
  const { business } = getState()
  try {
    await api.put(`/v1/businesses/${business.id}/fees/${values.id}`, values)
    return dispatch(
      addAlert({ message: 'Fees setting successfully updated', info: true, dismiss: true, top: true })
    )
  } catch (error) {
    console.error(error)
    return dispatch(
      addAlert({ message: `Fees setting fail to update`, error: true, dismiss: true, top: true })
    )
  }
}

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  const response = await api.post(`/v1/user/jwt-refresh`, {
    refreshToken,
  })
  if (!response.data) {
    return false //Promise.reject(error)
  }

  const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data

  localStorage.setItem('accessToken', newAccessToken)
  localStorage.setItem('refreshToken', newRefreshToken)
  return true
}

export const postBusinessDocUpload = payloadObj => async (dispatch, getState) => {
  const { business } = getState()

  var res
  try {
    const { BASE_URL } = getBaseUrl()
    const formData = new FormData()
    const accessToken = localStorage.getItem('accessToken')
    formData.append('purpose', payloadObj.purpose)
    formData.append('file', payloadObj.file[0])
    formData.append('businessId', business.id)
    const apiObj = {
      method: 'POST',
      body: formData,
      headers: {
        'X-Propell-Name': 'Propell',
        'X-Propell-Platform': 'Web',
        'X-Propell-Version': 'appVersion',
        'X-Propell-Build': 'buildVersion',
        authorization: `Bearer ${accessToken}`,
        'Tenant-Id': `${tenentId}`,
      },
    }
    res = await fetch(BASE_URL + `/v1/businesses/${business.id}/files`, apiObj)

    if (res?.status === 401) {
      const result = await refreshToken()
      if (result) {
        console.log('refresh and try again')
        const updateAccessToken = localStorage.getItem('accessToken')
        apiObj.headers = {
          'X-Propell-Name': 'Propell',
          'X-Propell-Platform': 'Web',
          'X-Propell-Version': 'appVersion',
          'X-Propell-Build': 'buildVersion',
          authorization: `Bearer ${updateAccessToken}`,
          'Tenant-Id': `${tenentId}`,
        }
        res = await fetch(BASE_URL + `/v1/businesses/${business.id}/files`, apiObj)
      }
    }
    let data = await res.json()
    return { data }
  } catch (error) {
    console.error('postBusinessDirector id upload Error#' + JSON.stringify(error?.response))
    localStorage.setItem('oldPath', window.location.pathname)

    await dispatch(
      addAlert({
        message: `Login token expired. Please login again and try again`,
        error: true,
        dismiss: true,
        top: true,
        dismissTimeout: 5 * 1000,
      })
    )
    await sleep(3 * 1000)
    window.location.pathname = '/login'
    return { status: 'ID upload Errors', error: 'File upload error. File must be less than 10MB.' }
  }
}

export const postSfCase = values => async (dispatch, getState) => {
  const { business } = getState()
  var rc
  await api
    .post(`/v1/businesses/${business.id}/cases`, {
      ...values,
      business_id: business.id,
    })
    .then(() => {
      dispatch(
        addAlert({
          message: `Payout quote request sent`,
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      rc = 0
    })
    .catch(err => {
      console.error(err)
      dispatch(
        addAlert({
          message: `Payout quote request fail`,
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      rc = -1
    })
  // console.log('rc :' + rc)
  // console.log('res.data' + JSON.stringify(res?.data))
  return rc
}
