import * as React from 'react'
import classnames from 'classnames'

import cls from './content.module.scss'

const UikContent = ({ className, Component, children, contentCenter, ...rest }: UikContentProps) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [cls.contentCenter]: contentCenter,
    })}
    {...rest}
  >
    {children}
  </Component>
)

UikContent.defaultProps = {
  className: null,
  Component: 'div',
  contentCenter: false,
  children: null,
}

export default UikContent
