import { createSlice } from '@redux'
import { api } from '@api'
import getBaseUrl from '@api/getBaseUrl'
import { tenentId } from '@theme'

export const initialState = {}

const slice = createSlice({
  name: 'originations/business',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        return payload
        // return { ...state, ...payload }
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions
export default slice.reducer

export const postBusinessDetail = payloadObj => async (dispatch, getState) => {
  var res
  try {
    //console.log('post BusinessDetail Form' + JSON.stringify(payloadObj))
    res = await api.post(`/v1/origination_forms/business-details`, payloadObj)
    //console.log('post BD result :' + JSON.stringify(res))
    const state = getState()
    dispatch(
      set({
        ...state.originations.business,
        detail: { ...payloadObj },
      })
    )
    return { status: res.status }
  } catch (error) {
    console.error('BusinessDetail Error#' + JSON.stringify(error.response))
    return { status: error.response.status, error: error.response.data.error }
  }
}

export const postBusinessFinancial = payloadObj => async (dispatch, getState) => {
  try {
    //console.log('post BusinessFinancial Form' + JSON.stringify(payloadObj))
    const res = await api.post(`/v1/origination_forms/business-financials`, payloadObj)
    //console.log('post BF result :' + JSON.stringify(res))
    const state = getState()
    dispatch(
      set({
        ...state.originations.business,
        financial: { ...payloadObj },
      })
    )
    return { status: res.status }
  } catch (error) {
    console.error('BusinessFinancial Error#' + JSON.stringify(error?.response))
    return { status: error?.response?.status, error: error?.response?.data?.error }
  }
}

export const postBusinessMerchantBank = payloadObj => async (dispatch, getState) => {
  try {
    //console.log('post BusinessFinancial Form' + JSON.stringify(payloadObj))
    const res = await api.post(`/v1/origination_forms/merchant-bank`, payloadObj)
    //console.log('post BF result :' + JSON.stringify(res))
    const state = getState()
    dispatch(
      set({
        ...state.originations.business,
        merchantBank: { ...payloadObj },
      })
    )
    return { status: res.status }
  } catch (error) {
    console.error('BusinessMerchantBank Error#' + JSON.stringify(error?.response))
    return { status: error?.response?.status, error: error?.response?.data?.error }
  }
}

export const postBusinessReviewCompleted = payloadObj => async (dispatch, getState) => {
  try {
    //console.log('post BusinessFinancial Form' + JSON.stringify(payloadObj))
    const res = await api.post(`/v1/origination_forms/business-review`, payloadObj)
    //console.log('post BR complete result :' + JSON.stringify(res))
    const state = getState()
    dispatch(
      set({
        ...state.originations.business,
        reviewCompleted: { ...payloadObj },
      })
    )
    return { status: res.status }
  } catch (error) {
    console.error('BusinessReview Completed Error#' + JSON.stringify(error.response))
    return { status: error.response.status, error: error.response.data.error }
  }
}

export const postBusinessDirector = payloadObj => async (dispatch, getState) => {
  try {
    const res = await api.post(`/v1/origination_forms/director-info`, payloadObj)
    //console.log('post BR complete result :' + JSON.stringify(res))
    const state = getState()
    dispatch(
      set({
        ...state.originations.business,
        director: { ...payloadObj },
      })
    )
    return { status: res.status }
  } catch (error) {
    console.error('postBusinessDirector Error#' + JSON.stringify(error.response))
    return { status: error.response.status, error: error.response.data.error }
  }
}

export const getOriginationCurrent = () => async (dispatch, getState) => {
  try {
    //console.log('get Origination Form current  State')
    const res = await api.get('/v1/origination_forms/current')
    //console.log('get Origination Form current result :' + JSON.stringify(res.data.stage))
    const state = getState()
    const businessFinFields = Object.keys(res.data).filter(e => {
      return e.includes('businessFin')
    })
    const finObj = businessFinFields.length > 0 ? {} : null
    businessFinFields.forEach(name => {
      finObj[name] = res.data[name]
    })
    dispatch(
      set({
        ...state.originations.business,
        businessFin: finObj,
        current: [res.data.stage],
        creditRating: res.data?.creditRating,
        updatedAt: res.data?.updatedAt,
        creditHitApprovedAt: res.data?.creditHitApprovedAt,
        locRequestedAt: res.data?.locRequestedAt,
        skippedBankAccountLoad: res.data?.skippedBankAccountLoad,
      })
    )
    return { stage: res.data.stage, subStage: res.data.subStage }
  } catch (error) {
    console.error('BR complete Error#' + JSON.stringify(error))
    return null
  }
}
/*
    const refreshToken = localStorage.getItem('refreshToken')

    if (!refreshToken) {
      return Promise.reject(error)
    }

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + token
        resolve(axios(errorResponse.config))
      })
    })

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true

      const response = await api.post(`/v1/user/jwt-refresh`, {
        refreshToken,
      })
*/

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')
  const response = await api.post(`/v1/user/jwt-refresh`, {
    refreshToken,
  })
  if (!response.data) {
    return false //Promise.reject(error)
  }

  const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data

  localStorage.setItem('accessToken', newAccessToken)
  localStorage.setItem('refreshToken', newRefreshToken)
  return true
}

export const postBusinessDirectorDocUpload = payloadObj => async (dispatch, getState) => {
  var res
  try {
    const { BASE_URL } = getBaseUrl()
    const formData = new FormData()
    // const refreshToken = localStorage.getItem('refreshToken')
    // //const response =
    // const refreshResponse = await api.post(`/v1/user/jwt-refresh`, {
    //   refreshToken,
    // })
    //console.log('response ' + JSON.stringify(refreshResponse))
    // localStorage.setItem('accessToken', refreshResponse.data.accessToken)
    // localStorage.setItem('refreshToken', refreshResponse.data.refreshToken)
    const accessToken = localStorage.getItem('accessToken')
    formData.append('id_document_front', payloadObj.idDocument_front[0])
    formData.append('id_document_back', payloadObj.idDocument_back[0])
    //console.log('post BusinessDirector Form' + JSON.stringify(payloadObj))
    const apiObj = {
      method: 'POST',
      body: formData,
      headers: {
        'X-Propell-Name': 'Propell',
        'X-Propell-Platform': 'Web',
        'X-Propell-Version': 'appVersion',
        'X-Propell-Build': 'buildVersion',
        authorization: `Bearer ${accessToken}`,
        'Tenant-Id': `${tenentId}`,
      },
    }
    res = await fetch(BASE_URL + '/v1/origination_forms/id-document-upload', apiObj)
    if (res?.status === 401) {
      const result = await refreshToken()
      if (result) {
        console.log('refresh and try again')
        const updateAccessToken = localStorage.getItem('accessToken')
        apiObj.headers = {
          'X-Propell-Name': 'Propell',
          'X-Propell-Platform': 'Web',
          'X-Propell-Version': 'appVersion',
          'X-Propell-Build': 'buildVersion',
          authorization: `Bearer ${updateAccessToken}`,
          'Tenant-Id': `${tenentId}`,
        }
        res = await fetch(BASE_URL + '/v1/origination_forms/id-document-upload', apiObj)
      }
    }
    //const res = await api.post(`/v1/origination_forms/id-document-upload`, payloadObj)
    //console.log('post BR complete result :' + JSON.stringify(res))
    // const state = getState()
    // dispatch(
    //   set({
    //     ...state.originations.business,
    //     director: { ...payloadObj },
    //   })
    // )
    return { status: res.status }
  } catch (error) {
    console.error('postBusinessDirector id upload Error#' + JSON.stringify(error?.response))
    return { status: 'ID upload Errors', error: 'File upload error. File must be less than 10MB.' }
  }
}

export const postOriginationConfirmProceed = payloadObj => async (dispatch, getState) => {
  try {
    const res = await api.post(`/v1/origination_forms/confirm-proceed`, payloadObj)
    const state = getState()
    dispatch(
      set({
        ...state.originations.business,
        director: { ...payloadObj },
      })
    )
    //console.log('postOriginationConfirmProceed: ' + JSON.stringify(res))
    return { status: res.status, data: res.data }
  } catch (error) {
    console.error('postBusinessDirector Error#' + JSON.stringify(error.response))
    return { status: error.response.status, error: error.response.data.error }
  }
}
