import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Flex } from '@chakra-ui/react'
import cls from './index.module.scss'

// const thumbsContainer = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   marginTop: 16,
// }

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  // width: 100,
  // height: 100,
  // padding: 4,
  boxSizing: 'border-box',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

function ImageUploader(props) {
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ))

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  return (
    <>
      <section className={cls.container}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          {fileRejections.length > 0 ? 'Please select one image file' : null}
        </div>
        {/* <aside style={thumbsContainer}>
        {thumbs}{' '}
        {files.length > 0 ? (
          <div
            onClick={() => {
              setFiles([])
            }}
          >
            x
          </div>
        ) : null}
      </aside> */}
      </section>
      <Flex maxWidth="400px">
        {thumbs}{' '}
        {files.length > 0 ? (
          <div
            onClick={() => {
              setFiles([])
            }}
          >
            x
          </div>
        ) : null}
      </Flex>
    </>
  )
}

export default ImageUploader
