import api from '@api'
import { addAlert } from '@features/alerts'

const altPaymentFinalizeStripeOrder = ({
  finalizationId,
  orderId,
  locationId: locationIdOverride,
  tenderTypesAbilityId,
  sendReceipt,
  recipientPhone,
  recipientEmail,
}) => async (dispatch, getState) => {
  const {
    business: { locationId: locationIdStore },
  } = getState()

  const locationId = locationIdOverride || locationIdStore

  await api
    .post(`/v1/locations/${locationId}/payments`, {
      locationId,
      tenderTypesAbilityId,
      orderId,
      finalizationId: finalizationId,
      sendReceipt,
      recipientEmail,
      recipientPhone,
    })
    .then(response => {
      if (response.data.paymentStatus === 'PAID') {
        return dispatch(
          addAlert({
            message: `Payment succeeded`,
            success: true,
            dismiss: true,
            top: true,
          })
        )
      } else {
        return dispatch(
          addAlert({
            message: response.data.error ?? `Payment declined`,
            error: true,
            dismiss: true,
            top: true,
          })
        )
      }
    })
    .catch(err => {
      const errMsg = err?.response?.data?.error
      if (errMsg) return dispatch(addAlert({ message: errMsg, error: true, dismiss: true, top: true }))
      else return dispatch(addAlert({ message: `Payment failed.`, error: true, dismiss: true, top: true }))
    })
}
export default altPaymentFinalizeStripeOrder
