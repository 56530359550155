import React, { useState, useEffect } from 'react';
import { usePropellLendingDataCtx } from '@components';
import { useSelector } from '@redux';

export const PropellLendingUiStateCtx = React.createContext(null);
export const usePropellLendingUiStateCtx = () => {
  const {
    amount,
    setAmount,
    amountErr,
    setAmountErr,
    timeFrameTypes,
    timeframe,
    setTimeframe,
    consolidateMode,
    setConsolidateMode,
    loansCanBeConsolidate,
    setLoansCanBeConsolidate,
    max,
    setMax,
    min,
    setMin,
    internalRefinancesTotalPayoffValue,
    setInternalRefinancesTotalPayoffValue,
    consolidationTotalPayoffValue,
    setConsolidationTotalPayoffValue,
    externalLoanVal,
    setExternalLoanVal,
    selLoansIds,
    setSelLoansIds,
    uploadedDoc,
    setUploadedDoc,
    files,
    setFiles,
  } = React.useContext(PropellLendingUiStateCtx);
  return {
    amount,
    setAmount,
    amountErr,
    setAmountErr,
    timeFrameTypes,
    timeframe,
    setTimeframe,
    consolidateMode,
    setConsolidateMode,
    loansCanBeConsolidate,
    setLoansCanBeConsolidate,
    max,
    setMax,
    min,
    setMin,
    internalRefinancesTotalPayoffValue,
    setInternalRefinancesTotalPayoffValue,
    consolidationTotalPayoffValue,
    setConsolidationTotalPayoffValue,
    externalLoanVal,
    setExternalLoanVal,
    selLoansIds,
    setSelLoansIds,
    uploadedDoc,
    setUploadedDoc,
    files,
    setFiles,
  };
};

const PropellLendingUiStateProvider = ({ children }) => {
  const { loansDataRdy, loans } = usePropellLendingDataCtx();
  const account = useSelector(({ business }) => business?.sfAccount);
  const [amount, setAmount] = useState(null);
  const [amountErr, setAmountErr] = useState();
  const timeFrameTypes = [
    { name: '12 months' },
    { name: '12 months or less' },
    { name: '6 months' },
  ];
  const [timeframe, setTimeframe] = useState('12 months or less');
  const [consolidateMode, setConsolidateMode] = useState();
  const [loansCanBeConsolidate, setLoansCanBeConsolidate] = useState([]);
  const [max, setMax] = useState(account?.overallLoanAvailable6month);
  const [min, setMin] = useState(5000);
  const [
    internalRefinancesTotalPayoffValue,
    setInternalRefinancesTotalPayoffValue,
  ] = useState(0);
  const [
    consolidationTotalPayoffValue,
    setConsolidationTotalPayoffValue,
  ] = useState(0);
  const [selLoansIds, setSelLoansIds] = useState([]);
  const [externalLoanVal, setExternalLoanVal] = useState(0);
  const [uploadedDoc, setUploadedDoc] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (loansDataRdy) {
      const mLoans = loans.filter(
        (l) =>
          l?.consolidationState !== 'pending' &&
          l?.sfLendingContract?.status === 'Active' &&
          //l?.sfLendingContract?.sfContractRepayments?.length === 6
          l?.sfLendingContract?.name.slice(0, 2) === 'LC'
      );
      setLoansCanBeConsolidate(
        mLoans.map((l) => {
          return { ...l, selected: false };
        })
      );
    }
  }, [loans, loansDataRdy]);

  useEffect(() => {
    if (account?.overallLoanAvailable12month)
      setMax(account?.overallLoanAvailable12month);
  }, [account]);

  useEffect(() => {
    const selectedLoans = loansCanBeConsolidate.filter((l) => l.selected);
    const loansIds = selectedLoans.map((l) => l?.sfLendingContract?.id);
    const selectedPayoffAmounts = selectedLoans.map(
      (l) => l?.sfLendingContract?.payoffAmountToday
    );
    const totalPayOffAmounts = selectedPayoffAmounts.reduce(
      (c, p) => parseFloat(c) + parseFloat(p),
      0
    );
    setInternalRefinancesTotalPayoffValue(totalPayOffAmounts);
    setConsolidationTotalPayoffValue(externalLoanVal);
    setSelLoansIds(loansIds);
  }, [loansCanBeConsolidate, externalLoanVal]);

  return (
    <PropellLendingUiStateCtx.Provider
      value={{
        amount,
        setAmount,
        amountErr,
        setAmountErr,
        timeFrameTypes,
        timeframe,
        setTimeframe,
        consolidateMode,
        setConsolidateMode,
        loansCanBeConsolidate,
        setLoansCanBeConsolidate,
        max,
        setMax,
        min,
        setMin,
        internalRefinancesTotalPayoffValue,
        setInternalRefinancesTotalPayoffValue,
        consolidationTotalPayoffValue,
        setConsolidationTotalPayoffValue,
        externalLoanVal,
        setExternalLoanVal,
        selLoansIds,
        setSelLoansIds,
        uploadedDoc,
        setUploadedDoc,
        files,
        setFiles,
      }}
    >
      {children}
    </PropellLendingUiStateCtx.Provider>
  );
};
export default PropellLendingUiStateProvider;
