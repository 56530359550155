import api from '@api'
import { setInsightsBankData } from './index.js'

export const getInsightsBankData = bid => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/businesses/${bid}/bank_feeds/bank_connections/daily_balances`)
    await dispatch(setInsightsBankData(res.data))
    //return res.data
  } catch (error) {
    console.error(error)
  }
}

export default getInsightsBankData
