import React from 'react'
import classnames from 'classnames'

import UikButton from '../UikButton'

import cls from './menuDrop.module.scss'

const DefaultDisplayComponent = ({ className, ...rest }: DefaultDisplayComponentProps) => (
  <UikButton
    className={classnames(cls.defaultDisplayComponent, className)}
    contentClassName={cls.btnContent}
    {...rest}
  >
    &middot;&middot;&middot;
  </UikButton>
)

DefaultDisplayComponent.defaultProps = {
  className: null,
}

export default DefaultDisplayComponent
