import React from 'react'
import cls from './index.module.scss'
//import { sleep } from '@lib'
const rotationDegreeAdj = -30
const rotationDegreeAdjRatio = rotationDegreeAdj / 360
const tooltipsSz = { width: '15', height: '4' }
const arcGap = 0.1
// const Circle = ({ radius, strokeWidth, rotation, percent, color }) => {
//   const outerRadius = radius
//   const innerRadius = outerRadius - 2
//   const fullScale = 2 * Math.PI * innerRadius
//   const dash = (fullScale * percent) / 100
//   return (
//     <g transform={`translate(${outerRadius},${outerRadius})`}>
//       <g transform={`rotate(${rotation + 270 + rotationDegreeAdj}) `}>
//         <g transform={`translate(${-1 * outerRadius},${-1 * outerRadius})`}>
//           <circle
//             cx={outerRadius}
//             cy={outerRadius}
//             r={innerRadius}
//             fill="none"
//             stroke={color}
//             strokeWidth={`${strokeWidth}`}
//             strokeDasharray={`${dash} ${fullScale}`}
//           />
//         </g>
//       </g>
//     </g>
//   )
// }

const CircularArc = ({ instanceId, idx, radius, strokeWidth, rotation, accuRotation, color }) => {
  const outerRadius = radius - 2
  const mCenter = { x: outerRadius + 2, y: outerRadius + 2 }
  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }
  const start = polarToCartesian(mCenter.x, mCenter.y, outerRadius, accuRotation + rotationDegreeAdj)
  const end = polarToCartesian(mCenter.x, mCenter.y, outerRadius, accuRotation + rotation + rotationDegreeAdj)
  const largeArcFlag = rotation <= 180 ? '0 1' : '1 1'
  const d = `M ${start.x} ${start.y} A ${outerRadius} ${outerRadius} ${accuRotation +
    rotationDegreeAdj} ${largeArcFlag} ${end.x} ${end.y}`

  return (
    <path
      id={`${instanceId}circleIdx${idx}`}
      key={`${instanceId}circleIdx${idx}`}
      d={d}
      fill="none"
      stroke={color}
      strokeWidth={`${strokeWidth}`}
    />
  )
}

const styleSmallText = {
  fontFamily: 'Roboto',
  fontSize: '2.2',
  fontWeight: '400',
}

// const StyleText1 = {
//   fontFamily: 'Roboto',
//   fontSize: '5', //'5.725',
//   fontWeight: '500',
// }
// const StyleText2 = {
//   fontFamily: 'Roboto',
//   fontSize: '4',
//   fontWeight: '400',
// }
// const StyleText3 = {
//   fontFamily: 'Roboto',
//   fontSize: '3',
//   fontWeight: '400',
// }

export const SvgPieChart = ({ instanceId, items, total, expenseHealth }) => {
  const boxSz = { w: 87, h: 50 }
  const pieChartRadius = 16
  const strokeWidth = 3
  const xOffset = (boxSz.w - 2 * pieChartRadius) / 2
  const yOffset = (boxSz.h - 2 * pieChartRadius) / 2
  //const centerDisplay = true
  const pieChartAdapter = items => {
    var accumulateValues = 0

    return items.map((item, idx) => {
      const currentAccumulateValues = accumulateValues
      const offset = 5
      accumulateValues += item.value
      const midPointPercentBefore = item.value / 2 + currentAccumulateValues
      const midPointPercent = midPointPercentBefore + rotationDegreeAdjRatio * 100
      const arcMidRadianBeforeRotate = ((item.value / 2 + currentAccumulateValues) / 100) * 2 * Math.PI
      const arcMidRadian = arcMidRadianBeforeRotate + 2 * Math.PI * rotationDegreeAdjRatio
      const arcMid0X = pieChartRadius + Math.sin(arcMidRadian) * pieChartRadius
      const side = midPointPercent <= 50 ? 'right' : 'left'
      const topBottom = midPointPercent <= 25 || midPointPercent >= 75 ? 'top' : 'bottom'
      const arcMid1X = side === 'right' ? arcMid0X + offset : arcMid0X - offset
      const arcMid2X = side === 'right' ? arcMid0X + offset + 4 : arcMid0X - (offset + 1.5)
      const arcMid3X = side === 'right' ? arcMid0X + offset + 4.5 : arcMid0X - (offset + 2.3)
      const arcMid4X = side === 'right' ? arcMid0X + offset + 5.8 : arcMid0X - (offset + 4)
      const arcMid0Y = pieChartRadius - Math.cos(arcMidRadian) * pieChartRadius
      const arcMid1Y = topBottom === 'top' ? arcMid0Y - 2 : arcMid0Y + 3
      const arcMid2Y = topBottom === 'top' ? arcMid0Y - 2 : arcMid0Y + 3
      const arcMid3Y = topBottom === 'top' ? arcMid0Y - 2 : arcMid0Y + 3
      const arcMid4Y = topBottom === 'top' ? arcMid0Y - 1.5 : arcMid0Y + 3.6
      //console.log(`midPointPercent (${idx}) ${midPointPercentBefore} :${midPointPercent}`)
      return {
        value: item.value,
        rawValue: item.rawValue,
        label: { x: arcMid4X, y: arcMid4Y, side, text: item.label },
        accuPos: currentAccumulateValues,
        color: item.color,
        dash: `${item.value} 100`,
        side,
        markerStart: { x: arcMid0X, y: arcMid0Y },
        markerMid: { x: arcMid1X, y: arcMid1Y },
        markerEnd: { x: arcMid2X, y: arcMid2Y },
        markerCicle: { x: arcMid3X, y: arcMid3Y },
      }
    })
  }
  const PieChartData = pieChartAdapter(items)
  const PieChartDataLeft = [...PieChartData].reduce((o, a) => {
    const side = `${a.side}`
    const cnt = side === 'left' ? 1 : 0
    return o + cnt
  }, 0)
  const PieChartDataRight = [...PieChartData].reduce((o, a) => {
    const side = `${a.side}`
    const cnt = side === 'right' ? 1 : 0
    return o + cnt
  }, 0)
  //console.log('PieChartData[left:right]= ' + PieChartDataLeft + ':' + PieChartDataRight)
  var leftIdx = 0
  var rightIdx = 0
  const leftOffset = PieChartDataLeft > 2 ? boxSz.h / (PieChartDataLeft + 1) : boxSz.h * 0.4
  const rightOffset = PieChartDataRight > 2 ? boxSz.h / (PieChartDataRight + 1) : boxSz.h * 0.35
  const PieChartDataFixPos = PieChartData.map((item, idx) => {
    const extraOffset = 6
    var newYPos = 0
    const leftSide = '' + item.side === 'left'
    if (leftSide) {
      //newYPos = boxSz.h - (leftOffset * leftIdx + leftOffset)
      newYPos = boxSz.h - (leftOffset * leftIdx + leftOffset)
      // console.log('leftIdx ' + leftIdx + 'Yoffset' + newYPos + `Label : ${item.label.text}`)
      leftIdx++
    } else {
      newYPos =
        PieChartDataRight === 2
          ? rightIdx === 0
            ? boxSz.h * 0.13
            : boxSz.h * 0.75
          : rightOffset * rightIdx + rightOffset
      // console.log(
      //   'rightIdx ' + rightIdx + 'rightOffset' + rightOffset * (rightIdx + 1) + `Label : ${item.label.text}`
      // )
      rightIdx++
    }
    const label = { ...item.label, text1: '', text2: '', text3: '' }
    const markerCicle = { ...item.markerCicle }
    const markerMid = { ...item.markerMid }
    const markerEnd = { ...item.markerEnd }
    label.y = newYPos - extraOffset + 0.75
    if (label.text.length > 12) {
      const texts = label.text.split(' ') || []
      //console.log(`${idx} : ${label.text} : ${texts.length}`)
      if (texts.length === 1) {
        label.text = null
        label.text1 = texts[0]
        label.text2 = Number(item.value).toFixed(2) + '%'
      } else if (texts.length > 1) {
        if (texts.length > 2) {
          label.text1 = texts[0] + ' ' + texts[1]
          label.text2 = String(texts[2]) + String(texts.length > 3 ? '...' : '')
        } else {
          label.text1 = texts[0]
          label.text2 = texts[1]
        }
        label.text2 += ' ' + Number(item.value).toFixed(2) + '%'
        label.text = null
      } else {
        label.text = null
      }

      // console.log(`${idx} : ${JSON.stringify(texts)}`)
      // console.log(`${idx} Label  : ${JSON.stringify(label)}`)
    }

    markerCicle.y = newYPos - extraOffset
    markerMid.y = newYPos - extraOffset
    markerEnd.y = newYPos - extraOffset
    return { ...item, label, markerCicle, markerMid, markerEnd }
  })
  // const [showItems, setShowItems] = useState([...items.map(i => true)])
  // useEffect(() => {
  //   if (items.length > 0) {
  //     ;(async () => {
  //       var state = [...items.map(i => false)]
  //       setShowItems(state)
  //       var i = 0
  //       while (i <= items.length - 1) {
  //         state[i++] = true
  //         await sleep(150)
  //         setShowItems(state)
  //       }
  //     })()
  //   }
  // }, [items])
  return (
    // <div ref={ref} style={{ width: '100%', height: '100%', position: 'relative' }}>
    <svg width="100%" height="100%" viewBox={`0 0 ${boxSz.w} ${boxSz.h}`} className={cls.svgContainer}>
      {/* <rect width={boxSz.w} height={boxSz.h} fill="#f0f0f0" /> */}
      <defs>
        <filter id="shadow1" colorInterpolationFilters="sRGB" x="-0.05" y="0.05" width="100%" height="100%">
          <feDropShadow dx="0.01" dy="0.01" stdDeviation="3" floodOpacity="0.2" />
        </filter>
        <filter id="shadow2" colorInterpolationFilters="sRGB">
          {/* <feDropShadow dx="1" dy="1" stdDeviation="3" floodColor="#bdbdbd" floodOpacity="0.4" /> */}
          <feDropShadow dx="0.05" dy="0.05" stdDeviation="1" floodColor="#bdbdbd" floodOpacity="0.05" />
        </filter>
      </defs>
      {/* <text x={boxSz.w / 2} y={boxSz.h / 2 + 0.25} fill="#2E3238" style={StyleText1} textAnchor={'middle'}>
        {centerDisplay ? expenseHealth : total}
      </text>
      {centerDisplay ? (
        <text x={boxSz.w / 2} y={boxSz.h / 2 + 4.5} fill="#9AA1A9" style={StyleText3} textAnchor={'middle'}>
          Expense Health
        </text>
      ) : (
        <text x={boxSz.w / 2} y={boxSz.h / 2 + 5.25} fill="#9AA1A9" style={StyleText2} textAnchor={'middle'}>
          Total
        </text>
      )} */}

      <g transform={`translate(${xOffset},${yOffset})`}>
        {PieChartDataFixPos.map(
          ({ value, label, accuPos, color, markerStart, markerMid, markerEnd, markerCicle }, idx) => (
            <g
              id={`Circle-idx${idx}`}
              key={`Circle-idx${idx}`}
              style={{ cursor: 'pointer' }}
              //visibility={showItems[idx] ? 'visiable' : 'hidden'}
            >
              <CircularArc
                instanceId={instanceId}
                idx={idx}
                radius={pieChartRadius}
                strokeWidth={strokeWidth}
                accuRotation={(360 * accuPos) / 100}
                rotation={(360 * value - arcGap) / 100}
                color={color}
              />
              <polyline
                points={`${markerStart.x},${markerStart.y} ${markerMid.x},${markerMid.y} ${markerEnd.x},${markerEnd.y}`}
                stroke={color}
                strokeWidth="0.3"
                strokeDasharray="0.5 0.5"
                fill="none"
              />
              <circle
                cx={markerCicle.x}
                cy={markerCicle.y}
                r={0.7125}
                fill="none"
                stroke={color}
                strokeWidth="0.3"
              />
              {label.text1 ? (
                <g>
                  <text
                    x={label.x}
                    y={label.y - 1.25}
                    fill="black"
                    style={styleSmallText}
                    textAnchor={label.side === 'left' ? 'end' : 'start'}
                  >
                    {label.text1}
                  </text>
                  <text
                    x={label.x}
                    y={label.y + 1.25}
                    fill="black"
                    style={styleSmallText}
                    textAnchor={label.side === 'left' ? 'end' : 'start'}
                  >
                    {label.text2}
                  </text>
                </g>
              ) : null}
              {label.text ? (
                <text
                  x={label.x}
                  y={label.y}
                  fill="black"
                  style={styleSmallText}
                  textAnchor={label.side === 'left' ? 'end' : 'start'}
                >
                  {label.text}&nbsp;{Number(value).toFixed(2)}%
                </text>
              ) : null}

              {PieChartDataFixPos.map(({ rawValue, label, markerCicle, color }, idx) => (
                <g key={`tooltips#${idx}`} visibility={'hidden'}>
                  <set
                    attributeName="visibility"
                    to="visible"
                    begin={`${instanceId}circleIdx${idx}.mouseenter`}
                    end={`${instanceId}circleIdx${idx}.mouseleave`}
                  />
                  <rect
                    x={label.side === 'right' ? markerCicle.x : markerCicle.x - tooltipsSz.width}
                    y={markerCicle.y - 8}
                    //width={tooltipsSz.width}
                    width={`$${Number(rawValue).toFixed(2)}`.length * 1.6}
                    height={tooltipsSz.height}
                    stroke="none"
                    //fill="white"
                    fill={color}
                    strokeWidth="5"
                    //filter="url(#shadow2)"
                  ></rect>
                  <text
                    x={label.side === 'right' ? markerCicle.x + 1.6 : markerCicle.x - tooltipsSz.width + 1.6}
                    y={markerCicle.y - 5.25}
                    //fill={'black'}
                    fill={'white'}
                    style={styleSmallText}
                  >
                    {`$${Number(rawValue).toFixed(2)}`}
                  </text>
                </g>
              ))}
            </g>
          )
        )}
      </g>
    </svg>
    // </div>
  )
}

export default SvgPieChart
