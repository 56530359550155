import api from '@api'
import { addAlert } from '@features/alerts'

const result = values => async (dispatch, getState) => {
  const res = await api
    .post(`/v1/lendings/prehosted-token`, values)
    .then(result => {
      return result
    })
    .catch(err => {
      console.error(err.response.data.error)
      dispatch(
        addAlert({
          message: err.response.data.error,
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      return err.response
    })
  return res.data
}
export default result
