import { createSlice } from '@redux'

export const initialState = { summary: {}, orders: [] }

const slice = createSlice({
  name: 'propellTradePayment',
  initialState,
  reducers: {
    setOrders: (state, { payload }) => {
      return {
        ...state,
        orders: payload,
      }
    },
    setPropellTrade: (state, { payload }) => {
      return {
        ...state,
        propellTradePayments: payload,
      }
    },
    clear: () => {
      return initialState
    },
  },
})

export const { setOrders, clear, setPropellTrade } = slice.actions

export default slice.reducer

export { getOrdersData, rejectOrdersCase } from './orders'
