import { createSlice } from '@redux'
import { getWeek, getQuarter, startOfQuarter } from 'date-fns'
export const initialState = null

const MTH_NAME = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

const paginateDaysData = (periodUnit, data) => {
  switch (periodUnit) {
    case 'Week':
      const wks = []
      const wk = { name: null, days: [] }
      data.forEach((d, index) => {
        const date = new Date(d.date)
        const wkNum = getWeek(date, { weekStartsOn: 1 })
        wk.name = `Week #${wkNum} ${date.getFullYear()}`
        wk.days.push(d)
        if (date.getDay() === 1 || index === data.length - 1) {
          const mWk = Object.assign({}, wk)
          mWk.days.reverse()
          wks.push(mWk)
          wk.days = []
        }
      })
      return wks
    case 'Month':
      const mths = []
      const mth = { name: null, days: [] }
      data.forEach((d, index) => {
        const date = new Date(d.date)
        const todayMonth = date.getMonth()
        mth.name = `${MTH_NAME[todayMonth]} ${date.getFullYear()}`
        mth.days.push(d)
        if (date.getDate() === 1 || index === data.length - 1) {
          const mMth = Object.assign({}, mth)
          mths.push(mMth)
          mth.days = []
        }
      })
      return mths
    case 'Quarter':
      const qtrs = []
      const qtr = { name: null, days: [] }
      data.forEach((d, index) => {
        const date = new Date(d.date)
        const todayQuarter = getQuarter(date)
        const mStartOfQuarter = startOfQuarter(date)
        qtr.name = `Q${todayQuarter} ${date.getFullYear()}`
        qtr.days.push(d)
        if (
          (date.getDate() === mStartOfQuarter.getDate() && date.getMonth() === mStartOfQuarter.getMonth()) ||
          index === data.length - 1
        ) {
          const mQtr = Object.assign({}, qtr)
          qtrs.push(mQtr)
          qtr.days = []
        }
      })
      //console.log(' qtrs :' + JSON.stringify(qtrs))
      return qtrs
    case 'All':
      return [{ name: 'all', days: data }]
    default:
      return [{ name: 'all', days: data }]
  }
}

const slice = createSlice({
  name: 'insightsUi',
  initialState,
  reducers: {
    setInsightsUiData: (state, { payload }) => {
      return {
        all: { name: 'All', days: paginateDaysData('All', payload) },
        weekly: { name: 'Week', days: paginateDaysData('Week', payload) },
        monthly: { name: 'Month', days: paginateDaysData('Month', payload) },
        quarterly: { name: 'Quarter', days: paginateDaysData('Quarter', payload) },
      }
    },
    updateInsightsUiData: (state, { payload }) => {
      //console.log('state :' + state.all.days)
      // const alldaysArrayRef = state.all.days[0].days
      // const oldObj = alldaysArrayRef.find(d => d.id === 12)
      // console.log(' oldObj ' + JSON.stringify(oldObj))
      // oldObj.selected = false
      // console.log(' o updated' + JSON.stringify(oldObj))

      // const mObj = [...state].find(e => e.id === 6)
      // console.log('mObj : ' + JSON.stringify(mObj))
      // mObj.newField = 'dummy'
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { clear, setInsightsUiData, updateInsightsUiData } = slice.actions
export default slice.reducer
