import { createSlice } from '@redux'
import api from '@api'

export const initialState = []

const slice = createSlice({
  name: 'bankFeeds',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        return payload
        // return { ...state, ...payload }
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions
export default slice.reducer

export const getBankConnections = bid => async (dispatch, getState) => {
  try {
    const res = await api.get(
      `/v1/businesses/${bid}/bank_feeds/bank_connections?business_id=${bid}?page=1&per_page=40`
    )
    const state = getState()
    dispatch(set({ ...state.bankFeeds, connections: res.data }))
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getBankConnectionsById = (bid, connectionId) => async (dispatch, getState) => {
  try {
    const res = await api.get(
      `/v1/businesses/${bid}/bank_feeds/bank_connections/${connectionId}?business_id=${bid}?page=1&per_page=20`
    )
    const state = getState()
    dispatch(set({ ...state.bankFeeds, connections: res.data }))
    return res.data
  } catch (error) {
    console.error(error)
  }
}

export const getBankInitialiseLink = (bid, bank_connection_id, path) => async (dispatch, getState) => {
  try {
    const obj = { redirect_path: path, business_id: bid, bank_connection_id: bank_connection_id }
    const res = await api.post(`/v1/businesses/${bid}/bank_feeds/initialise`, obj)
    const state = getState()
    dispatch(set({ ...state.bankFeeds, ...res.data }))
    //dispatch(set(res.data))
    //console.log('getBankInitialiseLink Root' + JSON.stringify(res.data))
    return res.data
  } catch (error) {
    console.error('getBankInitialiseLink Error#' + JSON.stringify(error))
    return null
  }
}

export const setBankConnectionsPrimaryAccount = (bid, connectionId, accountId) => async (
  dispatch,
  getState
) => {
  try {
    const obj = { bankAccountId: accountId, bankConnectionId: connectionId, businessId: bid }
    const res = await api.put(
      `/v1/businesses/${bid}/bank_feeds/bank_connections/${connectionId}/primary_account`,
      obj
    )
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}
