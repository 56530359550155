import * as React from 'react'
import classnames from 'classnames'

import cls from './tab.module.scss'

const UikTabContainer = ({ className, Component, children, ...rest }: UikTabContainerProps) => (
  <Component className={classnames(cls.container, className)} {...rest}>
    {children}
  </Component>
)

UikTabContainer.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default UikTabContainer
