import React, { useState } from 'react'
import cls from './index.module.scss'

export const PropellModalCtx = React.createContext(null)
export const usePropellModalCtx = () => {
  const [propellModalOpen, setPropellModalOpen, setPropellModalComponent] = React.useContext(PropellModalCtx)
  const mSetPropellModalOpen = b => {
    setPropellModalOpen(b)
  }
  const mSetPropellModalComponent = c => {
    setPropellModalComponent(c)
  }
  return {
    isOpen: propellModalOpen,
    setOpen: mSetPropellModalOpen,
    setComponent: mSetPropellModalComponent,
  }
}

const PropellModalProvider = ({ children }) => {
  const [propellModalOpen, setPropellModalOpen] = useState(false)
  const [propellModalComponent, setPropellModalComponent] = useState(<></>)
  return (
    <PropellModalCtx.Provider value={[propellModalOpen, setPropellModalOpen, setPropellModalComponent]}>
      {children}
      <div className={cls.modalWrapper} style={{ display: propellModalOpen ? 'flex' : 'none' }}>
        {propellModalComponent}
      </div>
    </PropellModalCtx.Provider>
  )
}
export default PropellModalProvider
