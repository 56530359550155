import React from 'react'
import cls from './index.module.scss'

export const SvgArcGaugeRestyle = ({ score, percent }) => {
  const strokeWidth = 8
  return (
    <svg viewBox="0 0 145 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_99_34884)">
        <path
          d="M24.9595 81.9285C21.2068 73.8594 19.5847 64.9631 20.248 56.0888C20.9113 47.2145 23.8378 38.6582 28.7482 31.2365C33.6586 23.8149 40.3892 17.7755 48.2975 13.6948C56.2058 9.61402 65.0279 7.62809 73.922 7.9265C82.8161 8.22491 91.4852 10.7977 99.1022 15.3993C106.719 20.001 113.03 26.4779 117.432 34.212C121.834 41.9461 124.18 50.6793 124.247 59.5781C124.314 68.477 122.099 77.2445 117.814 85.0439"
          stroke="url(#paint0_linear_99_34884)"
          strokeWidth="10.4145"
          strokeLinecap="round"
        />
      </g>
      <circle r={strokeWidth} fill="white">
        <animateMotion
          dur={`3s`}
          repeatCount={`${percent}`}
          path={`M24.9595 81.9285C21.2068 73.8594 19.5847 64.9631 20.248 56.0888C20.9113 47.2145 23.8378 38.6582 28.7482 31.2365C33.6586 23.8149 40.3892 17.7755 48.2975 13.6948C56.2058 9.61402 65.0279 7.62809 73.922 7.9265C82.8161 8.22491 91.4852 10.7977 99.1022 15.3993C106.719 20.001 113.03 26.4779 117.432 34.212C121.834 41.9461 124.18 50.6793 124.247 59.5781C124.314 68.477 122.099 77.2445 117.814 85.0439`}
          fill="freeze"
        ></animateMotion>
      </circle>
      <text className={cls.score} x={145 / 2} y={118 * 0.6} fill="black" textAnchor="middle">
        {score}
      </text>
      <defs>
        <filter
          id="filter0_d_99_34884"
          x="0.315645"
          y="0.607036"
          width="143.72"
          height="116.723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="12.4974" />
          <feGaussianBlur stdDeviation="7.29018" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.203125 0 0 0 0 0.585 0 0 0 0 0.9375 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_99_34884" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_99_34884" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_99_34884"
          x1="138.131"
          y1="61.8394"
          x2="-48.5285"
          y2="171.422"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0E9" />
          <stop offset="1" stopColor="#F33957" stopOpacity="0.22" />
        </linearGradient>
        <filter id="shadow" colorInterpolationFilters="sRGB" x="-0.1" y="0.0" width="110%" height="100%">
          <feDropShadow dx="0.1" dy="0" stdDeviation="1" floodOpacity="0.2" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgArcGaugeRestyle
