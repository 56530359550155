import React from 'react'
import cls from './index.module.scss'
import { IconsPropell } from '@components'

const Component = ({ status, icon, header, text, closable, onClose }) => {
  const MIcon = icon ? icon : status === 'alert' ? <IconsPropell name="AlertIcon" /> : null
  const componentClosable = closable !== undefined ? closable : true

  return (
    <div className={status === 'success' ? cls.messageBoxSuccess : cls.messageBox}>
      <div className={cls.messageBoxLeft}>{MIcon}</div>
      <div className={cls.messageBoxRight}>
        <div className={cls.messageBoxHeader}>{header}</div>
        <div className={cls.messageBoxText}>{text}</div>
      </div>
      {componentClosable ? (
        <div
          className={cls.closeButton}
          onClick={() => {
            if (onClose) {
              onClose()
            }
          }}
        >
          <IconsPropell name="CancelIcon" />
        </div>
      ) : null}
    </div>
  )
}

export default Component
