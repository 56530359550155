import React from 'react'
import cls from './index.module.scss'

const styleSmallText = {
  fontFamily: 'Roboto',
  fontSize: '2.2',
  fontWeight: '400',
}

const SvgPieChartListView = ({ instanceId, items, total }) => {
  const yOffset = 5
  const yspace = 5 //5
  const boxSz = { w: 87, h: items?.length ? items.length * yspace + yOffset : 50 }

  return (
    <div className={cls.svgContainer}>
      <svg viewBox={`0 0 ${boxSz.w} ${boxSz.h}`}>
        <defs>
          <filter id="shadow1" colorInterpolationFilters="sRGB" x="-0.05" y="0.05" width="100%" height="100%">
            <feDropShadow dx="0.01" dy="0.01" stdDeviation="3" floodOpacity="0.2" />
          </filter>
          <filter id="shadow2" colorInterpolationFilters="sRGB">
            <feDropShadow dx="0.05" dy="0.05" stdDeviation="1" floodColor="#bdbdbd" floodOpacity="0.05" />
          </filter>
        </defs>

        {items.map((item, idx) => {
          const yPos = yspace * idx + yOffset
          const labelYOffset = 0.5
          return (
            <g id={`Circle-idx${idx}`} key={`Circle-idx${idx}`}>
              <circle cx="10" cy={`${yPos}`} r={0.7125} fill="none" stroke={item.color} strokeWidth="0.3" />
              <text
                x={'15'}
                y={`${yPos + labelYOffset}`}
                fill="black"
                style={styleSmallText}
                textAnchor={'start'}
              >
                {Number(item.value).toFixed(2)}% &nbsp;
              </text>
              <text
                x={'37'}
                y={`${yPos + labelYOffset}`}
                fill="black"
                style={styleSmallText}
                textAnchor={'end'}
              >
                ${Number(item.rawValue).toFixed(0)}&nbsp;
              </text>
              <text
                x={'40'}
                y={`${yPos + labelYOffset}`}
                fill="black"
                style={styleSmallText}
                textAnchor={'start'}
              >
                {item.label}&nbsp;
              </text>
            </g>
          )
        })}
      </svg>
    </div>
  )
}

export default SvgPieChartListView
