import api from '@api'

export const getLoansPrecheckPending = ({ loanType }) => async (dispatch, getState) => {
  var res
  try {
    res = await api.get(`/v1/lendings/loans/pre-check/pending?loan_type=${loanType}`)
    return res.data
  } catch (error) {
    console.error(error?.response?.data)
    return error?.response?.data
  }
}

export default getLoansPrecheckPending
