import api from '@api'
import { addAlert } from '@features/alerts'

export const postEmail = values => async (dispatch, getState) => {
  var rc
  try {
    rc = await api.post(`/v1/utils/send-email`, {
      ...values,
    })
    dispatch(
      addAlert({
        message: `Support message sent`,
        success: true,
        dismiss: true,
        top: true,
        dismissTimeout: 3 * 1000,
      })
    )

    return rc?.data
  } catch (e) {
    console.error(e)
    dispatch(
      addAlert({
        message: e?.message + ' : ' + e.response?.data?.error,
        error: true,
        dismiss: true,
        top: true,
        dismissTimeout: 5 * 1000,
      })
    )
    //console.log('err e :' + JSON.stringify(e))
    //console.log('err rc :' + JSON.stringify(e.response?.data?.error))
    return e.response?.data
  }
}
