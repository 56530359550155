import { createSlice } from '@redux'
import api from '@api'

export const initialState = []

const slice = createSlice({
  name: 'business_fees',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        return payload
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions
export default slice.reducer

export const getBusinessFees = bid => async (dispatch, getState) => {
  try {
    var res
    res = await api.get(`/v1/businesses/${bid}/fees`)
    dispatch(set(res.data))
    return res.data
  } catch (error) {
    console.error(error)
  }
}
