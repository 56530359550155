import React from 'react'

const LineShort = props => (
  <svg fill="currentColor" height="2px" version="1.1" viewBox="0 0 14 2" width="14px" {...props}>
    <g id="Icon/20px/menu-[Extra]" transform="translate(0.000000, -2.000000)">
      <polygon id="Path" points="0 4 14 4 14 2 0 2" />
    </g>
  </svg>
)
export default LineShort
