import { createSlice } from '@redux'

export const initialState = {
  sidebarOpen: false,
  loading: true,
  dockState: 0,
}

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarOpen: (state, { payload }) => {
      state.sidebarOpen = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setDockState: (state, { payload }) => {
      state.dockState = payload
    },
    clear: () => {
      return initialState
    },
  },
})

export const { setSidebarOpen, setLoading, setDockState, clear } = slice.actions

export default slice.reducer
