import api from '@api'
import { setSummary } from './index.js'

const summary = values => async (dispatch, getState) => {
  const {
    business: { locationId },
  } = getState()

  try {
    const res = await api.get(`/v1/locations/${locationId}/payment_overview/summary`, {
      params: {
        start_date: 0,
        end_date: Number((new Date().getTime() / 1000).toFixed(0)), // new Date().getTime(),
        // start_date: getUnixTime(subMonths(new Date(), 1)) + 86400,
        // end_date: getUnixTime(new Date()) + 86400,
      },
    })
    dispatch(setSummary(res.data))
  } catch (error) {
    console.error(error)
  }
}
export default summary
//export default slice.reducer
