import React, { useEffect, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect, useSelector } from '@redux'

import { setAlerts } from '@features/alerts'
import { Alert } from '@components'
import { useRouter } from '@hooks'
import { Loading, ZipLandingPage } from '@components'
import '../theme/common/unscoped/index.scss'
import cls from '../theme/common/index.module.scss'
import { hotjar } from 'react-hotjar'
import TagManager from 'react-gtm-module'
import { useAction } from '@redux'
import { getApiStatus } from '@features/apiStatus'
import Maintenance from '../components/Maintenance'
import ZipLanding from './ZipLanding'
const Dashboard = lazy(() => import('@Apps/Dashboard'))
const ForgotPassword = lazy(() => import('@Apps/ForgotPassword'))
const Login = lazy(() => import('@Apps/Login'))
const Orders = lazy(() => import('@Apps/Orders'))
const Originations = lazy(() => import('@Apps/Originations'))
const ResetPassword = lazy(() => import('@Apps/ResetPassword'))
const ConfirmEmail = lazy(() => import('@Apps/ConfirmEmail'))
const FollowInvitation = lazy(() => import('@Apps/FollowInvitation'))
const Version = lazy(() => import('@Apps/Version'))
const Connect = lazy(() => import('@Apps/BrokerConnect'))

const tagManagerArgs = {
  gtmId: 'GTM-PGHSG9S',
}

const Apps = ({ setAlerts }) => {
  const alerts = useSelector(state => state.alerts)
  const { location, query } = useRouter()
  const fetchApiStatus = useAction(getApiStatus)
  const maintenance = useSelector(state => state.apiStatus?.status)
  let lastPingTime = Math.round(new Date().getTime() / 1000)
  hotjar.initialize(2128343, 6) // propell dev
  // if (env === 'production') {
  //   hotjar.initialize(2128343, 6) // propell dev
  // } else {
  //   console.warn('HotJar disabled in ' + env)
  // }
  TagManager.initialize(tagManagerArgs)

  const checkApiStatus = () => {
    const currTime = Math.round(new Date().getTime() / 1000)
    const timediff = currTime - lastPingTime
    if (timediff >= 120) {
      // last ping longer than 2 mins
      fetchApiStatus()
      lastPingTime = currTime
      //console.log(' ping again ' + timediff)
    }
  }

  useEffect(() => {
    // show widget on mount component
    //window.zE('webWidget', 'show')
    // console.log('show widget')
    fetchApiStatus()
    window.onclick = checkApiStatus
    return () => {
      // hide widget on unmount component
      //window.zE('webWidget', 'hide')
      window.onclick = null
      //console.log('hide widget')
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    setAlerts()
  }, [location.key, setAlerts])

  return maintenance === 'OK' || maintenance === 'ONBOARDING DOWN' ? (
    <Suspense fallback={query?.mobileWebView ? <></> : <Loading />}>
      <div className={cls.app}>
        <ZipLandingPage show={false} />
        {alerts.map(alert => (
          <Alert {...alert} key={alert.id} />
        ))}
        <Switch>
          <Route component={Login} exact path="/login" />
          {!query?.mobileWebView ? <Route component={ForgotPassword} exact path="/forgot-password" /> : null}
          {!query?.mobileWebView ? (
            <Route component={ResetPassword} exact path="/users/reset-password" />
          ) : null}
          {!query?.mobileWebView ? <Route component={ConfirmEmail} exact path="/users/confirm" /> : null}
          {!query?.mobileWebView ? <Route component={FollowInvitation} exact path="/follow-invite" /> : null}
          {!query?.mobileWebView ? <Route component={Originations} path="/apply" /> : null}
          {!query?.mobileWebView ? <Route component={Orders} path="/locations/:lid/orders/:oid/pay" /> : null}
          {!query?.mobileWebView ? <Route component={Version} path="/version" /> : null}
          {!query?.mobileWebView ? <Route component={ZipLanding} path="/zipLandingPage" /> : null}
          {!query?.mobileWebView ? <Route component={Connect} path="/connect" /> : null}
          <Route component={Dashboard} path="/" />
        </Switch>
      </div>
    </Suspense>
  ) : (
    <Maintenance />
  )
}

export default connect(null, { setAlerts })(Apps)
