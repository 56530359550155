import { addAlert } from '@features/alerts'
import { ext } from '@api'
//import { uuidv4 } from '@lib'

// export const getPaymentToken = (minorUnits, currencyCode, locationIdOverride) => async (
//   dispatch,
//   getState
// ) => {
//   const {
//     business: { locationId: locationIdStore },
//   } = getState()

//   const locationId = locationIdOverride || locationIdStore

//   const res = await api
//     .post(`/v1/locations/${locationId}/payments/prehosted-token`, {
//       clientKey: uuidv4(),
//       total: {
//         minorUnits,
//         currencyCode,
//       },
//     })
//     .catch(err => {
//       console.error(err)
//       return dispatch(
//         addAlert({
//           message: 'Transaction failed: Failed to get payment token.',
//           error: true,
//           dismiss: true,
//           top: true,
//         })
//       )
//     })

//   return res.data
// }

const integrapayEndpoint = (environment => {
  switch (environment) {
    case 'development':
    case 'development-experiment':
    case 'test':
    case 'staging':
    case 'staging-experiment':
      return 'https://testpayments.integrapay.com.au/js/JSApi.ashx'
    case 'production':
    case 'production-experiment':
      return 'https://payments.integrapay.com.au/js/JSApi.ashx'
    default:
      return 'https://testpayments.integrapay.com.au/js/JSApi.ashx'
  }
})(process.env.REACT_APP_API_STAGE)

const makeCcPayment = ({ token, number, name, expiry, cvc }) => async dispatch => {
  const res = await ext
    .post(integrapayEndpoint, {
      token: token,
      cardName: name.trim(),
      cardNumber: number.match(/\d+/g).join(''),
      cardExpiryMonth: expiry.slice(0, 2),
      cardExpiryYear: `20${expiry.slice(expiry.length - 2, expiry.length)}`,
      cardCcv: cvc.match(/\d+/g).join(''),
    })
    .catch(err => {
      console.error(err)
      return dispatch(
        addAlert({
          message: 'Transaction failed',
          error: true,
          dismiss: true,
          top: true,
        })
      )
    })

  return res.data
}

export default makeCcPayment
// export const createOrder = ({
//   token,
//   minorUnits,
//   currencyCode,
//   reference,
//   orderId,
//   phone,
//   email,
//   sendReceipt,
//   locationId: locationIdOverride,
//   tenderTypesAbilityId,
//   passOnFeeEnabled,
// }) => async (dispatch, getState) => {
//   const {
//     business: { locationId: locationIdStore },
//   } = getState()

//   const locationId = locationIdOverride || locationIdStore

//   await api
//     .post(`/v1/locations/${locationId}/payments`, {
//       locationId,
//       webpageToken: token,
//       orderReference: reference,
//       total:
//         minorUnits || currencyCode
//           ? {
//               minorUnits,
//               currencyCode,
//             }
//           : undefined,
//       recipientPhone: phone,
//       recipientEmail: email,
//       sendReceipt,
//       orderId,
//       tenderTypesAbilityId,
//       passOnFeeEnabled,
//     })
//     .then(response => {
//       if (response.data.paymentStatus === 'PAID') {
//         // For reading transaction results from Webview
//         if (typeof window.transactionCallback === 'function') {
//           window.transactionCallback({ result: 'success', data: response.data })
//         }

//         const androidString = JSON.stringify({ result: 'success', orderId: response.data.id })

//         /*eslint-disable */
//         if (typeof Android === 'object' && typeof Android.transactionCallback === 'function') {
//           Android.transactionCallback(androidString)
//         }
//         /*eslint-enable */

//         return dispatch(
//           addAlert({
//             message: `Payment succeeded`,
//             success: true,
//             dismiss: true,
//             top: true,
//           })
//         )
//       } else {
//         // For reading transaction results from Webview
//         if (typeof window.transactionCallback === 'function') {
//           window.transactionCallback({
//             result: 'error',
//             error: response.data.error ?? 'Payment declined',
//           })
//         }

//         const androidString = JSON.stringify({
//           result: 'error',
//           error: response.data.error ?? 'Payment declined',
//         })

//         /*eslint-disable */
//         if (typeof Android === 'object' && typeof Android?.transactionCallback === 'function') {
//           Android.transactionCallback(androidString)
//         }
//         /*eslint-enable */

//         return dispatch(
//           addAlert({
//             message: response.data.error ?? `Payment declined`,
//             error: true,
//             dismiss: true,
//             top: true,
//           })
//         )
//       }
//     })
//     .catch(err => {
//       // For reading transaction results from Webview
//       if (typeof window.transactionCallback === 'function') {
//         window.transactionCallback({
//           result: 'error',
//           error: err?.response?.data?.error || err || 'Unknown Error',
//         })
//       }

//       const androidString = JSON.stringify({
//         result: 'error',
//         error: err?.response?.data?.error || err || 'Unknown Error',
//       })

//       /*eslint-disable */
//       if (typeof Android === 'object' && typeof Android?.transactionCallback === 'function') {
//         Android.transactionCallback(androidString)
//       }
//       /*eslint-enable */
//       const errMsg = err?.response?.data?.error
//       if (errMsg) return dispatch(addAlert({ message: errMsg, error: true, dismiss: true, top: true }))
//       else return dispatch(addAlert({ message: `Payment failed.`, error: true, dismiss: true, top: true }))
//     })
// }
