import * as React from 'react'
import classnames from 'classnames'

import cls from './emoji-headline.module.scss'
import { UikContentItem } from '@uik'

const UikEmojiHeadline = ({ emoji, title, description, className, ...rest }: UikEmojiHeadlineProps) => (
  <UikContentItem className={classnames(cls.wrapper, className)} {...rest}>
    {emoji && <div className={cls.emojiBox}>{emoji}</div>}
    <div className={cls.content}>
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
    </div>
  </UikContentItem>
)

UikEmojiHeadline.defaultProps = {
  className: null,
  emoji: null,
  title: null,
  description: null,
}

export default UikEmojiHeadline
