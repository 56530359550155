import { createSlice } from '@redux'
import api from '@api'
import { addAlert } from '@features/alerts'

export const initialState = []

const slice = createSlice({
  name: 'term_conditions',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        return payload
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions
export default slice.reducer

export const getTermConditions = oid => async (dispatch, getState) => {
  try {
    var res = await api.get(`/v1/term_conditions/latest`)
    dispatch(set(res.data))
    return res?.data
  } catch (error) {
    console.error(error)
  }
}

export const putTermConditions = values => async (dispatch, getState) => {
  try {
    const obj = { id: values.id, accepted: values.accepted }
    const res = await api.put(`/v1/term_conditions/${obj.id}`, obj)
    return res
  } catch (error) {
    return dispatch(
      addAlert({ message: `putTermConditions setting fail to update`, error: true, dismiss: true, top: true })
    )
  }
}
