import api from '@api'
import { setInsightsData } from './index.js'

export const getInsights = bid => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/businesses/${bid}/insights`)
    await dispatch(setInsightsData(res.data))
    //return res.data
  } catch (error) {
    console.error(error)
  }
}

export default getInsights
