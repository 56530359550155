import api from '@api'
import { updateLoan, setTransactionsFlag } from './index.js'

const per_page = 8

export const getTransactionsData = (id, flag) => async (dispatch, getState) => {
  var page = 1
  var doneFetching = false
  const trans = []

  try {
    while (!doneFetching) {
      const res = await api.get(`/v1/lendings/loans/${id}/transactions?page=${page}&per_page=${per_page}`)
      if (res.data.length > 0) trans.push(...res.data)
      if (res.data.length < per_page) doneFetching = true
      page++
    }
    // update loan record with trans
    //if (trans.length > 0) {
    const updatedLoan = {
      ...getState().lending.loans.filter(loan => loan.id === id)[0],
      transactionsFetched: true,
      transactions: trans,
    }
    await dispatch(updateLoan(updatedLoan))
    //}
    if (flag) await dispatch(setTransactionsFlag(flag))
    return trans
  } catch (error) {
    await dispatch(setTransactionsFlag(flag))
    console.error(error)
  }
}

export default getTransactionsData
