import React from 'react'

export const PropellCenter = ({ children, w, h }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: w ? `${w}px` : '16px',
        height: h ? `${h}px` : '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export const PropellIconTick = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
        stroke="#66DAD8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0938 7.3125L7.96873 11.25L5.90625 9.28125"
        stroke="#66DAD8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PropellIconError = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 5.9375V10.0938" stroke="#EF404A" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.2053 2.375H6.79467C6.7167 2.375 6.63949 2.39036 6.56745 2.4202C6.49541 2.45004 6.42996 2.49377 6.37482 2.54891L2.54891 6.37482C2.49377 6.42996 2.45004 6.49541 2.4202 6.56745C2.39036 6.63949 2.375 6.7167 2.375 6.79467V12.2053C2.375 12.2833 2.39036 12.3605 2.4202 12.4326C2.45004 12.5046 2.49377 12.57 2.54891 12.6252L6.37482 16.4511C6.42996 16.5062 6.49541 16.55 6.56745 16.5798C6.63949 16.6096 6.7167 16.625 6.79467 16.625H12.2053C12.2833 16.625 12.3605 16.6096 12.4326 16.5798C12.5046 16.55 12.57 16.5062 12.6252 16.4511L16.4511 12.6252C16.5062 12.57 16.55 12.5046 16.5798 12.4326C16.6096 12.3605 16.625 12.2833 16.625 12.2053V6.79467C16.625 6.7167 16.6096 6.63949 16.5798 6.56745C16.55 6.49541 16.5062 6.42996 16.4511 6.37482L12.6252 2.54891C12.57 2.49377 12.5046 2.45004 12.4326 2.4202C12.3605 2.39036 12.2833 2.375 12.2053 2.375Z"
        stroke="#EF404A"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.5 13.6562C9.99188 13.6562 10.3906 13.2575 10.3906 12.7656C10.3906 12.2737 9.99188 11.875 9.5 11.875C9.00812 11.875 8.60938 12.2737 8.60938 12.7656C8.60938 13.2575 9.00812 13.6562 9.5 13.6562Z"
        fill="#EF404A"
      />
    </svg>
  )
}

export const PropellIconInfo = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 18.3337C6.39758 18.3337 2.66675 14.6028 2.66675 10.0003C2.66675 5.39783 6.39758 1.66699 11.0001 1.66699C15.6026 1.66699 19.3334 5.39783 19.3334 10.0003C19.3334 14.6028 15.6026 18.3337 11.0001 18.3337ZM11.0001 16.667C14.682 16.667 17.6667 13.6822 17.6667 10.0003C17.6667 6.31843 14.682 3.33366 11.0001 3.33366C7.31818 3.33366 4.33341 6.31843 4.33341 10.0003C4.33341 13.6822 7.31818 16.667 11.0001 16.667ZM6.63341 9.79782L10.1692 13.3337L16.0617 7.44116L14.8826 6.26283L10.1692 10.977L7.81175 8.61949L6.63341 9.79782Z"
        fill="#549ee8"
      />
    </svg>
  )
}

export const PropellIconWarning = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 10L5.25 6.4375C5.17238 6.37928 5.10938 6.30379 5.06598 6.21701C5.02259 6.13022 5 6.03453 5 5.9375V3.125C5 2.95924 5.06585 2.80027 5.18306 2.68306C5.30027 2.56585 5.45924 2.5 5.625 2.5H14.375C14.5408 2.5 14.6997 2.56585 14.8169 2.68306C14.9342 2.80027 15 2.95924 15 3.125V5.90947C15 6.0061 14.9776 6.10141 14.9345 6.18792C14.8915 6.27443 14.829 6.34978 14.7519 6.40805L10 10Z"
        stroke="#273869"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10L5.25 13.5625C5.17238 13.6207 5.10938 13.6962 5.06598 13.783C5.02259 13.8698 5 13.9655 5 14.0625V16.875C5 17.0408 5.06585 17.1997 5.18306 17.3169C5.30027 17.4342 5.45924 17.5 5.625 17.5H14.375C14.5408 17.5 14.6997 17.4342 14.8169 17.3169C14.9342 17.1997 15 17.0408 15 16.875V14.0905C15 13.9939 14.9776 13.8986 14.9345 13.8121C14.8915 13.7256 14.829 13.6502 14.7519 13.5919L10 10Z"
        stroke="#273869"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83276 6.87465H14.1345"
        stroke="#273869"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.1246V10"
        stroke="#273869"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
