import { createSlice } from '@redux'

export const initialState = { pathBeforeLogout: '' }

const routingStateHelper = createSlice({
  name: 'routingStateHelper',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      return { ...state, ...payload }
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = routingStateHelper.actions

export const setRoutingState = s => async (dispatch, getState) => {
  dispatch(set({ pathBeforeLogout: s }))
}

export default routingStateHelper.reducer
