import api from '@api'
import { addAlert } from '@features/alerts'

const getLoan = values => async (dispatch, getState) => {
  var rc
  await api
    .post(`/v1/lendings/loans`, {
      ...values,
      //      isKikka: true, // tell API handle we are Kikka api request
    })
    .then(() => {
      dispatch(
        addAlert({
          message: 'Get Funds request sent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      rc = 0
    })
    .catch(err => {
      console.error(err)
      dispatch(
        addAlert({
          message: 'Get Funds request failed',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      rc = -1
    })

  return rc //res.data
}
export default getLoan
