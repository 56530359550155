import api from '@api'

const result = values => async (dispatch, getState) => {
  const res = await api
    .get(
      `/v1/lendings/posthosted-status?webpage_token=${values.webpageToken}&transaction_id=${values.transactionId}`,
      values
    )
    .then(result => {
      return result
    })
    .catch(err => {
      console.error(err.response.data.error)
      return err.response
    })
  return res.data
}
export default result
