import React from 'react'
import cls from './index.module.scss'

const PropellInput = props => {
  const { label, type, placeholder, errorMessage, touched, fields, disabledErrMsg, smallGap, ...rest } = props
  return (
    <div className={!errorMessage ? cls.inputBox : cls.inputBoxErrBorder}>
      <div className={cls.inputBoxLabel}>{label}</div>
      <div className={cls.container}>
        <input
          type={type}
          placeholder={placeholder}
          {...fields}
          {...rest}
          style={smallGap ? { margin: '4px 0 12px 0' } : null}
        />
      </div>
      {errorMessage && !disabledErrMsg && <p className={cls.errorMessage}>{errorMessage}</p>}
    </div>
  )
}

export default PropellInput
