import { useEffect, useState } from 'react'
import { useSelector } from '@redux'
import {
  getOutgoingTotal,
  getExpenseItems,
  getIcomeItems,
  getDiscretionaryExpenseItems,
  getLendingItems,
  arraySum,
} from './utils'
/* 
  Hook - construst bar char data 
*/
const useDecisionMetric = bid => {
  const connections = useSelector(state => state.bankFeeds.connections)
  const insights = useSelector(({ insights }) => insights)
  //const [fetched, setFetched] = useState(false)
  const [pieChartData, setPieChartData] = useState([])
  const [expensePieChartData, setExpensePieChartData] = useState([])
  const [netWorkingCapital, setNetWorkingCapital] = useState(0)
  const [discretionarySpendingRatio, setDiscretionarySpendingRatio] = useState('')
  const [discretionarySpendingState, setDiscretionarySpendingState] = useState('Red')
  const [lendingItemsCnt, setLendingItemsCnt] = useState(0)
  const [lendingItemsState, setLendingItemsState] = useState('Red')
  const [expenseHealthPercent, setExpenseHealthPercent] = useState(0)
  const [expenseHealth, setExpenseHealth] = useState('GOOD')
  const [expenseList, setExpenseList] = useState([])
  const [openBalancesSum, setOpenBalancesSum] = useState(0)
  const [expenseSum, setExpenseSum] = useState(0)
  const [dishoursCnt, setDishoursCnt] = useState(0)

  useEffect(() => {
    //if (!fetching && fetchCompleted) {
    if (connections?.length > 0) {
      //console.log('connections' + JSON.stringify(connections))

      const connectedAccounts = [...connections].filter(o => o.status === 'CONNECTED')
      //console.log('connectedAccounts ' + JSON.stringify(connectedAccounts))
      const connectedBankAccounts = [...connectedAccounts].map(a => {
        return a.bankAccounts.filter(a => a.accountType === 'Savings' || a.accountType === 'Transaction')
      })
      //console.log('connectedBankAccounts ' + JSON.stringify(connectedBankAccounts))
      const openBalances = connectedBankAccounts.map(a => {
        // console.log('a ' + JSON.stringify(a))
        const lists = [...a]
        //console.log('list ' + JSON.stringify(lists))
        return lists.map(o => Number(o.openingBalance))
      })
      //console.log('openBalances ' + JSON.stringify(...openBalances))
      if (openBalances.length > 0) {
        const openBalancesSum = { ...openBalances }[0].reduce((o, a) => o + a, 0)
        //console.log('openBalancesSum ' + JSON.stringify(openBalancesSum))
        setOpenBalancesSum(openBalancesSum)
      }
    }
    //}
    // eslint-disable-next-line
    //}, [fetching, fetchCompleted])
  }, [connections])

  useEffect(() => {
    //if (bid) startFetch(true)
    // eslint-disable-next-line
  }, [bid])

  useEffect(() => {
    if (insights?.decisionMetrics) {
      if (insights.decisionMetrics.length > 0) {
        // console.log(
        //   'lending item : ' +
        //     JSON.stringify(
        //       insights.decisionMetrics.filter(o => o.sectionTitle === 'Responsible lending flags')
        //     )
        // )
        //setMonthlyExpense(insights.decisionMetrics)
        const pieChartArray = insights.decisionMetrics
          .map(o => [o.name, Number(o.value)])
          .sort((a, b) => b[1] - a[1])
        //console.log('pieChartArray' + JSON.stringify(pieChartArray))
        setPieChartData([['Expense', 'Expense per Category'], ...pieChartArray])

        const expensePieChartArray = insights.decisionMetrics
          .filter(o => o.section_title === 'Expenses' || o.sectionTitle === 'Expenses')
          .map(o => [o.name, Number(o.value)])
          .sort((a, b) => b[1] - a[1])

        setExpensePieChartData([['Expense', 'Expense per Category'], ...expensePieChartArray])

        const outgoingTotal = getOutgoingTotal(insights.decisionMetrics)
        const discretionaryExpenseItems = getDiscretionaryExpenseItems(insights.decisionMetrics)
        const expenseItems = getExpenseItems(insights.decisionMetrics)
        const incomeItems = getIcomeItems(insights.decisionMetrics)
        const lendingItems = getLendingItems(insights.decisionMetrics)
        const discretionaryExpenseSum = arraySum(discretionaryExpenseItems) // discretionaryExpenseItems.reduce((o, a) => o + a, 0)
        const expenseSum = arraySum(expenseItems)
        const incomeSum = arraySum(incomeItems)
        // console.log('((incomeSum + openBalancesSum - outgoingTotal) / (incomeSum + openBalancesSum)) * 100')
        // console.log(
        //   `((${incomeSum} + ${openBalancesSum} - ${outgoingTotal}) / (${incomeSum} + ${openBalancesSum})) * 100`
        // )
        const ratio = Number(
          ((incomeSum + openBalancesSum - outgoingTotal) / (incomeSum + openBalancesSum)) * 100
        ).toFixed(0)
        setNetWorkingCapital(ratio)

        // calculate discretionary spending indices
        // console.log('(discretionaryExpenseSum / Math.abs(outgoingTotal))')
        // console.log(`(${discretionaryExpenseSum} / Math.abs(${outgoingTotal}))`)
        const discretionaryRatio = Number((discretionaryExpenseSum / Math.abs(outgoingTotal)) * 100).toFixed(
          0
        )
        setDiscretionarySpendingRatio(`${discretionaryRatio}%`)
        setDiscretionarySpendingState(
          discretionaryRatio <= 5
            ? 'Green'
            : discretionaryRatio > 5 && discretionaryRatio <= 10
            ? 'Yellow'
            : 'Red'
        )

        // count items that affect lending
        // console.log('lending items ' + JSON.stringify(lendingItems))
        setLendingItemsCnt(lendingItems.length)
        setLendingItemsState(lendingItems.length < 1 ? 'Green' : lendingItems.length === 1 ? 'Yellow' : 'Red')

        // get health ratio
        const healthRatio = Number((expenseSum / Math.abs(outgoingTotal)) * 100).toFixed(0)
        setExpenseHealthPercent(`${healthRatio}%`)
        setExpenseHealth(
          healthRatio >= 95 ? 'EXCELLENT' : healthRatio > 90 && healthRatio < 95 ? 'AVERAGE' : 'POOR'
        )

        // generate sorted expense list
        const expenses = [...insights.decisionMetrics].sort((a, b) => Number(b.value) - Number(a.value))
        setExpenseList(expenses)
        setExpenseSum(expenseSum)

        const DM004s = insights?.decisionMetrics.filter(o => o.metricId === 'DM004')
        if (DM004s?.length > 0) {
          //console.log('DM004s: ' + JSON.stringify(DM004s[0]))
          const obj = DM004s[0]
          setDishoursCnt(parseInt(obj.value))
        }
      }
    }

    // eslint-disable-next-line
  }, [insights])

  return {
    pieChartData,
    expensePieChartData,
    netWorkingCapital,
    discretionarySpendingRatio,
    discretionarySpendingState,
    lendingItemsCnt,
    lendingItemsState,
    expenseHealthPercent,
    expenseHealth,
    expenseList,
    expenseSum,
    dishoursCnt,
  }
}
export default useDecisionMetric
