import * as React from 'react'
import classnames from 'classnames'
import { IconsPropell } from '@components'
import cls from './nav-link.module.scss'

const UikNavLink = ({
  rightEl,
  children,
  className,
  highlighted,
  icon,
  Component,
  isActive,
  isExpanded,
  isDirectLink,
  badge,
  hasBadge,
  expandable,
  ...rest
}: UikNavLinkProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        //maxWidth: '200px',
        maxWidth: '100%',
        //border: '1px solid green',
        borderTop: '1px solid #E9E8E5',
        // borderBottom: '1px solid #E9E8E5',
        height: '48px',
      }}
    >
      <Component
        className={classnames(cls.wrapper, className, {
          [cls.highlighted]: highlighted,
          [cls.active]: isActive,
          [cls.expanded]: isExpanded,
          [cls.isDirectLink]: isDirectLink,
        })}
        {...rest}
        //style={{ border: '1px red solid' }}
      >
        <div className={cls.text}>
          {icon && <span className={cls.icon}>{icon}</span>}
          <div className={isActive ? cls.childrenActive : cls.children}>{children}</div>
        </div>
        {rightEl && <span className={cls.rightEl}>{rightEl}</span>}
        {expandable && !hasBadge ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              //border: 'orange 1px solid',
              minHeight: '40px',
              cursor: 'pointer',
              marginRight: '20px',
            }}
          >
            {isExpanded ? <IconsPropell name="PropellIconUp" /> : <IconsPropell name="PropellIconDown" />}
          </div>
        ) : hasBadge ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              //border: 'orange 1px solid',
              minHeight: '40px',
            }}
          >
            <div>{badge}</div>
          </div>
        ) : null}
      </Component>
    </div>
  )
}

UikNavLink.defaultProps = {
  className: null,
  rightEl: null,
  highlighted: false,
  icon: null,
  children: null,
  Component: 'a',
}

export default UikNavLink
