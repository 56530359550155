import React, { useState, useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { Flex, Box } from '@chakra-ui/react'
import { Button } from '@components'
import { InputCreditCard as Input } from '@uik'

import { creditCardNameValidation } from '@lib/formUtils'
import VisaImage from '../../assets/ui-payment-type-small-visa.svg'
import MasterImage from '../../assets/ui-payment-type-small-master-card.svg'
import AmexImage from '../../assets/Amex_logo_color.svg'

import cls from './index.module.scss'
import { useBreakpoint } from '@hooks'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements } from '@stripe/react-stripe-js'

const CreditCardInput = ({ onSubmit, buttonText = 'SUBMIT', children, initialValues, extraComponent }) => {
  const [formKey, setFormKey] = useState(0)
  const incFormKey = () => setFormKey(v => v + 1)
  const breakpoint = useBreakpoint()
  const elements = useElements()
  const [stripeElementState, setStripeElementState] = useState({ number: {}, expiry: {}, cvc: {} })
  const [numberEvt, setNumberEvt] = useState()
  const [numberErr, setNumberErr] = useState()
  const [expiryEvt, setExpiryEvt] = useState()
  const [expiryErr, setExpiryErr] = useState()
  const [cvcEvt, setCvcEvt] = useState()
  const [cvcErr, setCvcErr] = useState()
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '14px',
        //fontFamily: 'Roboto',
        fontWeight: '350',
        color: '#3e3f42',
        '::placeholder': {
          //fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '350',
          color: '#A0AEC0',
        },
      },
      invalid: {
        color: '#e74c3c',
      },
    },
  }

  const cardNumberElementOptions = {
    placeholder: 'Card Number',
    style: {
      base: {
        fontSize: '14px',
        //fontFamily: 'Roboto',
        fontWeight: '350',
        color: '#3e3f42',
        '::placeholder': {
          //fontFamily: 'Roboto',
          fontSize: '14px',
          fontWeight: '350',
          color: '#A0AEC0',
        },
      },
      invalid: {
        color: '#e74c3c',
      },
    },
  }

  useEffect(() => {
    if (elements) {
      //console.log('elements rdy')
      const cardNum = elements.getElement(CardNumberElement)
      const cardExpiry = elements.getElement(CardExpiryElement)
      const cardCvc = elements.getElement(CardCvcElement)

      cardNum.on('focus', () => {
        setNumberEvt('focus')
      })
      cardNum.on('blur', () => {
        setNumberEvt('blur')
      })
      cardNum.on('change', function(event) {
        //console.log('event ' + event)
        if (event.error) {
          setNumberEvt('error')
          setNumberErr(event.error.message)
        }
      })

      cardExpiry.on('focus', () => {
        setExpiryEvt('focus')
      })
      cardExpiry.on('blur', () => {
        setExpiryEvt('blur')
      })
      cardExpiry.on('change', function(event) {
        //console.log('event ' + event)
        if (event.error) {
          setExpiryEvt('error')
          setExpiryErr(event.error.message)
        }
      })

      cardCvc.on('focus', () => {
        setCvcEvt('focus')
      })
      cardCvc.on('blur', () => {
        setCvcEvt('blur')
      })
      cardCvc.on('change', function(event) {
        //console.log('event ' + event)
        if (event.error) {
          setCvcEvt('error')
          setCvcErr(event.error.message)
        }
      })
    } else {
      //console.log('elements Not rdy')
    }
  }, [elements])

  useEffect(() => {
    switch (numberEvt) {
      case 'error':
        setStripeElementState({ ...stripeElementState, number: 'error' })
        break
      case 'focus':
        setStripeElementState({ ...stripeElementState, number: 'focus' })
        setNumberErr()
        break
      case 'blur':
        if (stripeElementState.number !== 'error')
          setStripeElementState({ ...stripeElementState, number: 'blur' })
        break
      default:
        break
    }
    // eslint-disable-next-line
  }, [numberEvt])

  useEffect(() => {
    switch (expiryEvt) {
      case 'error':
        setStripeElementState({ ...stripeElementState, expiry: 'error' })
        break
      case 'focus':
        setStripeElementState({ ...stripeElementState, expiry: 'focus' })
        setExpiryErr()
        break
      case 'blur':
        if (stripeElementState.expiry !== 'error')
          setStripeElementState({ ...stripeElementState, expiry: 'blur' })
        break
      default:
        break
    }
    // eslint-disable-next-line
  }, [expiryEvt])

  useEffect(() => {
    switch (cvcEvt) {
      case 'error':
        setStripeElementState({ ...stripeElementState, cvc: 'error' })
        break
      case 'focus':
        setStripeElementState({ ...stripeElementState, cvc: 'focus' })
        setCvcErr()
        break
      case 'blur':
        if (stripeElementState.cvc !== 'error') setStripeElementState({ ...stripeElementState, cvc: 'blur' })
        break
      default:
        break
    }
    // eslint-disable-next-line
  }, [cvcEvt])

  return (
    <Form
      key={formKey}
      onSubmit={onSubmit}
      initialValues={{ sendReceipt: true, ...initialValues }}
      render={({ handleSubmit, form, submitting, pristine, values, active, invalid }) => {
        return (
          <form
            onSubmit={event => {
              const promise = handleSubmit(event)
              promise &&
                promise.then(() => {
                  incFormKey()
                })
              return promise
            }}
            style={{ maxWidth: '100%', width: '100%' }}
            noValidate
            id="externalSubmitFormTarget"
          >
            <Flex className={cls.section}>
              {children}
              {breakpoint > 1 ? extraComponent : null}
            </Flex>

            <Flex className={cls.section}>
              <Flex className={cls.visaLogoContainer}>
                <Box className={cls.visaLogoContainerBox}>
                  <img src={MasterImage} alt="Propell" />
                </Box>
                <Box className={cls.visaLogoContainerBox}>
                  <img src={AmexImage} alt="Propell" />
                </Box>
                <Flex className={cls.visaLogoContainerBox}>
                  <img src={VisaImage} alt="Propell" />
                </Flex>
              </Flex>

              <Box maxW={400}>
                <Field name="name" type="text" validate={creditCardNameValidation}>
                  {({ input, meta }) => (
                    <Input
                      label="Name"
                      placeholder="As displayed on card"
                      {...input}
                      autoComplete="cc-name"
                      errorMessage={meta.error && meta.touched && meta.error}
                      className={cls.inputBox}
                      altStyle={true}
                    />
                  )}
                </Field>

                <Flex direction="column">
                  <Flex direction="column" w="100%" pb="16px">
                    <span className={cls.expiryInputBoxLabel}>Number</span>
                    <Box
                      className={cls.stripeWrapperBox}
                      style={{
                        borderColor:
                          stripeElementState?.number === 'error'
                            ? '#e74c3c'
                            : stripeElementState?.number === 'focus'
                            ? '#66dad8'
                            : '#d5d7da',
                      }}
                    >
                      <CardNumberElement options={cardNumberElementOptions} />
                    </Box>
                    {numberErr ? <p className={cls.errorMessage}>{numberErr}</p> : null}
                  </Flex>
                  <Flex className={cls.inputBoxContainer}>
                    <Flex direction="column" w="70%" pb="16px">
                      <span className={cls.expiryInputBoxLabel}>Card Expiry</span>
                      <Box
                        className={cls.stripeWrapperBox}
                        w="100%"
                        style={{
                          borderColor:
                            stripeElementState?.expiry === 'error'
                              ? '#e74c3c'
                              : stripeElementState?.expiry === 'focus'
                              ? '#66dad8'
                              : '#d5d7da',
                        }}
                      >
                        <CardExpiryElement options={cardElementOptions} />
                      </Box>
                      {expiryErr ? <p className={cls.errorMessage}>{expiryErr}</p> : null}
                    </Flex>
                    <Box w="30%" />
                    <Flex direction="column" w="30%" pb="16px">
                      <span className={cls.expiryInputBoxLabel}>Cvc</span>
                      <Box
                        className={cls.stripeWrapperBox}
                        w="100%"
                        style={{
                          borderColor:
                            stripeElementState?.cvc === 'error'
                              ? '#e74c3c'
                              : stripeElementState?.cvc === 'focus'
                              ? '#66dad8'
                              : '#d5d7da',
                        }}
                      >
                        <CardCvcElement options={cardElementOptions} />
                      </Box>
                      {cvcErr ? <p className={cls.errorMessage}>{cvcErr}</p> : null}
                    </Flex>
                  </Flex>
                  <Box h="32px" />
                </Flex>
              </Box>
            </Flex>
            {breakpoint > 1 ? null : (
              <Flex w="100%" justify="center">
                {extraComponent}
              </Flex>
            )}
            <div className={cls.buttonContainer}>
              <Button
                type="submit"
                disabled={submitting || invalid || numberErr || cvcErr || expiryErr}
                isLoading={submitting}
                className={cls.buttonContainerBtn}
                w={breakpoint < 2 ? '100%' : '40%'}
              >
                {buttonText}
              </Button>
            </div>
          </form>
        )
      }}
    />
  )
}

export default CreditCardInput
