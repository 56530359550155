import { createSlice } from '@redux'
import api from '@api'
//import { addAlert } from '@features/alerts'

export const initialState = { status: 'OK', other: 'dummy' }

const slice = createSlice({
  //  name: 'term_conditions',
  name: 'api_status',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        return payload
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions
export default slice.reducer

export const getApiStatus = oid => async (dispatch, getState) => {
  // var res = await api.get(`/v1/ping`)
  // dispatch(set(res.data))
  // return res?.data
  const testBadApi = {
    timeEpoch: 1623803544,
    status: 'NOK',
    androidVersion: '1.3.1',
    iosVersion: '2.2.0',
    webVersion: '1.0.1',
    bankfeedStatus: 'OK',
  }
  const testBadBankfeed = {
    timeEpoch: 1623803544,
    status: 'OK',
    androidVersion: '1.3.1',
    iosVersion: '2.2.0',
    webVersion: '1.0.1',
    bankfeedStatus: 'NOK',
  }
  const testBadOrigination = {
    timeEpoch: 1623803544,
    status: 'ONBOARDING DOWN',
    androidVersion: '1.3.1',
    iosVersion: '2.2.0',
    webVersion: '1.0.1',
    bankfeedStatus: 'OK',
  }
  try {
    if (localStorage.getItem('apiStatusDebug') === 'apiDown') {
      await dispatch(set(testBadApi))
      return testBadApi
    } else if (localStorage.getItem('apiStatusDebug') === 'bankfeedDown') {
      await dispatch(set(testBadBankfeed))
      return testBadBankfeed
    } else if (localStorage.getItem('apiStatusDebug') === 'originationDown') {
      await dispatch(set(testBadOrigination))
      return testBadBankfeed
    } else {
      var res = await api.get(`/v1/ping`)
      await dispatch(set(res.data))
      return res?.data
    }
  } catch (error) {
    console.error(error)
    dispatch(set({ status: 'NOK' }))
  }
}
