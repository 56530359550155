import { formatCurrency } from '@lib'
export const connectionsToAccountAdapter = connections => {
  const conns = [...connections]
  const accounts = []
  conns.forEach(conn => {
    const institution = conn.institution
    const bankAccounts = conn.bankAccounts
    bankAccounts.forEach(acc => {
      const accObj = {
        bank: institution,
        name: `${acc.isPrimaryTransactionAccount ? 'PRIMARY ACCOUNT' : acc.accountType}`,
        number: acc.accountNumber,
        isPrimary: acc.isPrimaryTransactionAccount,
      }
      accounts.push(accObj)
    })
  })
  const sorted = accounts.sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1
    else return 0
  })
  return sorted
}

export const adaptBarchartCardData = (apiData, max, min) => {
  const apiDataValues = apiData.map(e => parseInt(e.value))
  const sum = [...apiDataValues].reduce((a, b) => parseInt(a) + parseInt(b), 0)
  const sumAv = sum / (apiDataValues?.length || 1) || 0
  var base = min >= 0 ? max : max > Math.abs(min) ? max : Math.abs(min)
  const sumAvPercent = sumAv / base
  const average = { value: sumAv * 100, percent: sumAvPercent }

  const formatedData = [...apiDataValues].map((v, idx) => {
    const value = parseFloat(Number(v / base).toFixed(2))
    return { v: value, pol: value > 0, label: `${formatCurrency(v * 100)}`, dateLabel: apiData[idx].date }
  })

  return { data: formatedData, average }
}

export const DAY = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
// export const ChartColor = [
//   '#4FC64C',
//   '#385095',
//   '#A9E8E7',
//   '#00C9C6',
//   '#F4252D',
//   '#E5676D',
//   '#BF0267',
//   '#FD9588',
//   '#FFCA84',
//   '#006154',
//   '#FFC9D0',
//   '#CC5794',
//   '#D5D7DA',
// ]
// export const pieChartSliceColor = ChartColor.map((c, idx) => {
//   return { color: c }
// })

export const getOutgoingTotal = decisionMetrics => {
  const outgoingLists = decisionMetrics.filter(
    o =>
      o.sectionTitle === 'Expenses' ||
      o.sectionTitle === 'Liabilities' ||
      o.sectionTitle === 'Responsible lending flags'
  )
  const outgoingTotal = outgoingLists.map(o => Number(o.value)).reduce((o, a) => o + a, 0)
  return outgoingTotal
}

export const getExpenseItems = decisionMetrics => {
  const expenseItems = decisionMetrics.filter(o => o.sectionTitle === 'Expenses').map(o => Number(o.value))
  return expenseItems
}

export const getIcomeItems = decisionMetrics => {
  const icomeItems = decisionMetrics.filter(o => o.sectionTitle === 'Income').map(o => Number(o.value))
  return icomeItems
}

export const getDiscretionaryExpenseItems = decisionMetrics => {
  const discretionaryExpenseList = decisionMetrics
    .filter(
      o =>
        o.metricId === 'DM023' ||
        o.metricId === 'DM058' ||
        o.metricId === 'DM029' ||
        o.metricId === 'DM052' ||
        o.metricId === 'DM053' ||
        o.metricId === 'DM057' ||
        o.metricId === 'DM059'
    )
    .map(o => Number(o.value))
  return discretionaryExpenseList
}

export const getLendingItems = decisionMetrics => {
  const lendingItems = decisionMetrics
    .filter(o => o.sectionTitle === 'Responsible lending flags')
    .map(o => Number(o.value))
  return lendingItems
}

export const arraySum = arr => {
  const sum = [...arr].reduce((o, a) => o + a, 0)
  return sum
}

export const generateBarChartData = numOfRecord => {
  // generate dummy balanceData and balanceMax values
  const balanceData = []
  //var balanceMax
  balanceData.fill(0, 0, numOfRecord)
  const today = new Date()

  for (var i = 0; i < numOfRecord; i++) {
    const val = Math.floor(Math.random() * 5000)
    const polarity = val % 7 === 0 ? -1 : 1
    const mDate = new Date(today.getTime() - 24 * 60 * 60 * 1000 * i)
    balanceData[i] = {
      value: val * polarity,
      label: mDate.toDateString(),
      short: DAY[mDate.getDay()],
      date: mDate,
      id: i,
    }
  }
  return balanceData
}
