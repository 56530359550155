import { useSelector } from '@redux'

const useShow = show => {
  const choices = useSelector(state => state.originations.choice)
  if (!show) return true

  if (typeof show === 'string') {
    return !!choices[show]
  }

  if (Array.isArray(show)) {
    return show.some(e => !!choices[e])
  }

  return false
}
export default useShow
