import { useSelector } from '@redux'
import { useMemo } from 'react'
import { useEffect, useState } from 'react'
//import { sleep } from '@lib'

const useAccountStage = () => {
  const business = useSelector(({ business }) => business)
  const user = useSelector(({ user }) => user)
  const [tradeLendingEnabled, setTradeLendingEnabled] = useState(false)
  const [merchantsEnabled, setMerchantsEnabled] = useState(false)
  const [lendingEnabled, setLendingEnabled] = useState(false)
  const [paymentEnabled, setPaymentEnabled] = useState(false)
  const [paymentOptions, setPaymentOptions] = useState(false)
  const [insightsEnabled, setInsightsEnabled] = useState(false)
  const [lendingConditionalApproved, setLendingConditionalApproved] = useState(false)
  const [lendingConditionalApprovedAmount, setLendingConditionalApprovedAmount] = useState(false)
  const [paymentConditionalApproved, setPaymentConditionalApproved] = useState()
  const [lendingClosed, setLendingClosed] = useState(false)
  const originationsStageRaw = useSelector(({ originations }) => originations?.business?.current)
  const originationsStage = useMemo(() => originationsStageRaw || [], [originationsStageRaw])
  const [ready, setReady] = useState(false)
  const [originationsStageCompleted, setOriginationStageCompleted] = useState(false)
  const acceptanceRequiredOnNewTerms = useSelector(({ user }) => user.acceptanceRequiredOnNewTerms)
  const [accountState, setAccountState] = useState('loading')

  function setPaymentEnabledLocalstore(newval) {
    const oldPaymentEnabled = localStorage.getItem('paymentEnabled')
    if (oldPaymentEnabled === null) {
      localStorage.setItem('paymentEnabled', newval)
    } else {
      if (oldPaymentEnabled !== 'true') {
        localStorage.setItem('paymentEnabled', true)
        if (newval) {
          localStorage.setItem('paymentFirstEnabled', '0')
        }
      }
    }
  }

  useEffect(() => {
    if (business && business?.tenderTypesAbilities) {
      const lendingStage = business.stage?.lending || null
      const paymentStage = business.stage?.payment || null
      const insightsStage = business.stage?.insights || null
      const conditionalApproveStatus = business.stage?.conditional

      switch (lendingStage) {
        case 'Completed Application Form':
        case 'Pending':
        case 'Manual Review':
        case 'Not Yet Qualified':
          setLendingEnabled(false)
          break
        case 'Closed Lost':
        case 'Closed Won':
          setLendingEnabled(false)
          setLendingClosed(true)
          break
        case 'Approved Line of Credit':
          setLendingEnabled(true)
          break
        default:
          setLendingEnabled(false)
          break
      }
      setPaymentEnabled(paymentStage === 'Enabled')
      setInsightsEnabled(insightsStage === 'Approved Business Insights')
      setPaymentEnabledLocalstore(business.stage?.payment === 'Enabled')
      setLendingConditionalApproved(conditionalApproveStatus?.lendingApproved === true)
      setLendingConditionalApprovedAmount(conditionalApproveStatus?.lendingAmount)
      setPaymentConditionalApproved(conditionalApproveStatus?.paymentApproved === true)
      setMerchantsEnabled(user.propellSource === 'merchant')
      setTradeLendingEnabled(user.propellSource === 'tradeBuyer')
      const availableTender = business.tenderTypesAbilities.filter(tenderTypesAbility => {
        return tenderTypesAbility.status === 'AVAILABLE'
      })
      const mPaymentOptions = availableTender.map(ability => ability.key)
      setPaymentOptions(mPaymentOptions)
      //console.log('mPaymentOptions' + JSON.stringify(mPaymentOptions))
      ;(async () => {
        //await sleep(500)
        if (lendingStage === 'Approved Line of Credit') {
          setAccountState('lendingApproved')
          setOriginationStageCompleted(true)
        }
        setReady(true)
      })()
    }
    // eslint-disable-next-line
  }, [business])

  useEffect(() => {
    if (originationsStage.length > 0) {
      if (originationsStage[0] === 'CONFIRM PROCEED DONE' || originationsStage[0] === 'COMPLETED') {
        setOriginationStageCompleted(true)
        setAccountState('in-progress')
      } else {
        // console.log('user' + user?.propellSource)
        // if (user?.propellSource === 'merchant') setAccountState('merchantCompleted')
        // else
        setAccountState('applicationNotFinished')
      }
    }
  }, [originationsStage])

  return {
    tradeLendingEnabled,
    merchantsEnabled,
    lendingEnabled,
    paymentEnabled,
    insightsEnabled,
    lendingConditionalApproved,
    lendingConditionalApprovedAmount,
    paymentConditionalApproved,
    originationsStage,
    originationsStageCompleted,
    acceptanceRequiredOnNewTerms,
    ready,
    accountState,
    insightsRead: user?.insightsRead || false,
    insightsVersionDate: user?.insightsVersionDate,
    lendingClosed,
    paymentOptions,
  }
}
export default useAccountStage
