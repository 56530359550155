import api from '@api'
import { addAlert } from '@features/alerts'

const collectResend = values => async (dispatch, getState) => {
  const { locationId, id: orderId } = values
  //   console.log(
  //     'collectResend ' +
  //       JSON.stringify({
  //         locationId,
  //         orderId,
  //       })
  //   )
  const res = await api
    .post(`/v1/locations/${locationId}/payments/collection-request/resend`, {
      locationId,
      orderId,
    })
    .then(() => {
      return dispatch(
        addAlert({
          message: 'Collect payment request resent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    })
    .catch(err => {
      console.error(err)
      return dispatch(
        addAlert({
          message: 'Collect payment request resend failed',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    })

  return res.data
}
export default collectResend
