import React, { useEffect } from 'react'
//import { Alert as ChakraAlert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { connect } from '@redux'
//import classnames from 'classnames'

import { useTimeout, useRouter } from '@hooks'
import { removeAlert } from '@features/alerts'

import cls from './index.module.scss'
import { PropellIconTick, PropellIconError, PropellIconInfo, PropellIconWarning } from './icons'
const Alert = ({
  message,
  title,
  // hasIcon,
  // top,
  // bottom,
  // middle,
  dismiss,
  error,
  success,
  warning,
  info,
  id,
  removeAlert,
  dismissTimeout = 5000,
}) => {
  const { pathname } = useRouter()
  const AlertContainer = ({ children }) => {
    return (
      <div className={cls.alertFloatWrapper}>
        <div className={cls.alertFloatInnerWrapper}>
          <div className={cls.dummySiderMenu} />
          <div className={cls.alertFloat}>{children}</div>
        </div>
      </div>
    )
  }
  useTimeout(() => {
    if (dismiss) {
      removeAlert(id)
    }
  }, dismissTimeout)

  useEffect(() => {
    return () => {
      if (!dismiss && pathname !== '/') {
        removeAlert(id)
      }
    }
    // remove alert from redux on route change
  }, [dismiss, id, removeAlert, pathname])

  // const status = (() => {
  //   const collection = [error, success, warning, info]

  //   if (collection.every(v => !v)) {
  //     return 'info'
  //   }

  //   return ['error', 'success', 'warning', 'info'][collection.findIndex(v => v)]
  // })()

  return (
    <AlertContainer>
      <Flex
        className={
          success
            ? cls.alertContainerSuccess
            : error
            ? cls.alertContainerError
            : warning
            ? cls.alertContainerWarning
            : info
            ? cls.alertContainerInfo
            : cls.alertContainer
        }
      >
        <Flex p="0 8px">
          {success ? (
            <PropellIconTick />
          ) : error ? (
            <PropellIconError />
          ) : warning ? (
            <PropellIconWarning />
          ) : info ? (
            <PropellIconInfo />
          ) : null}
        </Flex>
        {title}
        {title ? ' - ' : ''} {message}
      </Flex>
    </AlertContainer>
  )
}

Alert.defaultProps = {
  dismiss: false,
  error: false,
  hasIcon: true,
  info: false,
  message: '',
  success: false,
  title: '',
  warning: false,
  top: false,
  bottom: true,
}

export default connect(null, { removeAlert })(Alert)
