import React from 'react'
//import { useBreakpoint } from '@hooks'
import cls from './index.module.scss'
import { IconsPropell } from '@components'
import { useSwipeable } from 'react-swipeable'

const CloseBtn = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 7L7 25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25 25L7 7" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const BpPageModal = ({ isOpen, onClose, children, pages, selectedPage, setSelectedPage }) => {
  //const bp = useBreakpoint()
  //const pages = useSelector(({ insightsModalHelper }) => insightsModalHelper.pages) || [{}, {}]
  // const [selectedPage, setSelectedPage] = useState(0)
  const handlers = useSwipeable({
    onSwipedLeft: eventData => {
      console.log('User Swiped Left!', eventData)
      if (selectedPage < pages.length - 1) setSelectedPage(selectedPage + 1)
      else setSelectedPage(0)
    },
    onSwipedRight: eventData => {
      console.log('User Swiped Right', eventData)
      if (selectedPage > 0) setSelectedPage(selectedPage - 1)
      else setSelectedPage(pages?.length - 1)
    },
  })

  return (
    <div className={cls.container} {...handlers}>
      <div
        className={cls.headerBar}
        style={{ justifyContent: pages?.length > 1 ? 'space-between' : 'flex-end' }}
      >
        {pages?.length > 1 ? (
          <div className={cls.pagesControl}>
            <div className={cls.pagesControlInner}>
              <span
                className={cls.pagesControlCursor}
                onClick={() => {
                  if (selectedPage > 0) setSelectedPage(selectedPage - 1)
                  else setSelectedPage(pages?.length - 1)
                }}
              >
                <IconsPropell name="ChevronLeftBtn" />
              </span>
              <span className={cls.pagesControlText}>
                &nbsp;&nbsp;{selectedPage + 1}/{pages?.length}&nbsp;&nbsp;
              </span>
              <span
                className={cls.pagesControlCursor}
                onClick={() => {
                  if (selectedPage < pages.length - 1) setSelectedPage(selectedPage + 1)
                  else setSelectedPage(0)
                }}
              >
                <IconsPropell name="ChevronRightBtn" />
              </span>
            </div>
          </div>
        ) : null}
        <div
          className={cls.closeBtn}
          onClick={() => {
            onClose()
          }}
        >
          <CloseBtn />
        </div>
      </div>
      {children}
      {pages[selectedPage]}
    </div>
  )
}

export default BpPageModal
