import React from 'react'
import { Button as ChakraButton } from '@chakra-ui/react'
import cls from './index.module.scss'
import mixpanel from 'mixpanel-browser'
import { PropellMixPanelEnabled } from '@Apps/..'

const Button = ({ children, color = 'primary', icon = null, ...props }) => {
  const baseProps = {
    bg: `${color}.600`,
    color: 'white',
    //p: '1rem 1.8rem 1.9rem 1.8rem',
    pl: '1.8rem',
    pr: '1.8rem',
    fontSize: '0.75rem',
    _hover: { bg: `${color}.500` },
    _focus: { outline: 'none', boxShadow: 'none' },
  }

  return (
    <ChakraButton
      {...baseProps}
      {...props}
      leftIcon={icon}
      className={cls.btn}
      onClick={e => {
        if (PropellMixPanelEnabled) mixpanel.track('ButtonOnClick', { source: `${children}` })
        if ('onClick' in props) props.onClick(e)
      }}
    >
      {children}
    </ChakraButton>
  )
}

export default Button
