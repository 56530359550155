// @flow
import api from '@api'
import { addAlert } from '@features/alerts'
import { addOrder } from './index.js'
import { getPaymentToken, postIntegrapay, createOrder } from './utils'

export type OrderStatus = 'open' | 'pending' | 'completed' | 'complete' | 'cancelled' | 'notFound'

export const getOrder = (lid: string, oid: string): OrderStatus => async dispatch => {
  let status: ?OrderStatus = null
  let result = null

  await api
    .get(`/v1/locations/${lid}/orders/${oid}`)
    .then(({ data }) => {
      //console.log('data:' + JSON.stringify(data))
      status = data.status.toLowerCase()
      result = data
      if (status === 'open' || status === 'pending') {
        dispatch(addOrder(data))
      }
    })
    .catch(err => {
      console.error(err)
      status = 'notFound'
    })

  return result
}

const payLater = ({
  number,
  name,
  expiry,
  cvc,
  email,
  sendReceipt,
  minorUnits,
  currencyCode,
  lid,
  oid,
  tenderTypesAbilityId,
  passOnFeeEnabled,
}) => async dispatch => {
  const tokenResponse = await dispatch(getPaymentToken(minorUnits, currencyCode, lid))
  if (!tokenResponse) return
  const { webpageToken: token } = tokenResponse

  const integraResponse = await dispatch(
    postIntegrapay({
      token,
      number,
      name,
      expiry,
      cvc,
    })
  )
  if (!integraResponse) return
  const { Result: integraResult, ErrorMessage: integraError } = integraResponse

  if (integraResult !== 'OK') {
    return dispatch(
      addAlert({
        message: `Transaction failed: ${integraError}`,
        error: true,
        dismiss: true,
        top: true,
        dismissTimeout: 10 * 1000,
      })
    )
  }

  await dispatch(
    createOrder({
      locationId: lid,
      orderId: oid,
      sendReceipt,
      email,
      token,
      tenderTypesAbilityId,
      passOnFeeEnabled,
    })
  )
}
export default payLater
