import * as Yup from 'yup'

import {
  numberValidationMessage,
  requiredFieldValidationMessage,
  numberSelectValidationMessage,
} from '@theme/strings'
import { parsePhoneNumber } from 'libphonenumber-js/min'

export const makeObject = fields => field => {
  const reducer = (a, b) => ({ ...a, [b]: fields[b][field] })
  return Object.keys(fields)
    .filter(f => f.formik !== false)
    .reduce(reducer, {})
}

export const getValidationCondition = show => productSelection => show.some(p => productSelection[p] === true)

export const textFieldValidation = (req = false, min = 3) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .matches(/^([a-z]|[A-Z])/, 'Should start with a letter')
    .matches(/^.*\S$/, 'White spaces are not allowed at start or end of thie field')
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const textFieldAllowSpaceValidation = (req = false, min = 3) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .matches(/^([a-z]|[A-Z])/, 'Should start with a letter')
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const driverLicenceCardFieldValidation = (req = false, min = 3) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .matches(/^.*\S$/, 'White spaces are not allowed at start or end of thie field')
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const addressFieldValidation = (req = false, min = 20) => {
  const val = Yup.string().min(min, `Should be at least ${min} characters`)
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const addressFieldValidationOrig = (req = false, min = 20) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .test('testAddress', 'Incorrect address format', function(value) {
      if (value?.length > 0) {
        const mAddrObj = parseAuAddress(value)
        const { path, createError } = this
        const status = { street: false, city: false, state: false, postal: false }
        status.street = mAddrObj.street.length > 0 ? true : false
        status.city = /.*[a-z]+/.test(mAddrObj.city) ? true : false
        status.state = mAddrObj.state.length > 0 ? true : false
        status.postal = mAddrObj.postal.length > 0 ? true : false
        if (status.street && status.city && status.state && status.postal) {
          return true
        } else {
          const missingFields = []
          if (!status.street) missingFields.push('street')
          if (!status.city) missingFields.push('city')
          if (!status.state) missingFields.push('state')
          if (!status.postal) missingFields.push('postal code')
          const lastIdx = missingFields.length - 1
          const missingFieldsLists = missingFields.map((field, index) =>
            index === 0 ? field : index !== lastIdx ? `, ${field}` : ` and ${field}`
          )
          var string = ''
          missingFieldsLists.forEach(s => {
            string += s
          })
          //console.log(' missingFieldsLists ' + string)
          return createError({
            path,
            message: 'Please enter your ' + string,
          })
        }
      }
    })
  // .matches(
  //   /^.*(\bQLD\b|\bqueensland\b|\bNSW\b|\bNew South Wales\b|\bVIC\b|\bVictoria\b|\bACT\b|\bAustralian Capital Territory\b|\bNT\b|\bNorthern Territory\b|\bSA\b|\bSouth Australia\b|\bWA\b|\bWestern Australia\b|\bTAS\b|\bTasmania\b){1}.*\d{4}.*/gi,
  //   'Invalid Address'
  // )
  //.matches(/^([0-9]|[a-z]|[A-Z])/, 'Should start with a letter')
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const emailFieldValidation = (req = false) => {
  const val = Yup.string().email()
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const numberFieldValidation = (req = false, min = 3) => {
  const val = Yup.number()
    .min(min, `Should be at least ${min} characters`)
    .typeError(numberValidationMessage)
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const numberFieldSelectValidation = (req = false, min = 3) => {
  const val = Yup.number()
    .min(min, `Should be at least ${min} characters`)
    .typeError(numberValidationMessage)
  if (req) {
    return val.required(numberSelectValidationMessage)
  }
  return val
}

export const phoneNumberFieldValidation = (req = false) => {
  const val = Yup.mixed()
    .test('is-phoneNum', 'Not valid a phone number', value => {
      try {
        const phoneNum = parsePhoneNumber(value, 'AU')
        return phoneNum.isValid()
      } catch (e) {
        //console.warn('libPhone error:' + e)
      }
    })
    .typeError(numberValidationMessage)
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const dateFieldValidation = (req = false, min = 10) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .matches(/^(\d\d\d\d-\d\d-\d\d)/, 'Should start with a letter')
    .test('is-18yrsOld', 'Only allow dates greater than 18 years old', value => {
      const today = new Date()
      const dob = new Date(value)
      const diffInYear = new Date(today - dob).getUTCFullYear() - new Date(0).getUTCFullYear()
      //console.log('DoB-diffInYear: ' + diffInYear)
      return diffInYear >= 18
    })
    .typeError('Date less than 18 years old')

  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const twoFaFieldValidation = (req = false, min = 6) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .matches(/^(\d\d\d\d\d\d)/, 'Should be 6 digits code')

  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const newPasswordFieldValidation = (req = false) => {
  // const val = Yup.string()
  //   .required(requiredFieldValidationMessage)
  //   .min(8, 'Password must be 8 characters or longer')
  //   .matches(
  //     /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{8,})/,
  //     'Password must include at least 1 uppercase, lowercase, and numeric character'
  //   )
  const val = Yup.string()
    .required(requiredFieldValidationMessage)
    .min(8, 'Password must be 8 characters or longer')
    .test('testUpperCase', 'Password must be 8 characters or longer', function(value) {
      return value?.length >= 8
    })
    .test('testUpperCase', 'Password must include at least 1 uppercase character', function(value) {
      return /^((?=.*[A-Z]))(?=.{8,})/.test(value)
    })
    .test('testPassword', 'Password must include at least 1 lowercase character', function(value) {
      return /^((?=.*[a-z]))(?=.{8,})/.test(value)
    })
    .test('testPassword', 'Password must include at least 1 numeric character', function(value) {
      return /^((?=.*[0-9]))(?=.{8,})/.test(value)
    })
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const requiredBoolFieldValidation = msg => {
  return Yup.bool()
    .oneOf([true], msg)
    .required('Required')
}

export const businessDetailValidation = (req = false, min = 20) => {
  const val = Yup.string()
    .min(min, `Should be at least ${min} characters`)
    .matches(/^([0-9]{11}.*)/)
    .required('Required')
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const userBanksFeedErrLimitCnt = 1

// regex pattern for matching address
const startPattern = ['^.*\\s+[a-z\\s*]+.*\\s+']
const streetPattern = [
  '(Alley |Arcade |Avenue |Boulevard |Bypass |', // try match the road
  'Circuit |Close |Corner |Court |Crescent |Cross |Cul-de-sac |',
  'Drive |Esplanade |Green |Grove |Highway |Junction |',
  'Lane |Link |Loop |Mews |Parade |Promenade |Place |Ridge |Ride |Road |Rise |Square |',
  'Street |Terrace |Way |',
  'Ally |Arc |Ave |Bvd |Bypa |Cct |Cl |Crn |Ct |Cres |Cds |',
  'Dr |Esp |Grn |Gr |Hwy |Ln |Lp |Jnc |Pde |Pl |Rdge |Rd |Sq |St |Tce |Turn )',
]
const cityPattern = ['[a-z]+\\s*[a-z]*(\\s|,)?\\s*']
const statePattern = [
  '(WA|QLD|NSW|SA|VIC|TAS|ACT|NT|', // State
  'Western Australia|Queensland|New South Wales|South Australia|Victoria|',
  'Tasmania|Australian Capital Territory|Northern Territory)',
]
const postalPattern = ['\\d{4}(\\s|,)?\\s*']
const spaceOrComma = ['(\\s|,)?\\s*']
const completeAddressPattern = new RegExp(
  [
    ...startPattern,
    ...streetPattern,
    ...spaceOrComma,
    ...cityPattern,
    ...statePattern,
    ...spaceOrComma,
    ...postalPattern, // postal code
  ].join(''),
  'gim'
)

export const isValidateAuAddress = addrString => {
  return completeAddressPattern.test(addrString)
}

export const parseAuAddress = addrString => {
  const mStreetPattern = new RegExp([...streetPattern].join(''), 'gim')
  const mStatePattern = new RegExp(['^', ...statePattern, '$'].join(''), 'gim')
  const mPostalPattern = new RegExp([...postalPattern].join(''), 'gim')
  //console.log('addrString' + addrString)
  const addrStringNoComma = addrString?.replace(/,\s*/g, ' ')
  const tokens = addrStringNoComma?.split(' ')
  //const tokens = addrString.split(' ')
  //console.log('split result :' + JSON.stringify(tokens))
  const addrObj = {
    street: '',
    city: '',
    state: '',
    postal: '',
  }
  if (tokens === undefined) return addrObj
  var i = 0

  for (i = 0; i < tokens.length; i++) {
    if (mStreetPattern.test(tokens[i] + ' ')) {
      addrObj.street += ' ' + tokens[i]
      break
    }
    addrObj.street += ' ' + tokens[i]
  }
  addrObj.street = addrObj.street.replace(/^[\s,]+|[\s,]+$/gm, '')
  i += 1
  for (; i < tokens.length; i++) {
    const t = tokens[i]
    if (t.toUpperCase() === 'NORTHERN' || t.toUpperCase() === 'SOUTH' || t.toUpperCase() === 'WESTERN') {
      i++
      const t2 = tokens[i]
      const TT = (t + ' ' + t2).trim(',')
      if (mStatePattern.test(TT)) {
        addrObj.state = TT
        break
      } else {
        i--
        addrObj.city += t
        addrObj.city += ' '
      }
    } else if (t.toUpperCase() === 'NEW' || t.toUpperCase() === 'AUSTRALIAN') {
      i++
      const t2 = tokens[i]
      i++
      const t3 = tokens[i]
      const TT = t + ' ' + t2 + ' ' + t3
      if (mStatePattern.test(TT)) {
        addrObj.state = TT
        break
      } else {
        i--
        i--
        addrObj.city += t
        addrObj.city += ' '
      }
    } else {
      if (mStatePattern.test(t)) {
        addrObj.state = t
        break
      } else {
        addrObj.city += t
        addrObj.city += ' '
      }
    }
  }
  addrObj.city = addrObj.city.replace(/^[\s,]+|[\s,]+$/gm, '')

  for (; i < tokens.length; i++) {
    if (mPostalPattern.test(tokens[i])) {
      addrObj.postal = tokens[i].replace(/,/g, '')
      break
    }
  }
  return addrObj
}

export const numberFieldValidationRange = (req = false, min = 1, max = 1000) => {
  const val = Yup.number()
    .min(min, `Please enter value from ${min} to ${max}`)
    .max(max, `Please enter value from ${min} to ${max}`)
    .typeError(numberValidationMessage)
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}

export const driverLicenseFieldValidation = (req = false, min = 6, max = 11) => {
  const val = Yup.string()
    .trim('Driver License number cannot include leading and trailing spaces')
    .matches(/^([a-zA-Z0-9]{6}.*)/, 'Invalid Driver License Number')
    .min(min, `Should be at least ${min} characters`)
    .max(max, `Should be less than ${max} characters`)
  if (req) {
    return val.required(requiredFieldValidationMessage)
  }
  return val
}
