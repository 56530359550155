import * as React from 'react'
import classnames from 'classnames'

import cls from './index.module.scss'
import { useBreakpoint } from '@hooks'

const UikNavLink = ({
  rightEl,
  children,
  className,
  highlighted,
  icon,
  Component,
  isActive,
  isExpanded,
  ...rest
}: UikNavLinkProps) => {
  const breakpoint = useBreakpoint()
  return (
    <Component
      className={classnames(
        breakpoint > 1
          ? cls.wrapper
          : breakpoint === 1
          ? cls.wrapperS
          : breakpoint === 0
          ? cls.wrapperXS
          : cls.wrapper,
        className,
        {
          [cls.highlighted]: highlighted,
          [cls.active]: isActive,
          [cls.expanded]: isExpanded,
        }
      )}
      {...rest}
    >
      <div className={cls.text}>
        {icon && <span className={cls.icon}>{icon}</span>}
        <div className={cls.children}>{children}</div>
      </div>
      {rightEl && <span className={cls.rightEl}>{rightEl}</span>}
    </Component>
  )
}

UikNavLink.defaultProps = {
  className: null,
  rightEl: null,
  highlighted: false,
  icon: null,
  children: null,
  Component: 'a',
}

export default UikNavLink
