import { createSlice } from '@redux';
import { debounce } from 'lodash';
import { setCustomerId } from '@features/user';

import { auth } from '@api';
import { addAlert } from '@features/alerts';

export const initialState = {
  success: null,
  error: null,
  errorField: null,
  loginExists: false,
  twoFaEnabled: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      state.success = payload;
    },
    loginFailure: (state, { payload }) => {
      return {
        ...initialState,
        success: false,
        ...payload,
      };
    },
    setLoginExists: (state, { payload }) => {
      state.loginExists = payload;
    },
    set2FaEnabled: (state, { payload }) => {
      return {
        ...state,
        twoFaEnabled: payload,
      };
    },
  },
});

export default loginSlice.reducer;
export const { setLoginExists, set2FaEnabled } = loginSlice.actions;

const loginSuccess = (res) => async (dispatch) => {
  const { accessToken, refreshToken, customerId } = res.data;
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);

  if (customerId) {
    localStorage.setItem('customerId', customerId);
    await dispatch(setCustomerId(customerId));
  }

  dispatch(loginSlice.actions.loginSuccess(res.data));

  return {
    success: true,
    accessToken,
  };
};

const loginFailure = (error) => (dispatch) => {
  if (!error.response.data || !error.response.data.fieldError) {
    const payload = {
      error: 'Something went wrong, please try again',
    };

    dispatch(loginSlice.actions.loginFailure(payload));
    return payload;
  }

  const payload = {
    error: error.response.data.fieldError[1],
    errorField: error.response.data.fieldError[0],
  };

  dispatch(loginSlice.actions.loginFailure(payload));
  return payload;
};

export const login = (email, password, code) => async (dispatch) => {
  try {
    const res = await auth.post('/login', { login: email, password, code });
    return dispatch(loginSuccess(res));
  } catch (error) {
    console.error(error?.response);
    const need2fa =
      error?.response?.data?.error ===
      'Valid request but require 2FA to proceed';
    if (need2fa) {
      dispatch(
        addAlert({
          message: 'SMS authentication code sent.',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      );
      return {
        success: true,
        need2fa: true,
      };
    } else {
      return dispatch(loginFailure(error));
    }
  }
};

const debouncedCheckLogin = debounce(async (dispatch, email) => {
  try {
    //const parm = `?login=${encodeURIComponent(email)}`
    // const res = await auth.get(`/login-check${parm}`)
    // if (res.status === 200) {
    //   return dispatch(setLoginExists(res.data.exist))
    // }
    // console.log('legacy login check removed!')
  } catch (error) {
    console.error(error);
  }

  return dispatch(setLoginExists(null));
}, 1000);

export const checkLogin = (email) => async (dispatch) =>
  debouncedCheckLogin(dispatch, email);
