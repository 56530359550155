import React, { useState, useEffect } from 'react'
import { useRouter } from '@hooks'
import { useSelector } from '@redux'
import cls from './index.module.scss'

const env = process.env.REACT_APP_API_STAGE || process.env.NODE_ENV

const ZipLandingPage = ({ show }) => {
  const [zmId, setZmId] = useState(
    env === 'production' ? 'a26a4f69-6f7f-48ec-84a4-03c98dca9fe9' : '04f5a8f7-fd70-4de9-b473-a56eba319065'
  )
  //  const zDataEnv = env !== 'production' ? '{sandbox}' : '{production}'
  const zDataEnv = env !== 'production' ? 'sandbox' : 'production'
  const {
    params: { oid },
  } = useRouter()
  const { business } = useSelector(({ payments }) => payments[oid] || {})
  const zipAbilities = business?.tenderTypesAbilities?.filter(ability => ability?.name === 'Zip')

  useEffect(() => {
    if (zipAbilities?.length > 0) {
      if (zipAbilities[0]?.paymentProviders?.length > 0) {
        const zId = zipAbilities[0]?.paymentProviders[0].zipmoneyPublicKey
        //setZmId(`{${zId}}`)
        setZmId(zId)
      }
    }
  }, [zipAbilities])

  return (
    <div className={show ? cls.zipLandPageContainer : cls.zipLandPageContainerHide}>
      {/* <div data-zm-merchant="{04f5a8f7-fd70-4de9-b473-a56eba319065}" data-env="{sandbox}"></div> */}
      <div data-zm-merchant={zmId} data-env={zDataEnv}></div>
      <div zm-asset="landingpage" zm-widget="inline"></div>
    </div>
  )
}

export default ZipLandingPage
