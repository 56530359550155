import api from '@api'
import { addAlert } from '@features/alerts'

const altPaymentPreparation = values => async (dispatch, getState) => {
  const { tenderTypesAbilityId, businessId, orderId, total, stripePaymentMethodType } = values

  const res = await api
    .post(`/v1/businesses/${businessId}/tender_types_abilities/${tenderTypesAbilityId}/preparations`, {
      tenderTypesAbilityId,
      businessId,
      orderId,
      total,
      stripePaymentMethodType,
    })
    .catch(err => {
      console.error(err)
      return dispatch(
        addAlert({
          message: 'Prepare payment request failed',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    })

  return res.data
}
export default altPaymentPreparation
