import React from 'react'
import cls from './index.module.scss'

const PropellInputTextArea = props => {
  const { label, type, placeholder, errorMessage, touched, fields, disabledErrMsg, ...rest } = props
  return (
    <div className={!errorMessage ? cls.inputBox : cls.inputBoxErrBorder}>
      <div className={cls.inputBoxLabel}>{label}</div>
      <div className={cls.container}>
        {/* <input type={type} placeholder={placeholder} {...fields} {...rest} /> */}
        <textarea
          type={type}
          placeholder={placeholder}
          {...fields}
          {...rest}
          rows="5"
          className={cls.textarea}
          style={{ height: '5rem', padding: '1rem 0.5rem', margin: '4px 0' }}
        />
      </div>
      {errorMessage && !disabledErrMsg && <p className={cls.errorMessage}>{errorMessage}</p>}
    </div>
  )
}

export default PropellInputTextArea
