import axios from 'axios'
import { api } from '.'
// import the redux store to dispatch actions from here
import { store } from '../index.js'
import { addAlert } from '@features/alerts'

let isAlreadyFetchingAccessToken = false

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = []

const addSubscriber = callback => {
  subscribers.push(callback)
}

const onAccessTokenFetched = token => {
  // When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(token))
  subscribers = []
}

const resetTokenAndReattemptRequest = async error => {
  try {
    const { response: errorResponse } = error
    const refreshToken = localStorage.getItem('refreshToken')

    if (!refreshToken) {
      return Promise.reject(error)
    }

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + token
        resolve(axios(errorResponse.config))
      })
    })

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true

      const response = await api.post(`/v1/user/jwt-refresh`, {
        refreshToken,
      })

      if (!response.data) {
        return Promise.reject(error)
      }

      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data

      localStorage.setItem('accessToken', newAccessToken)
      localStorage.setItem('refreshToken', newRefreshToken)

      isAlreadyFetchingAccessToken = false
      onAccessTokenFetched(newAccessToken)
    }

    return retryOriginalRequest
  } catch (error) {
    //console.log('resetTokenAndReattemptRequest 2A ' + JSON.stringify(error?.response?.data?.error))
    if (error?.response?.data?.error === 'Invalid refresh token') {
      isAlreadyFetchingAccessToken = false
      localStorage.setItem('oldPath', window.location.pathname)
      window.location.pathname = '/login'
    }
    return Promise.reject(error)
  }
}

export const handleResponseError = error => {
  const { dispatch } = store

  if (!error?.response?.data?.error) {
    return Promise.reject(error)
  }

  if (error.response.status === 401) {
    if (
      error.response.data.error === 'Unauthorized. Invalid or expired token. Code: NCU' ||
      error.response.data.error === 'Unauthorized. You do not have the required permission. Code: BUZ'
    ) {
      return resetTokenAndReattemptRequest(error)
    }
    // show alert
    dispatch(
      addAlert({
        message: error.response.data.error,
        error: true,
        top: true,
        dismiss: true,
      })
    )
  }

  return Promise.reject(error)
}
