export const connectionsToAccountAdapter = connections => {
  const conns = [...connections]
  const accounts = []
  conns.forEach(conn => {
    const institution = conn.institution
    const bankAccounts = conn.bankAccounts
    bankAccounts.forEach(acc => {
      const accObj = {
        bank: institution,
        name: `${acc.isPrimaryTransactionAccount ? 'PRIMARY ACCOUNT' : acc.accountType}`,
        number: acc.accountNumber,
        isPrimary: acc.isPrimaryTransactionAccount,
      }
      accounts.push(accObj)
    })
  })
  const sorted = accounts.sort((a, b) => {
    if (a.isPrimary && !b.isPrimary) return -1
    else return 0
  })
  return sorted
}
