// @flow
import * as React from 'react'
import classnames from 'classnames'

import UikContentTitle from '../UikContentTitle'

import cls from './index.module.scss'
import Uikon from '../Uikon'
import { Flex } from '@chakra-ui/react'
class Input extends React.PureComponent<UikInputProps> {
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
    icon: null,
    clear: false,
    iconPosition: 'left',
    errorMessage: null,
    forwardRef: null,
    inputPatternRegex: null,
  }

  // setRef = node => {
  //   this.input = node
  // }

  setValue = value => {
    this.input.value = value
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      icon,
      label,
      focus, // eslint-disable-line
      clear,
      iconPosition,
      errorMessage,
      forwardRef,
      inputPatternRegex,
      disabledErrMsg,
      customStyle,
      touched,
      type,
      forceTick,
      hideTick,
      // size
      ...rest
    } = this.props

    //console.log(' this.props' + JSON.stringify(this.props))
    return (
      <div
        {...wrapperProps}
        className={classnames(wrapperProps.className, cls.formGroup, {
          [cls.clear]: clear,
        })}
      >
        {label ? (
          customStyle === 1 ? (
            <div className={cls.altStyle}>{label}</div>
          ) : customStyle === 2 ? (
            <div className={cls.altStyle2}>{label}</div>
          ) : customStyle === 3 ? (
            <div className={cls.altStyle3}>{label}</div>
          ) : (
            <UikContentTitle>{label}</UikContentTitle>
          )
        ) : null}
        <div className={classnames(cls.inputWrapper)}>
          {icon && (
            <span
              className={classnames(cls.iconWrapper, {
                [cls.iconWrapperRight]: iconPosition === 'right',
              })}
            >
              <Flex h="100%" align="center" justify="center">
                {icon}
              </Flex>
            </span>
          )}
          <div className={cls.relativeWrapper}>
            <input
              ref={forwardRef}
              className={classnames(className, cls.input, {
                [cls.errorHighlight]: errorMessage,
              })}
              name={name}
              {...rest}
              type={type ? type : 'text'}
              onKeyPress={e => {
                if (inputPatternRegex) {
                  if (inputPatternRegex.test(e.key) === false) e.preventDefault()
                }
              }}
              style={
                ((customStyle === 2 || customStyle === 3) && errorMessage === null && touched === true) ||
                forceTick
                  ? { outline: '2px solid #66DAD8', paddingLeft: icon ? '60px' : '10px', background: 'white' }
                  : { outline: null, paddingLeft: icon ? '60px' : '10px', background: 'white' }
              }
            />
            {/* {(altStyle2 && errorMessage === null && touched === true) || forceTick ? ( */}
            {((customStyle === 2 || customStyle === 3) && errorMessage === null && touched === true) ||
            forceTick ? (
              !hideTick ? (
                <div className={cls.absoluteWrapper}>
                  <Uikon className={cls.validationIcon}>check</Uikon>
                </div>
              ) : null
            ) : null}
          </div>
        </div>
        {errorMessage && !disabledErrMsg && <p className={cls.errorMessage}>{errorMessage}</p>}
        {customStyle === 0 ? <div style={{ height: '6px' }} /> : null}
      </div>
    )
  }
}

export default Input
