export { default as mainLogo } from './assets/main-logo.svg'
export { default as mainLogoCoal900 } from './assets/main-logo-coal900.svg'

export const tenentId = 'eab2ed4c-ee92-42c8-812c-60490f4c46ad'
export const tenantName = 'propell'
export const brandName = 'Propell'
export const contactPhone = '1300 804 091'
export const contactEmail = 'support@propellme.com'
export const ABNLookupGUID = '29e297c6-3260-4000-bbd4-f57add801965'

export const abnProxyUrl = 'https://117srtsaa7.execute-api.ap-southeast-2.amazonaws.com/production/'

export const pages = {
  insights: '/pulse',
  payments: '/payments',
  lending: '/lending',
  lending0: '/lending0',
  lendingPropellTrade: '/lendingPropellTrade',
  accounts: '/accounts',
  trade: '/trade',
  //  apply: '/apply',
}

export const propellColor = {
  // charcoal grey
  charcoal: '#40474f',
  charcoal900: '#2e3238',
  charcoal800: '$charcoal',
  charcoal700: '#505a65',
  charcoal600: '#606d7b',
  charcoal500: '#6d7c8c',
  charcoal400: '#838f9d',
  charcoal300: '#9aa3ae',
  charcoal200: '#b8bdc4',
  charcoal100: '#d5d7da',
  charcoal50: '#efefef',

  // grey warm
  greywarm: '#4b4a48',
  greywarm900: '#292826',
  greywarm800: '#4b4a48',
  greywarm700: '#6b6967',
  greywarm600: '#7f7c7e',
  greywarm500: '#a9a8a5',
  greywarm400: '#c7c6c3',
  greywarm300: '#e9e8e5',
  greywarm200: '#f3f1ef',
  greywarm100: '#f8f6f4',
  greywarm50: '#fafafa',

  fontColor: '#3a4c7d',
  special0: '#2f333c',
  special1: '#dde2ef',
  special2: '#b8b9bb',
  special3: '#ececed',
  lightGrey: '#c0c0c0',
  lighterSolmon: '#ffd4ae',
  overDueStateRed: '#ff0000',
  mselectBorder0: '#473cb0',
  mselectBorder1: '#eaedf3',
  pureRed: '#ff0000',
}

export const originationPage = '/apply'
export const supportLink = 'https://propell.zendesk.com/hc/en-us'
