import { tenentId } from '@theme'

export const handleRequestSuccess = config => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    config.headers['Authorization'] = 'Bearer ' + accessToken
    config.headers['Tenant-Id'] = tenentId
  }
  return config
}

