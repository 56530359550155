import { useSelector, useAction } from '@redux'
import { useEffect, useState, useRef } from 'react'
import { getBankConnections } from '@features/bankFeeds'

/* 
  Hook - for fetch bank connection from api 
  connections     : bank connection array return from illion
  startFetch      : set fetching state to trigger API request
  fetching        : state variable which will trigger API request
  fetchCompleted  : state initicate fetching API return successfully
*/
const useBankConnections = bid => {
  const connections = useSelector(({ bankFeeds }) => bankFeeds.connections)
  const [fetching, startFetch] = useState(false)
  const [fetchCompleted, setCompleted] = useState(false)
  const [primaryAccounts, setPrimaryAccounts] = useState([])
  const getBankConnectionsAction = useAction(getBankConnections)
  const fetchConnectionCnt = useRef(0)
  const [primaryBankIsMfa, setPrimaryBankIsMfa] = useState(false)

  const fetchConnections = async bid => {
    if (bid !== null) {
      try {
        fetchConnectionCnt.current = fetchConnectionCnt.current + 1
        setCompleted(false)
        await getBankConnectionsAction(bid)
      } catch (e) {
        // error retry
        if (fetchConnectionCnt.current < 5) {
          setTimeout(() => {
            fetchConnections()
          }, 3000)
        }
      }
    }
  }

  useEffect(() => {
    // console.log('0 fetching :' + fetching + ' completed:' + fetchCompleted)
    if (fetching === true) {
      fetchConnections(bid)
    }
    // eslint-disable-next-line
  }, [fetching, bid])

  useEffect(() => {
    if (fetching === true && fetchCompleted === false) {
      startFetch(false)
      setCompleted(true)
      // console.log('connections:[' + JSON.stringify(connections) + ']')
      // console.log('1 fetching :' + fetching + ' completed:' + fetchCompleted)
    }
    if (connections) {
      if (connections?.length > 0) {
        // construct Bank Account List
        const lists = connections.map(bankConnection => {
          const { status, institution, bankAccounts, id } = bankConnection
          return bankAccounts.map(account => {
            return {
              conn: id,
              accountId: account.id,
              primary: account.isPrimaryTransactionAccount,
              status: status,
              bankName: institution,
              bsb: account.bsb,
              accountType: account.accountType,
              accountNumber: account.accountNumber,
              label: `${institution.toUpperCase()} - ${account.accountType} ${account.accountNumber}`,
              id,
            }
          })
        })
        const pAccounts = [].concat(...lists).filter(bank => bank.primary)
        setPrimaryAccounts([].concat(...pAccounts))
        const primaryConn = connections.filter(c => {
          if (pAccounts?.length > 0) return c.id === pAccounts[0].conn
          else return false
        })
        if (primaryConn?.length > 0) {
          setPrimaryBankIsMfa(primaryConn[0]?.hasMfa)
        }
      }
    }

    // eslint-disable-next-line
  }, [connections])

  return { connections, startFetch, fetching, fetchCompleted, primaryAccounts, primaryBankIsMfa }
}
export default useBankConnections
