import React, { useEffect, useState } from 'react'
import { Loading } from '@components'
import cls from './index.module.scss'
import { Flex } from '@chakra-ui/react'

const BankFeedIframe = props => {
  const [isOnline, setIsOnline] = useState(false)
  const [iframe, setIframe] = useState()
  useEffect(() => {
    //console.log('iframe mount :' + iframe)
    return () => {
      //console.log('iframe unmount')
      setIframe()
      setIsOnline(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.path?.length > 0) {
      //console.log('props.path :' + props.path)
      setIframe(props.path)
      setTimeout(() => {
        setIsOnline(true)
      }, 3000)
    }
  }, [props.path])

  return (
    <div style={{ marginTop: '1rem' }}>
      <iframe
        style={{ display: isOnline ? 'block' : 'none' }}
        width="100%"
        //height="680px"
        height={props?.iframeHeight ? `${props?.iframeHeight}px` : '680px'}
        frameBorder="no"
        src={iframe}
        title="bankFeed"
        loading="lazy"
      />
      {isOnline ? null : props.enable ? (
        <Flex className={cls.loadingUI}>
          <Loading />
          <div className={cls.text}>Please wait while we securely connect to your bank.</div>
        </Flex>
      ) : null}
    </div>
  )
}

export default BankFeedIframe
