import api from '@api'
import { addAlert } from '@features/alerts'
import { updateLoan } from './index.js'

const result = values => async (dispatch, getState) => {
  const res = await api
    .put(`/v1/lendings/loans/${values.id}`, values)
    .then(result => {
      dispatch(
        addAlert({
          message: 'Add Loan label',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      const myObj = {
        ...getState().lending.loans.filter(loan => loan.id === values.id)[0],
        customizedLoanName: values.customizedLoanName,
      }
      dispatch(updateLoan(myObj))
      return result.data
    })
    .catch(err => {
      console.error(err.response.data.error)
      api.post(`/v1/crashes?data=${err.response.data.error}`)
      dispatch(
        addAlert({
          message: err.response.data.error,
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      return err.response
    })

  return res.data
}
export default result
