import api from '@api'
import { addAlert } from '@features/alerts'

// import { setFetchOrdersState } from '@features/uiStateHelper'
import { setPropellTrade } from './index.js'
const pageSize = 8
export const getOrdersData = () => async (dispatch, getState) => {
  const { business } = getState()
  var page = 1
  var doneFetching = false
  const cases = []
  try {
    while (!doneFetching) {
      const res = await api.get(`/v1/businesses/${business?.id}/cases?page=${page}&per_page=${pageSize}`)
      if (res.data.length > 0) cases.push(...res.data)
      if (res.data.length < pageSize) doneFetching = true
      page++
    }
    //const filteredCases = cases.filter(c => c.recordType !== 'General Enquiry')
    const filteredCases = cases
    dispatch(setPropellTrade(filteredCases))
    //dispatch(setOrders(mockData))
  } catch (error) {
    console.error(error)
  }
}

export const rejectOrdersCase = ({ id }) => async (dispatch, getState) => {
  const { business } = getState()
  try {
    const res = await api.put(`/v1/businesses/${business?.id}/cases/${id}/trade-reject`, {
      id,
      businessId: business?.id,
    })
    dispatch(
      addAlert({
        message: 'Reject payment request sent',
        success: true,
        dismiss: true,
        top: true,
        dismissTimeout: 10 * 1000,
      })
    )
    return { status: res.status, message: res.data }
  } catch (error) {
    console.error(error)
    dispatch(
      addAlert({
        message: error.response.data.error,
        error: true,
        dismiss: true,
        top: true,
        dismissTimeout: 10 * 1000,
      })
    )
    return { status: error.response.status, message: error.response.data.error }
  }
}
