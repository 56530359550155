import classnames from 'classnames'
import * as React from 'react'

import UikAvatar from '../UikAvatar'

import cls from './social-friend-item.module.scss'

const UikSocialFriendList = ({
  className,
  Component,
  children,
  data: { name, imgUrl, textBottom },
  ...rest
}: UikSocialFriendListProps) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    <UikAvatar highlighted imgUrl={imgUrl} name={name} size="large" textBottom={textBottom} />
  </Component>
)

UikSocialFriendList.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default UikSocialFriendList
