import { addAlert } from '@features/alerts'
import api from '@api'

const postPreContractDisclosureQuestionnaire = values => async (dispatch, getState) => {
  var rc = null
  await api
    .post(`/v1/lendings/loans/pre-contracts`, {
      ...values,
    })
    .then(result => {
      dispatch(
        addAlert({
          message: 'Loan pre-contracts request sent',
          success: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      rc = result
    })
    .catch(err => {
      console.error(err)
      dispatch(
        addAlert({
          message: 'Loan pre-contracts request sent failed',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      rc = -1
    })
  return rc
}
export default postPreContractDisclosureQuestionnaire
