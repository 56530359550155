const normalizeInput2MobileNumber = newValue => {
  const stripped = newValue.replaceAll('-', '').replaceAll(' ', '')
  if (stripped?.length <= 10) {
    const parts = [stripped.slice(0, 4), stripped.slice(4, 7), stripped.slice(7, 10)]
    var normalizedMobile = ''
    parts.forEach((s, idx) => {
      if (s?.length === 4 && idx === 0) normalizedMobile = normalizedMobile.concat(s + ' - ')
      else if (s?.length === 3 && idx === 1) normalizedMobile = normalizedMobile.concat(s + ' - ')
      else normalizedMobile = normalizedMobile.concat(s)
    })
    const isBackspace = /.*\d\s-$/.test(newValue)
    if (isBackspace) {
      normalizedMobile = normalizedMobile.slice(0, normalizedMobile?.length - 3)
    }
    return normalizedMobile
  } else {
    return newValue.slice(0, 16)
  }
}
export default normalizeInput2MobileNumber
