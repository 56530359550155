import api from '@api'
import { setInsightsDepositData } from './index.js'

export const getInsightsBankWklyDepositData = bid => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/businesses/${bid}/bank_feeds/bank_connections/weekly_adb_deposits`)
    await dispatch(setInsightsDepositData(res.data))
    //return res.data
  } catch (error) {
    console.error(error)
  }
}

export default getInsightsBankWklyDepositData
