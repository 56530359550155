import { theme } from '@chakra-ui/react'
import variables from './vars.module.scss'

// for some reason in imports from scss don't work in testing env - fake it
const breakpoints =
  process.env.NODE_ENV === 'test'
    ? ['1px', '1px', '1px', '1px']
    : [variables.tinyWidth, variables.smallWidth, variables.mediumWidth, variables.largeWidth]

export const [tiny, small, medium, large] = breakpoints

const customTheme = {
  ...theme,
  breakpoints: [variables.tinyWidth, variables.smallWidth, variables.mediumWidth, variables.largeWidth],
  fonts: {
    body: variables.fontFamily,
    heading: variables.fontFamily,
    mono: 'Menlo, monospace',
    secondary: variables.fontFamilySecondary,
  },

  colors: {
    ...theme.colors,
    primary: {
      900: variables.primary900,
      800: variables.primary800,
      700: variables.primary700,
      600: variables.primary600,
      500: variables.primary500,
      400: variables.primary400,
      300: variables.primary300,
      200: variables.primary200,
      100: variables.primary100,
    },
    green: {
      900: variables.green900,
      800: variables.green800,
      700: variables.green700,
      600: variables.green600,
      500: variables.green500,
      400: variables.green400,
      300: variables.green300,
      200: variables.green200,
      100: variables.green100,
    },
    red: {
      900: variables.red900,
      800: variables.red800,
      700: variables.red700,
      600: variables.red600,
      500: variables.red500,
      400: variables.red400,
      300: variables.red300,
      200: variables.red200,
      100: variables.red100,
    },
    plum: {
      900: variables.plum900,
      800: variables.plum800,
      700: variables.plum700,
      600: variables.plum600,
      500: variables.plum500,
      400: variables.plum400,
      300: variables.plum300,
      200: variables.plum200,
      100: variables.plum100,
    },
    grey: {
      900: variables.grey900,
      800: variables.grey800,
      700: variables.grey700,
      600: variables.grey600,
      500: variables.grey500,
      400: variables.grey400,
      300: variables.grey300,
      200: variables.grey200,
      100: variables.grey100,
    },
    lightGreen: variables.lightGreen,
    fontColor: variables.fontColor,
    fontColorLight: variables.fontColorLight,
    fontColorLighter: variables.fontColorLighter,
    outstandingOrange: variables.outstandingOrange,
    zipNavy: '#21285e',
  },
}

export default customTheme
