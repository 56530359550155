import React from 'react'
import { useEffect, useState } from 'react'
import { sleep } from '@lib'
//import cls from './index.module.scss'

const SvgArcGauge = ({ percentage, score, label, customArcColor }) => {
  const mScore = score > 750 ? 750 : score < 450 ? 450 : score
  const rate = percentage ? percentage : (mScore - 450) / (750 - 450)
  const boxWidth = 150
  const boxHeight = (boxWidth / 2) * 1.25
  const arcStartX = boxWidth * 0.15
  const arcStartY = boxHeight - boxHeight * 0.15
  const arcEndX = boxWidth - arcStartX
  const arcEndY = arcStartY
  const radius = (arcEndX - arcStartX) / 2
  const arcFullLength = radius * Math.PI
  const arcLen = (1 - rate) * arcFullLength
  const fontSize = 26
  const textX = boxWidth / 2
  const textY = arcStartY - 3
  const strokeWidth = 17
  const arcColor = customArcColor
    ? customArcColor
    : score < 550
    ? 'red'
    : score < 650
    ? 'lightsalmon'
    : '#4FCFB8'
  //const [mArcLen, setMArcLen] = useState(arcLen)
  // const [mRate, setMRate] = useState(0)

  // useEffect(() => {
  //   setTimeout(async () => {
  //     if (mRate !== rate) {
  //       var newRate = mRate + 0.08
  //       if (newRate >= rate) newRate = rate
  //       setMRate(newRate)
  //       setMArcLen((1 - newRate) * arcFullLength)
  //     }
  //   }, 100)
  //   // eslint-disable-next-line
  // }, [])

  // console.log('arcFullLength =' + arcFullLength)
  // console.log('arcLen =' + arcLen)
  // console.log('score =' + score)
  // console.log('numberOfDigit =' + numberOfDigit)
  const circleTime = (2 * 1) / percentage
  const [ready, setReady] = useState(false)
  useEffect(() => {
    //console.log('svg cmp onload' + percentage)
  }, [])
  useEffect(() => {
    ;(async () => {
      if (percentage) {
        //console.log('svg percentage' + percentage)
        await sleep(600)
        setReady(true)
      }
    })()
  }, [percentage])
  return ready ? (
    <svg width="100%" height="100%" viewBox={`0 0 ${boxWidth} ${boxHeight}`}>
      <defs>
        <filter id="shadow" colorInterpolationFilters="sRGB" x="-0.3" y="-0.3" width="150%" height="150%">
          <feDropShadow dx="0.1" dy="0.1" stdDeviation="3" floodOpacity="0.3" />
        </filter>
      </defs>
      <path
        d={`M${arcStartX} ${arcStartY} A50 50 0 0 1 ${arcEndX} ${arcEndY}`}
        stroke="#f0f0f0"
        strokeWidth={`${strokeWidth}px`}
        fill="none"
        filter="url(#shadow)"
        //filter="drop-shadow(0 0 9px #BDBDBD)"
      ></path>
      <path
        d={`M${arcStartX} ${arcStartY} A50 50 0 0 1 ${arcEndX} ${arcEndY}`}
        stroke={arcColor} //"#4FCFB8"
        strokeWidth={`${strokeWidth}px`}
        fill="none"
        //filter="drop-shadow(0 0 9px #BDBDBD)"
        strokeDasharray={arcFullLength}
        strokeDashoffset={arcLen}
      >
        <animate
          attributeType="XML"
          attributeName="stroke-dashoffset"
          from={arcFullLength}
          to={arcLen}
          dur="2s"
          repeatCount="1"
          calcMode="linear"
        />
        <animate attributeName="stroke" values={`#EF404A;${arcColor}`} dur="2s" repeatCount="1" />
      </path>

      {label ? (
        // <text className={cls.labelText}>{label}</text>
        <text
          x={textX}
          y={textY + 2}
          fill="black"
          textAnchor="middle"
          style={{
            display: 'block',
            // fontSize: `13px`,
            // lineHeight: '15px',
            // fontWeight: '650',
            fontSize: `26px`,
            lineHeight: '32px',
            fontWeight: 'bold',
          }}
        >
          {label}
        </text>
      ) : (
        <text
          x={textX}
          y={textY}
          fill="black"
          textAnchor="middle"
          style={{
            display: 'block',
            fontSize: `${fontSize}`,
            lineHeight: '31.47px',
            fontWeight: '700',
          }}
        >
          {score}
        </text>
      )}
      <circle r={strokeWidth * 0.65} fill="white" filter="url(#shadow)">
        <animateMotion
          dur={`${circleTime}s`}
          repeatCount={percentage}
          path={`M${arcStartX} ${arcStartY} A50 50 0 0 1 ${arcEndX} ${arcEndY}`}
          fill="freeze"
        ></animateMotion>
      </circle>
    </svg>
  ) : (
    <svg width="100%" height="100%" viewBox={`0 0 ${boxWidth} ${boxHeight}`} />
  )
}

export default SvgArcGauge
