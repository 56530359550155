import { useState, useEffect } from 'react'
import { useSelector, useAction } from '@redux'
import { getUserFees, putUserFee, getUserFeeEstimate } from '@features/userFees'

const useCardFees = () => {
  const userFees = useSelector(({ userFees }) => userFees)
  const [cardFees, setCardFees] = useState()
  const fetchUserFees = useAction(getUserFees)
  const updateUserFee = useAction(putUserFee)
  const fetchUserFeeEstimate = useAction(getUserFeeEstimate)

  useEffect(() => {
    if (userFees?.length > 0) {
      var mCardFees = { vmFee: {}, amexFee: {} }
      userFees.forEach(userFee => {
        if (userFee.fee.name === 'American Express') {
          mCardFees = { ...mCardFees, amexFee: userFee }
        } else if (userFee.fee.name === 'Visa Master') {
          mCardFees = { ...mCardFees, vmFee: userFee }
        }
      })
      setCardFees(mCardFees)
    }
  }, [userFees])

  return { fetchUserFees, updateUserFee, fetchUserFeeEstimate, cardFees }
}
export default useCardFees
