import React, { useState, useEffect } from 'react'
import cls from './index.module.scss'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { PropellInput, PropellInputTextArea, ButtonV3A, IconsPropell } from '@components'
import { requiredFieldValidationMessage } from '@theme/strings'
import { capitalize } from 'lodash'
import { mainLogoCoal900 } from '@theme'
import { textFieldAllowSpaceValidation, emailFieldValidation } from '@Apps/Originations/utils'
import { sleep } from '@lib'
import UploadDocs from '../UploadDocs'
import { useSelector, useAction } from '@redux'
import { postBusinessDocUpload } from '@features/business'
import { postEmail } from '@features/utils'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required(requiredFieldValidationMessage)
    .min(2, `Should be at least 2 characters`)
    .matches(/^([a-z]|[A-Z])/, 'Should start with a letter')
    .matches(/^.*\S$/, 'White spaces are not allowed at start or end of thie field'),
  email: emailFieldValidation(true),
  question: textFieldAllowSpaceValidation(true, 2),
})

const Modal = ({ toggleModal, open }) => {
  const [files, setFiles] = useState([])
  const [uiState, setUiState] = useState(-1)
  const email = useSelector(({ user }) => user.email)
  const { firstName, lastName } = useSelector(({ user }) => user)
  const postBusinessDocUploadAction = useAction(postBusinessDocUpload)
  const postEmailAction = useAction(postEmail)

  useEffect(() => {
    if (email?.length > 0) setUiState(0)
  }, [email])
  return (
    <div className={cls.modal} style={{ display: open ? 'flex' : 'none' }}>
      <div className={cls.modalTop}>
        <div className={cls.header}>
          {uiState === 0 ? 'Leave us a message' : uiState === -1 ? 'Contact' : 'Message sent'}
        </div>
        <div
          className={cls.closeBtn}
          onClick={() => {
            toggleModal()
          }}
        >
          -
        </div>
      </div>

      {uiState === 0 ? (
        <div className={cls.modalBottom}>
          <Formik
            initialValues={{
              name: `${firstName} ${lastName}`,
              email: email,
              question: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              const uploadedFile = []
              for (var i = 0; i < files?.length; i++) {
                const file = files[i]
                const res = await postBusinessDocUploadAction({
                  name: file?.path,
                  file: [file],
                  purpose: 'verification doc',
                })
                if (res?.data?.id) {
                  uploadedFile.push(res?.data?.file_url)
                }
              }
              const res = await postEmailAction({
                subject: `Support Ticket from ${values?.name}`,
                body: values.question?.trim(),
                fromEmail: values.email,
                attachmentUrl: uploadedFile?.length > 0 ? uploadedFile[0] : undefined,
              })

              //console.log('res: ' + JSON.stringify(res))
              if (res?.table?.message === 'ok') {
                await sleep(1500)
                resetForm({ name: `${firstName} ${lastName}`, email: email, question: '' })
                setFiles([])
                setUiState(1)
              }
            }}
          >
            {({
              isSubmitting,
              errors: validationErrors,
              touched,
              errors,
              setFieldError,
              values,
              isValid,
              setFieldValue,
            }) => (
              <Form className={cls.form}>
                <div className={cls.formFieldsWrapper}>
                  <Field name="name">
                    {({ field }) => {
                      return (
                        <PropellInput
                          className={cls.inputBox}
                          label="Your Name"
                          name="name"
                          placeholder=""
                          type="text"
                          errorMessage={touched?.name ? capitalize(errors?.name) : null}
                          smallGap={true}
                          {...field}
                        />
                      )
                    }}
                  </Field>

                  <Field name="email">
                    {({ field }) => {
                      return (
                        <PropellInput
                          className={cls.inputBox}
                          label="Email Address"
                          name="email"
                          placeholder=""
                          type="email"
                          errorMessage={touched?.email ? capitalize(errors?.email) : null}
                          smallGap={true}
                          {...field}
                        />
                      )
                    }}
                  </Field>

                  <Field name="question">
                    {({ field }) => {
                      return (
                        <PropellInputTextArea
                          className={cls.inputBox}
                          label="How can we help you?"
                          name="question"
                          placeholder=""
                          type="text"
                          errorMessage={touched?.question ? capitalize(errors?.question) : null}
                          {...field}
                        />
                      )
                    }}
                  </Field>
                  <UploadDocs
                    name={'docs'}
                    label={'Attachments'}
                    setFieldValue={setFieldValue}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>
                <div className={cls.formBtnWrapper}>
                  <img src={mainLogoCoal900} alt={`Propell Logo`} />
                  <ButtonV3A
                    className={cls.sendButton}
                    type="submit"
                    disabled={!isValid}
                    isLoading={isSubmitting}
                    width={'120px'}
                  >
                    Send
                  </ButtonV3A>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      ) : uiState === 1 ? (
        <div className={cls.modalBottom2}>
          <div className={cls.modalBottom2Inner}>
            <div className={cls.modalBottom2Header}>Thanks for reaching out</div>
            <div className={cls.modalBottom2Text}>Someone will get back to you soon</div>
            <div
              className={cls.modalBottom2GoBack}
              onClick={() => {
                toggleModal()
              }}
            >
              Go Back
            </div>
          </div>
        </div>
      ) : (
        <div className={cls.modalBottom2}>
          <div className={cls.modalBottom2Inner}>
            <div className={cls.modalBottom2Header}>
              <IconsPropell name="PropellEmail" />
              &nbsp;&nbsp;
              <div className={cls.emailLink}>
                <a href="mailto:support@propellme.com.au">
                  <strong>support@propellme.com.au</strong>
                </a>
              </div>
            </div>
            <br />
            <div className={cls.modalBottom2Header}>
              <IconsPropell name="PropellPhone" />
              &nbsp;&nbsp;
              <div className={cls.emailLink}>
                <a href="tel://1300804091">1300 804 091</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Modal
