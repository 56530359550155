import classnames from 'classnames'

import * as React from 'react'
import cls from './btn-group.module.scss'

const UikButtonGroup = ({ className, Component, children, ...rest }: UikButtonGroupProps) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    {children}
  </Component>
)

UikButtonGroup.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
}

export default UikButtonGroup
