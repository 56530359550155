import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useAction = (actionCreator, ...boundArgs) => {
  const dispatch = useDispatch()
  return useCallback((...args) => dispatch(actionCreator(...boundArgs, ...args)), [
    actionCreator,
    boundArgs,
    dispatch,
  ])
}

export default useAction
