import { createSlice } from '@redux';
import { debounce } from 'lodash';

//import { auth } from '@api'

export const initialState = {
  // firstName: '',
  // lastName: '',
  // email: '',
  // password: '',
  // confirmPassword: '',
  // TODO there's a ton more fields - add them when it's clear what they are
  emailExists: null,
};

const originationsFormSlice = createSlice({
  name: 'originations/form',
  initialState,
  reducers: {
    // updateFormState: (state, { payload }) => {
    //   console.log('payload:' + JSON.stringify(payload))
    //   return {
    //     ...state,
    //     ...payload,
    //   }
    // },
    setEmailExists: (state, { payload }) => {
      state.emailExists = payload;
    },
    clear: () => {
      return initialState;
    },
  },
});

//export const { updateFormState, setEmailExists } = originationsFormSlice.actions
export const { setEmailExists, clear } = originationsFormSlice.actions;

const debouncedCheckEmail = debounce(async (dispatch, email) => {
  //const parm = `?login=${encodeURIComponent(email)}`
  try {
    // const res = await auth.get(`/login-check${parm}`)
    // if (res.status === 200) {
    //   return dispatch(setEmailExists(res.data.exist))
    // }
    // console.log('legacy login check removed!')
  } catch (error) {
    console.error(error);
  }
  return dispatch(setEmailExists(null));
}, 1000);

export const checkEmail = (email) => async (dispatch) =>
  debouncedCheckEmail(dispatch, email);

export default originationsFormSlice.reducer;
