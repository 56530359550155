import { createSlice } from '@redux'
import api from '@api'
import { addAlert } from '@features/alerts'

export const initialState = []

const slice = createSlice({
  name: 'user_fees',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (payload) {
        return payload
      }
      return state
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions
export default slice.reducer

export const getUserFees = oid => async (dispatch, getState) => {
  try {
    var res
    if (oid) {
      res = await api.get(`/v1/user_fees?order_id=${oid}`)
    } else {
      res = await api.get(`/v1/user_fees`)
    }
    dispatch(set(res.data))
  } catch (error) {
    console.error(error)
  }
}

export const putUserFee = values => async (dispatch, getState) => {
  try {
    const { userFees } = getState()
    const obj = { id: values.id, pass_on_fee_enabled: values.passOnFeeEnabled }
    const res = await api.put(`/v1/user_fees/${values.id}`, obj)
    dispatch(set(userFees.map(userFee => (res.data.id === userFee.id ? res.data : userFee))))
  } catch (error) {
    return dispatch(
      addAlert({ message: `putUserFee setting fail to update`, error: true, dismiss: true, top: true })
    )
  }
}

export const getUserFeeEstimate = (id, amount, oid) => async (dispatch, getState) => {
  try {
    var res
    if (oid) {
      res = await api.get(`/v1/user_fees/${id}/estimate?id=${id}&minor_units=${amount}&order_id=${oid}`)
    } else {
      res = await api.get(`/v1/user_fees/${id}/estimate?id=${id}&minor_units=${amount}`)
    }
    //dispatch(set(res.data))
    //console.log('getUserFeeEstimate' + JSON.stringify(res))
    return res
  } catch (error) {
    console.error(error)
  }
}
