import { useEffect, useState } from 'react'
import { useSelector } from '@redux'

const adaptApiData = dataObj => {
  const keys = Object.keys(dataObj)
  const data = Object.values(dataObj).map(v => parseInt(v))
  const max = Math.max(...data)
  const min = Math.min(...data)
  const sum = [...data].reduce((a, b) => parseInt(a) + parseInt(b), 0)
  const avg = sum / (data?.length || 1) || 0
  const formattedData = data.map((v, id) => {
    return {
      value: v,
      label: keys[id],
      short: keys[id],
      date: keys[id],
      id: id,
    }
  })
  return { keys, data, formattedData, max, min, avg }
}
/* 
  Hook - construst bar char data for wkly bankDeposit and wkly bankBalance
*/
const useBankDepositData = bid => {
  const insights = useSelector(({ insights }) => insights)
  const [depositData, setDepositData] = useState([])
  const [depositMaxValue, setDepositMaxValue] = useState(0)
  const [depositMinValue, setDepositMinValue] = useState(0)
  const [depositAvValue, setDepositAvValue] = useState(0)
  // monthly deposit
  const [mthlyDepositData, setMthlyDepositData] = useState([])
  const [mthlyDepositMaxValue, setMthlyDepositMaxValue] = useState(0)
  const [mthlyDepositMinValue, setMthlyDepositMinValue] = useState(0)
  const [mthlyDepositAvValue, setMthlyDepositAvValue] = useState(0)
  // adb weekly data
  const [wklyAdbData, setWklyAdbData] = useState([])
  const [wklyAdbMax, setWklyAdbMax] = useState(0)
  const [wklyAdbMin, setWklyAdbMin] = useState(0)
  const [wklyAdbAvg, setWklyAdbAvg] = useState(0)

  useEffect(() => {
    if (
      insights?.depositData?.weeklyDeposits &&
      insights?.monthlyDepositData?.monthlyDeposits &&
      insights?.depositData?.weeklyAdb
    ) {
      const wklyDataObj = insights.depositData.weeklyDeposits
      const weeklyDepositObj = adaptApiData(wklyDataObj)
      setDepositData(weeklyDepositObj.formattedData)
      setDepositMaxValue(weeklyDepositObj.max)
      setDepositMinValue(weeklyDepositObj.min)
      setDepositAvValue(weeklyDepositObj.max.avg)

      const mthlyDataObj = insights.monthlyDepositData.monthlyDeposits
      const monthlyDepositObj = adaptApiData(mthlyDataObj)
      setMthlyDepositData(monthlyDepositObj.formattedData)
      setMthlyDepositMaxValue(monthlyDepositObj.max)
      setMthlyDepositMinValue(monthlyDepositObj.min)
      setMthlyDepositAvValue(monthlyDepositObj.max.avg)

      const balanceDataObj = insights.depositData.weeklyAdb
      const weeklyBalanceObj = adaptApiData(balanceDataObj)
      setWklyAdbData(weeklyBalanceObj.formattedData)
      setWklyAdbMax(weeklyBalanceObj.max)
      setWklyAdbMin(weeklyBalanceObj.min)
      setWklyAdbAvg(weeklyBalanceObj.avg)
    }

    // eslint-disable-next-line
  }, [insights])

  return {
    depositData,
    depositMaxValue,
    depositMinValue,
    depositAvValue,

    mthlyDepositData,
    mthlyDepositMaxValue,
    mthlyDepositMinValue,
    mthlyDepositAvValue,

    wklyAdbData,
    wklyAdbMax,
    wklyAdbMin,
    wklyAdbAvg,
  }
}
export default useBankDepositData
