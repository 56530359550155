import React from 'react'

const ZipLanding = () => {
  return (
    <div>
      <div data-zm-merchant="a26a4f69-6f7f-48ec-84a4-03c98dca9fe9" data-env="production"></div>
      <div data-zm-asset="landingpage" data-zm-widget="inline"></div>
    </div>
  )
}

export default ZipLanding
