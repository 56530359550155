import * as React from 'react'
import classnames from 'classnames'

import cls from './status-dot.module.scss'

const UikStatusDot = ({ children, className, color, name, Component, fill, ...rest }: UikStatusDotProps) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [color]: color,
    })}
    {...rest}
  >
    {children}
  </Component>
)

UikStatusDot.defaultProps = {
  className: null,
  Component: 'span',
  children: null,
  color: 'green',
}

export default UikStatusDot
