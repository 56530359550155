import * as React from 'react'
import classnames from 'classnames'

const Uikon = ({ children, className, Component, color, ...rest }: UikonProps) => (
  <i
    className={classnames('uikon', className, {
      [color]: color,
    })}
    {...rest}
  >
    {children}
  </i>
)

Uikon.defaultProps = {
  className: null,
  Component: 'i',
  children: null,
  color: null,
}

export default Uikon
