import * as React from 'react'
import classnames from 'classnames'
import cls from './loader-dots.module.scss'

const LoaderDots = ({ className, color }: UikLoaderDots) => (
  <div className={classnames(cls.dotloader, className, 'btn-loader')}>
    <div className={classnames(cls.dotloader1, { [cls.colorPrimary]: color === 'primary' })} />
    <div className={classnames(cls.dotloader2, { [cls.colorPrimary]: color === 'primary' })} />
    <div className={classnames(cls.dotloader3, { [cls.colorPrimary]: color === 'primary' })} />
  </div>
)

LoaderDots.defaultProps = {
  className: null,
}

export default LoaderDots
