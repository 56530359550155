import { createSlice } from '@redux'
import api from '@api'
import { addAlert } from '@features/alerts'

export const initialState = []

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    set: (state, { payload }) => {
      if (!payload.length) return []
      return payload.map(({ id, email, status, firstName = null, lastName = null, roleDescription }) => ({
        id,
        email,
        status,
        firstName,
        lastName,
        roleDescription,
      }))
    },
    clear: () => {
      return initialState
    },
  },
})

export const { set, clear } = slice.actions

export default slice.reducer

export const getEmployees = () => async (dispatch, getState) => {
  const {
    business: { id: businessId },
  } = getState()

  const res = await api.get(`/v1/businesses/${businessId}/employees`)
  dispatch(set(res.data))
}

export const inviteEmployee = ({ email, name, phone }) => async (dispatch, getState) => {
  const {
    business: { id: businessId },
  } = getState()

  try {
    await api.post(`/v1/businesses/${businessId}/employees`, {
      email,
      name,
      mobilePhone: phone,
    })

    return dispatch(
      addAlert({ message: `Team member successfully created`, success: true, dismiss: true, top: true })
    )
  } catch (error) {
    console.error(error)
    return dispatch(
      addAlert({ message: `Team member could not be created`, error: true, dismiss: true, top: true })
    )
  }
}

export const resendEmployeeInvite = employeeId => async (dispatch, getState) => {
  const {
    business: { id: businessId },
  } = getState()

  try {
    await api.post(`/v1/businesses/${businessId}/employees/${employeeId}/resend_invite`)

    return dispatch(addAlert({ message: `Invitation resent`, success: true, dismiss: true, top: true }))
  } catch (error) {
    console.error(error)
    return dispatch(
      addAlert({ message: `Invitation could not be resent`, error: true, dismiss: true, top: true })
    )
  }
}

export const followInvite = (
  password,
  firstName,
  lastName,
  mobilePhone,
  termsAccepted,
  activationToken
) => async dispatch => {
  try {
    await api.post(`/v1/employee_activations`, {
      activationToken,
      password,
      firstName,
      lastName,
      mobilePhone,
      termsAccepted,
    })
    //console.log('password:' + password + ' firstName:' + firstName + ' lastName:' + lastName)
    return {
      success: true,
    }
  } catch (error) {
    console.error(error)
    return {
      error: 'Could not activate a new account',
    }
  }
}

export const deactivateEmployee = employeeId => async (dispatch, getState) => {
  const {
    business: { id: businessId },
  } = getState()

  try {
    await api.put(`/v1/businesses/${businessId}/employees/${employeeId}`, {
      status: 'INACTIVE',
    })

    return dispatch(
      addAlert({ message: `Account successfully deactivated`, info: true, dismiss: true, top: true })
    )
  } catch (error) {
    console.error(error)
    return dispatch(
      addAlert({ message: `Account deactivation failed`, error: true, dismiss: true, top: true })
    )
  }
}

export const reactivateEmployee = employeeId => async (dispatch, getState) => {
  const {
    business: { id: businessId },
  } = getState()

  try {
    await api.put(`/v1/businesses/${businessId}/employees/${employeeId}`, {
      status: 'ACTIVE',
    })

    return dispatch(
      addAlert({ message: `Account successfully activated`, info: true, dismiss: true, top: true })
    )
  } catch (error) {
    console.error(error)
    return dispatch(addAlert({ message: `Account activation failed`, error: true, dismiss: true, top: true }))
  }
}
