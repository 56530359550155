import api from '@api'
import { addAlert } from '@features/alerts'

const altPaymentFinalization = values => async (dispatch, getState) => {
  const { tenderTypesAbilityId, tenderTypesPreparationId, businessId, clientKey, total } = values

  const res = await api
    .post(`/v1/businesses/${businessId}/tender_types_abilities/${tenderTypesAbilityId}/finalizations`, {
      tenderTypesAbilityId,
      tenderTypesPreparationId,
      businessId,
      clientKey,
      total,
    })
    .catch(err => {
      console.error(err)
      dispatch(
        addAlert({
          message: 'Finalization payment request failed',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
      return {}
    })

  return res.data
}
export default altPaymentFinalization
