// @flow
import * as React from 'react'
import classnames from 'classnames'

import UikContentTitle from '../UikContentTitle'

import cls from './index.module.scss'

class Input extends React.PureComponent<UikInputProps> {
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
    icon: null,
    clear: false,
    iconPosition: 'left',
    errorMessage: null,
    rows: 3,
    forwardRef: null
  }

  // setRef = node => {
  //   this.input = node
  // }
  //
  setValue = value => {
    this.input.value = value
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      icon,
      label,
      focus, // eslint-disable-line
      clear,
      iconPosition,
      errorMessage,
      // size
      rows,
      forwardRef,
      ...rest
    } = this.props

    return (
      <div
        {...wrapperProps}
        className={classnames(wrapperProps.className, cls.formGroup, {
          [cls.clear]: clear,
        })}
      >
        {label && <UikContentTitle>{label}</UikContentTitle>}
        <div className={classnames(cls.inputWrapper)}>
          {icon && (
            <span
              className={classnames(cls.iconWrapper, {
                [cls.iconWrapperRight]: iconPosition === 'right',
              })}
            >
              {icon}
            </span>
          )}
          <textarea
            rows={rows}
            ref={forwardRef}
            className={classnames(className, cls.input, {
              [cls.errorHighlight]: errorMessage,
            })}
            name={name}
            type="text"
            {...rest}
          />
        </div>
        {errorMessage && <p className={cls.errorMessage}>{errorMessage}</p>}
      </div>
    )
  }
}

export default Input
