import React, { useEffect } from 'react'
import { useBankConnections } from '@hooks'
import { useSelector } from '@redux'

export const PropellBankDataCtx = React.createContext(null)
export const usePropellBankDataCtx = () => {
  const {
    bankStartFetch,
    bankFetchCompleted,
    bankFetching,
    bankConnections,
    primaryAccounts,
    primaryBankIsMfa,
    bankConnected,
  } = React.useContext(PropellBankDataCtx)
  return {
    bankStartFetch,
    bankFetchCompleted,
    bankFetching,
    bankConnections,
    primaryAccounts,
    primaryBankIsMfa,
    bankConnected,
  }
}

const PropellBankDataProvider = ({ children }) => {
  const bid = useSelector(({ business }) => business?.id)
  const bankConnected = useSelector(({ business }) => business?.bankConnected)
  const {
    startFetch: bankStartFetch,
    fetchCompleted: bankFetchCompleted,
    fetching: bankFetching,
    connections: bankConnections,
    primaryAccounts,
    primaryBankIsMfa,
  } = useBankConnections(bid)

  useEffect(() => {
    ;(async () => {
      bankStartFetch(true)
    })()
    // eslint-disable-next-line
  }, [])

  return (
    <PropellBankDataCtx.Provider
      value={{
        bankStartFetch,
        bankFetchCompleted,
        bankFetching,
        bankConnections,
        primaryAccounts,
        primaryBankIsMfa,
        bankConnected,
      }}
    >
      {children}
    </PropellBankDataCtx.Provider>
  )
}
export default PropellBankDataProvider
