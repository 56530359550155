import * as React from 'react'
import classnames from 'classnames'

import cls from './nav-link.module.scss'

import UikNavLink from '../UikNavLink'

const UikNavLinkDark = ({ children, className, ...rest }: UikNavLinkDarkProps) => (
  <UikNavLink className={classnames(cls.wrapperDark, className)} {...rest}>
    {children}
  </UikNavLink>
)

UikNavLinkDark.defaultProps = {
  className: null,
  children: null,
}

export default UikNavLinkDark
