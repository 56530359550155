import * as React from 'react'
import classnames from 'classnames'

import UikContentTitle from '../UikContentTitle'
import UikButton from '../UikButton'
import { FaDollarSign, FaSearch } from 'react-icons/fa'
import { Box, Tooltip } from '@chakra-ui/react'

import cls from './input.module.scss'

const ActionButton = ({ handleClick, actionIcon, maybeClearValue, showErr, hasInput }) => {
  const [loading, setLoading] = React.useState(false)
  const executeClick = async (...args) => {
    setLoading(true)
    await handleClick(...args)
    maybeClearValue()
    setLoading(false)
  }
  const [showTooltips, setShowTooltips] = React.useState(false)

  React.useEffect(() => {
    if (hasInput) {
      setTimeout(() => {
        setShowTooltips(true)
        setTimeout(() => {
          setShowTooltips(false)
        }, 6000)
      }, 1000)
    }
  }, [hasInput])

  return (
    <UikButton
      clear
      icon={
        <Tooltip
          shouldWrapChildren
          label="Click here search your business"
          placement="top"
          isOpen={showTooltips}
          style={{ backgroundColor: '#2568ef' }}
        >
          <Box as={actionIcon || FaSearch} boxSize="1rem" />
        </Tooltip>
      }
      isLoading={loading}
      iconOnly
      onClick={args => {
        setShowTooltips(false)
        executeClick(args)
      }}
      className={cls.actionButton}
      style={{ outline: showErr ? '1px solid red' : null }}
    />
  )
}

const CurrencyPre = () => {
  return <UikButton clear icon={<Box as={FaDollarSign} size="1rem" />} iconOnly className={cls.preButton} />
}

class Input extends React.PureComponent<UikInputProps> {
  state = {
    value: '',
  }
  static defaultProps = {
    wrapperProps: {},
    label: null,
    name: null,
    className: null,
    icon: null,
    clear: false,
    iconPosition: 'right',
    errorMessage: null,
    actionClearsValue: false,
    type: 'text',
  }

  maybeClearValue = () => {
    if (this.props.actionClearsValue) {
      this.setState({ value: '' })
    }
  }

  render() {
    const {
      className,
      wrapperProps,
      name,
      icon,
      label,
      focus, // eslint-disable-line
      clear,
      iconPosition,
      errorMessage,
      action,
      actionIcon,
      actionClearsValue,
      // just filter out dispatch from ...rest
      dispatch,
      // size
      type,
      note,
      showErr,
      ...rest
    } = this.props
    return (
      <div
        {...wrapperProps}
        className={classnames(
          wrapperProps.className,
          cls.formGroup,
          {
            [cls.clear]: clear,
          },
          cls.wrapperLayout
        )}
      >
        {label && <UikContentTitle>{label}</UikContentTitle>}
        {note && <div className={cls.note}> {note}</div>}
        <div className={classnames(cls.inputWrapper)}>
          {icon && (
            <span
              className={classnames(cls.iconWrapper, {
                [cls.iconWrapperRight]: iconPosition === 'right',
              })}
            >
              {icon}
            </span>
          )}
          {type === 'currency' ? <CurrencyPre /> : null}
          <input
            onChange={({ target: { value } }) => this.setState({ value })}
            className={classnames(className, cls.input, {
              [cls.errorHighlight]: errorMessage,
            })}
            name={name}
            value={this.state.value}
            type={type === 'currency' ? 'number' : type}
            {...rest}
          />
          {action ? (
            <ActionButton
              handleClick={action.bind(null, this.state.value)}
              actionIcon={actionIcon}
              maybeClearValue={this.maybeClearValue}
              showErr={showErr}
              hasInput={this.state?.value?.length > 3}
            />
          ) : null}
        </div>
        {errorMessage && <p className={cls.errorMessage}>{errorMessage}</p>}
      </div>
    )
  }
}

export default Input
