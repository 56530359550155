import React from 'react'
import { capitalize } from 'lodash'

import { UikCheckbox } from '@uik'

import cls from './index.module.scss'

const Checkbox = ({ errors = null, touched = null, name, ...props }) => {
  const hasError = errors && touched && errors[name] && touched[name]
  const noMaxWidth = props?.noMaxWidth
  const myProps = { ...props }
  delete myProps.noMaxWidth

  return (
    <div className={noMaxWidth ? cls.containerNoMaxWidth : cls.container}>
      <UikCheckbox color="green" {...myProps} />
      {hasError ? <p className={cls.error}>{capitalize(errors[name])}</p> : null}
    </div>
  )
}

export default Checkbox
