import React from 'react';
import ReactDOM from 'react-dom';
import boxen from 'boxen';
import { ConnectedRouter } from 'connected-react-router';
import { Provider as ReduxProvider } from 'react-redux';
import { ChakraProvider, Flex } from '@chakra-ui/react';

import configureStore, { history } from '@redux/store';

import initialState from '@redux/initialState';

import chakraTheme from '@theme/chakra-theme';
import {
  UikTopBar,
  UikTopBarSection,
  UikTopBarTitle,
  UikContainerHorizontal,
} from '@uik';
import cls from './index.module.scss';
import { mainLogo, brandName } from '@theme';
import { buildVersion } from './version';
import mixpanel from 'mixpanel-browser';
import { PropellSupportModalProvider } from './components';
export const store = configureStore(initialState);
export const PropellMixPanelEnabled = true;
const msg = `Build number ${buildVersion}`;
const defaultMaintenaceValue = false;
const forceMaintenaceValue =
  localStorage.getItem('forceMaintenaceValue') || null;
const maintenance =
  forceMaintenaceValue !== null
    ? forceMaintenaceValue === 'true'
      ? true
      : forceMaintenaceValue === 'false'
      ? false
      : null
    : defaultMaintenaceValue;

console.log({ maintenance });
const Maintenance = () => (
  <div>
    <UikTopBar>
      <UikTopBarSection>
        <UikTopBarTitle className={cls.mainLogo}>
          <img
            src={mainLogo}
            alt={`${brandName} Logo`}
            style={{ width: '100%' }}
          />
        </UikTopBarTitle>
      </UikTopBarSection>
    </UikTopBar>
    <UikContainerHorizontal>
      <Flex className={cls.mainSection}>
        <div>
          <h2> We'll be back soon!</h2>
          <div>
            Sorry for the inconvenience but we're performing some maintenance at
            the moment. If you need to you can always{' '}
            {/* Sorry for the inconvenience but due to a global outage we are unable to process your request at
            this time. If you need to you can always{' '} */}
            <a
              className={cls.linkText}
              target="_blank"
              rel="noopener noreferrer"
              href="https://propellme.com/contact-us/"
            >
              contact us
            </a>
            , otherwise, we'll be back online shortly!
          </div>
          <br></br>
          <div>&#x2014; The Propell Team</div>
        </div>
      </Flex>
    </UikContainerHorizontal>
  </div>
);

console.info(boxen(msg, { padding: 1 }));
if (PropellMixPanelEnabled)
  mixpanel.init('14481bf9e8ce6a80aea74427abdce0be', { debug: false });
const render = () => {
  const Apps = require('./Apps').default;
  ReactDOM.render(
    <>
      {!maintenance ? (
        <ReduxProvider store={store}>
          <ConnectedRouter history={history}>
            <ChakraProvider theme={chakraTheme}>
              <PropellSupportModalProvider>
                <Apps />
              </PropellSupportModalProvider>
            </ChakraProvider>
          </ConnectedRouter>
        </ReduxProvider>
      ) : (
        <Maintenance />
      )}
    </>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./Apps', render);
}
