import React, { useState } from 'react'
import { IconsPropell } from '@components'
import cls from './index.module.scss'
import Modal from './components/Modal'

export const PropellSupportModalCtx = React.createContext(null)
export const usePropellSupportModalCtx = () => {
  const [openHelpModal] = React.useContext(PropellSupportModalCtx)
  return { openHelpModal }
}

const PropellSupportModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen(!open)
  }
  const openHelpModal = () => {
    setOpen(true)
  }
  return (
    <>
      <PropellSupportModalCtx.Provider value={[openHelpModal]}>{children}</PropellSupportModalCtx.Provider>
      <button
        className={cls.button}
        style={{ display: open ? 'none' : 'flex' }}
        onClick={() => {
          toggleModal()
        }}
      >
        <IconsPropell name="PropellHelp" sizeInPx={26} />
        <div className={cls.desktopShow}>&nbsp; Help</div>
      </button>
      <div className={cls.modalContainer} style={{ display: open ? 'flex' : 'none' }}>
        <Modal toggleModal={toggleModal} open={open} />
      </div>
    </>
  )
}
export default PropellSupportModalProvider
