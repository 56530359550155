import React from 'react'
import cls from './index.module.scss'

const PropellSpinnerLogo = () => {
  return (
    <div className={cls.loaderContainer}>
      {/* <div style={{ width: '2rem', height: '2rem' }}> */}
      <div style={{ width: '1.5rem', height: '1.5rem' }}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 178 178"
          style={{ enableBackground: 'new 0 0 178 178' }}
          xmlSpace="preserve"
        >
          <polygon
            className={cls.st0}
            points="103.20001983642578,75.23332703113556 108.00000762939453,90.73332703113556 174.4000244140625,87.93333923816681 158.5,39.0333377122879 "
          />
          <polygon
            className={cls.st0}
            points="157.29998779296875,140.43335449695587 116.00000762939453,170.633336186409 92.9000015258789,108.63332092761993 106.00000762939453,99.03334534168243 "
          />
          <polygon
            className={cls.st0}
            points="60.70001220703125,170.633336186409 19.00000762939453,140.43335449695587 71.00000762939453,99.43333923816681 84.20001983642578,109.03334534168243 "
          />
          <polygon
            className={cls.st0}
            points="1.8999989032745361,87.93333923816681 17.79999542236328,39.0333377122879 73.00000762939453,76.03334534168243 67.9000015258789,91.53334534168243 "
          />
          <polygon
            className={cls.st0}
            points="62.70001220703125,6.43334686756134 79.79999542236328,70.43333923816681 96.10001373291016,70.43333923816681 114.00000762939453,6.43334686756134 "
          />
        </svg>
      </div>
    </div>
  )
}

export default PropellSpinnerLogo
