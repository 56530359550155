import { createSlice } from '@redux'

export const initialState = { summary: {}, orders: [] }

const slice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    addOrder: (state, { payload }) => {
      const {
        id,
        total: { minorUnits, currencyCode },
        orderReference,
        business: { abn, email, website, phone, iconUrl, registeredName, tradingName, useTradingName },
        total,
        business,
        passOnFeeEnabled,
      } = payload
      const current = {}
      current[id] = {
        minorUnits,
        currencyCode,
        reference: orderReference,
        phone,
        email,
        logo: iconUrl,
        website,
        businessName: useTradingName ? tradingName : registeredName,
        abn,
        total,
        business,
        passOnFeeEnabled,
      }
      return {
        ...state,
        current: current,
      }
    },
    setSummary: (state, { payload }) => {
      return {
        ...state,
        summary: payload,
      }
    },
    setOrders: (state, { payload }) => {
      return {
        ...state,
        orders: payload,
      }
    },
    clear: () => {
      return initialState
    },
  },
})

export const { addOrder, setSummary, setOrders, clear } = slice.actions

export default slice.reducer

export { default as submitPayNow } from './payNow'
export { default as submitPayLater, getOrder } from './payLater'
export { default as collectPayment } from './collect'
export { default as collectPaymentResend } from './collectResend'
export { default as altPaymentPreparation } from './altPaymentPreparation'
export { default as altPaymentFinalization } from './altPaymentFinalization'
export { default as altPaymentFinalizeZipOrder } from './altPaymentFinalizeZipOrder'
export { default as altPaymentFinalizeStripeOrder } from './altPaymentFinalizeStripeOrder'
export { default as getOrdersSummary } from './summary'
export { default as postReceiptResend } from './receiptResend'

export { getOrdersData, refundRequest } from './orders'
