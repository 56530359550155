import React, { useState } from 'react'
import cls from './index.module.scss'
import { useDropzone } from 'react-dropzone'
import { IconsPropell } from '@components'

const maxFiles = 1
const DeleteBtn = () => {
  return <IconsPropell name="CloseBtn" sizeInPx={16} />
}

const UploadDocs = props => {
  //const [files, setFiles] = useState([])
  const { files, setFiles } = props
  const [error, setError] = useState()
  const { setFieldValue } = props
  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'image/*',
    maxFiles: maxFiles,
    onDrop: acceptedFiles => {
      setError()
      setFieldValue(props.name, acceptedFiles)
      const mFiles = acceptedFiles
      if (mFiles?.length + files?.length <= maxFiles) {
        const newFiles = files.concat(mFiles)
        setFiles(newFiles)
      } else {
        setError(
          `Attachment limit reached You can upload a maximum of ${maxFiles} attachments.Please select again!`
        )
      }
    },
  })

  return (
    <>
      <div className={cls.label}>
        {props.label}
        {`${files?.length > 0 ? `(${files?.length})` : ''}`}
      </div>
      <div className={cls.docsList}>
        {files.map((file, idx) => {
          return (
            <div key={`file-${idx}`} className={cls.docsItem}>
              <div className={cls.docsItemText}>{file.path}</div>
              <div
                className={cls.docsItemCloseBtnContainer}
                onClick={() => {
                  const newFiles = files.filter(f => f.name !== file.name)
                  setFiles(newFiles)
                }}
              >
                <DeleteBtn />
              </div>
            </div>
          )
        })}
        {error ? <div className={cls.errText}>{error}</div> : null}
      </div>
      <div className={cls.fileDropBox}>
        <div {...getRootProps({ className: 'dropzone' })} className={cls.fileDropBoxInner}>
          <input {...getInputProps()} />
          <p>Add up to {maxFiles} files</p>
          {/* <p>{files.length} files uploaded</p> */}
        </div>
      </div>
    </>
  )
}

export default UploadDocs
