import { useEffect, useState } from 'react'
import { useSelector } from '@redux'
import { formatFloatCurrency } from '@lib'

/* 
  Hook - construst bar char data 
*/
const useInsightsData = bid => {
  const insights = useSelector(({ insights }) => insights)
  const [adb, setAdb] = useState('NA') //'$6,345.00'
  const [adbNum, setAdbNum] = useState(0)
  const [amd, setAmd] = useState('NA') //$12,345.00'
  const [din, setDin] = useState('NA') // '0'
  const [mdb, setMdb] = useState('NA') // $1,329.00'
  const [totalBalance, setTotalBalance] = useState('NA')
  const [totalCredits, setTotalCredits] = useState('NA') // '0'
  const [totalDebits, setTotalDebits] = useState('NA') // $1,329.00'
  const [allLoanDebit, setAllLoanDebit] = useState('NA') // $1,329.00'

  useEffect(() => {
    if (insights) {
      if (insights?.totalAverageDailyBalance) {
        setAdb(formatFloatCurrency(insights?.totalAverageDailyBalance))
        if (insights?.totalAverageDailyBalance) setAdbNum(insights.totalAverageDailyBalance)
      }
      if (insights?.totalAverageMonthlyDeposits) {
        setAmd(formatFloatCurrency(insights.totalAverageMonthlyDeposits))
      }
      if (insights?.primaryBankAccountDaysInNegative) {
        setDin(`${Number(insights.primaryBankAccountDaysInNegative).toFixed(0)}`)
      }
      if (insights?.primaryBankAccountMinimumDayBalance) {
        setMdb(formatFloatCurrency(insights.primaryBankAccountMinimumDayBalance))
      }
      if (insights?.totalCredits) {
        setTotalCredits(formatFloatCurrency(insights.totalCredits))
      }
      if (insights?.totalDebits) {
        setTotalDebits(`${formatFloatCurrency(-1 * insights.totalDebits)}`)
      }
      if (insights?.allLoanDebits) {
        setAllLoanDebit(formatFloatCurrency(insights.allLoanDebits))
      }
      if (insights?.totalBalance) {
        setTotalBalance(formatFloatCurrency(insights.totalBalance))
      }
    }

    // eslint-disable-next-line
  }, [insights])

  return {
    adb,
    adbNum,
    amd,
    din,
    mdb,
    totalBalance,
    totalCredits,
    totalDebits,
    allLoanDebit,
  }
}
export default useInsightsData
