import api from '@api'
import { setInsightsData } from './index.js'

export const getCreditWatchScores = bid => async (dispatch, getState) => {
  try {
    const res = await api.get(`/v1/businesses/${bid}/credit_watch_scores/current`)
    await dispatch(setInsightsData({ detail: res.data }))
    //return res.data
  } catch (error) {
    console.error(error)
  }
}

export default getCreditWatchScores
