import React from 'react'
import cls from './index.module.scss'

const PropellTooltip = ({ children, tips, title, pos, show }) => {
  return (
    <div
      className={cls.tooltip}
      style={{
        top: pos.y,
        left: pos.x,
        visibility: show ? 'visible' : 'hidden',
      }}
    >
      <div className={cls.title}>{title}</div>
      <div className={cls.text}>{tips}</div>
    </div>
  )
}

export default PropellTooltip
