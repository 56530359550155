import React from 'react'
import cls from './index.module.scss'
import { generateCurve } from './curveFunctions'

//const boxSz = { w: 378, h: 186, topMargin: 32, bottomMargin: 32 }
//const boxSz = { w: 390, h: 84, topMargin: 12, bottomMargin: 12 }
const boxSz = { w: 390, h: 115, topMargin: 20, bottomMargin: 28 }
const tooltipsSz = { width: '40', height: '20' }
const tooltipsStyle = {
  fontFamily: 'Roboto',
  fontSize: '10px',
  lineHeight: '10px',
  fontWeight: '500',
}
const generatePoints = (line, posXs, scale, fullHeight, offset) => {
  return line.map((v, idx) => {
    const y = v >= 0 ? v * scale : Math.abs(v) * scale
    const ypos = v >= 0 ? fullHeight - y - offset : fullHeight - offset + y
    return [posXs[idx], ypos > 0 ? ypos : boxSz.topMargin / 2]
  })
}

const SvgCurve = (line, posXs, scale, fullHeight, offset, color, idx) => {
  const mLine = line
  const points = generatePoints(mLine, posXs, scale, fullHeight, offset)
  //console.log('Points' + JSON.stringify(points))
  const curvePoints = generateCurve(points)
  //console.log('curve' + JSON.stringify(curvePoints))
  return (
    <path
      key={`path#${idx}`}
      d={curvePoints}
      fill="none"
      strokeOpacity="0.8"
      stroke={color}
      strokeWidth="4"
      filter="url(#lineDropShadow)"
    />
  )
}

export const SvgCurveChart = ({ instanceId, lines: mLines, months, max, min }) => {
  const scale = (boxSz.h - (boxSz.bottomMargin + boxSz.topMargin)) / (max - min)
  const offset = min * scale * -1 + boxSz.topMargin
  //const width = boxSz.w / (2 * 12)
  //const width = boxSz.w / (mLines[0]?.length - 1)
  const width = boxSz.w / (mLines[0]?.length - 0.5)
  const posXs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((v, idx) => {
    return idx * width + width / 4
  })
  const lines = mLines.map(line => line.map(pt => pt.value))
  const tooltipLabel = mLines.map(line => line.map(pt => pt.label))
  const dataPoints = lines.map((line, idx) => {
    const points = generatePoints(line, posXs, scale, boxSz.h, offset)
    return points
  })

  return (
    <svg width="100%" height="100%" viewBox={`0 0 ${boxSz.w} ${boxSz.h}`} className={cls.svgContainer}>
      <defs>
        <filter id="lineDropShadow" colorInterpolationFilters="sRGB">
          <feGaussianBlur stdDeviation="0.8" />
          <feDropShadow dx="3" dy="3" stdDeviation="3" floodColor="#00C9C6" floodOpacity="0.4" />
        </filter>
      </defs>
      {lines.map((line, idx) => {
        return SvgCurve(line, posXs, scale, boxSz.h, offset, '#00C9C6', idx)
      })}
      {dataPoints.map((line, lineIdx) => {
        return line.map((points, pointIdx) => {
          return (
            <g
              key={`${instanceId}line#${lineIdx}Point${pointIdx}`}
              id={`${instanceId}line#${lineIdx}Point${pointIdx}`}
            >
              <circle cx={points[0]} cy={points[1]} r="2" fill={'#00C9C6'} fillOpacity="0.4" />
              <circle
                cx={points[0]}
                cy={points[1]}
                r="6"
                fill={'transparent'}
                style={{ cursor: 'pointer' }}
              />
            </g>
          )
        })
      })}
      {dataPoints.map((line, lineIdx) => {
        return line.map((points, pointIdx) => {
          //const rectWidth = tooltipLabel[lineIdx][pointIdx]?.length * 6.5
          const rectWidth = tooltipLabel[lineIdx][pointIdx]?.length * 7
          const rectHeight = tooltipsSz.height
          const rectOffset =
            pointIdx <= 1
              ? { x: 0, y: -1 * (4 + parseInt(tooltipsSz.height)) }
              : pointIdx === line?.length - 1
              ? { x: -1 * rectWidth, y: -1 * (4 + parseInt(tooltipsSz.height)) }
              : { x: -1 * (rectWidth / 2), y: -1 * (4 + parseInt(tooltipsSz.height)) }
          //const textOffset = { x: rectOffset.x + 6.5, y: -rectHeight / 2 - 7 }
          const textOffset = { x: rectOffset.x + 10.5, y: -rectHeight / 2 - 7 }
          const rectPos = {
            x: points[0] + rectOffset.x,
            y: points[1] + rectOffset.y - 6.5,
          }
          const trianglePos =
            pointIdx <= 1
              ? [
                  //{ x: rectPos.x - 2.5, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x + 3.5, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x, y: rectPos.y + parseInt(rectHeight) + 6.5 },
                ]
              : pointIdx === line?.length - 1
              ? [
                  { x: rectPos.x + rectWidth - 3.5, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x + rectWidth, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x + rectWidth, y: rectPos.y + parseInt(rectHeight) + 6.5 },
                ]
              : [
                  { x: rectPos.x + rectWidth / 2 - 3.5, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x + rectWidth / 2 + 3.5, y: rectPos.y + parseInt(rectHeight) },
                  { x: rectPos.x + rectWidth / 2, y: rectPos.y + parseInt(rectHeight) + 6.5 },
                ]
          return (
            <g key={`tooltips#${lineIdx}Point${pointIdx}`} visibility="hidden">
              <set
                attributeName="visibility"
                to="visible"
                begin={`${instanceId}line#${lineIdx}Point${pointIdx}.mouseenter`}
                end={`${instanceId}line#${lineIdx}Point${pointIdx}.mouseleave`}
              />
              <rect
                x={rectPos.x}
                y={rectPos.y}
                width={rectWidth}
                height={rectHeight}
                stroke="none"
                fill={'#00C9C6'}
                //fill={'rgb(56, 80, 149)'}
                strokeWidth="5"
                filter="url(#shadow)"
                rx="3"
              ></rect>
              <circle cx={points[0]} cy={points[1]} r="3.5" fill={'#00C9C6'} />
              <polygon
                points={`${trianglePos[0].x} ${trianglePos[0].y},${trianglePos[1].x} ${trianglePos[1].y},${trianglePos[2].x} ${trianglePos[2].y}`}
                stroke="none"
                fill={'#00C9C6'}
                //fill={'rgb(56, 80, 149)'}
                strokeWidth="5"
                filter="url(#shadow)"
              />
              <text
                x={points[0] + textOffset.x}
                y={points[1] + textOffset.y}
                //fill={'white'}
                fill={'#273869'}
                style={tooltipsStyle}
              >
                {tooltipLabel[lineIdx][pointIdx]}
              </text>
            </g>
          )
        })
      })}
    </svg>
  )
}

export default SvgCurveChart
