import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'

import alerts from '@features/alerts'
import authLogin from '@features/auth/login'
import authRegister from '@features/auth/register'
import business from '@features/business'
import employees from '@features/employees'
import layout from '@Dashboard/layout'
import lending from '@features/lendings'
import tradeLending from '@features/tradeLendings'
import insights from '@features/insights'
import insightsUi from '@features/insightsUi'
import originationsChoice from '@features/originations/choice'
import originationsForm from '@features/originations/form'
import originationsLookup from '@features/originations/lookup'
import originationsStep from '@features/originations/step'
import originationsBusiness from '@features/originations/businessForm'
import payments from '@features/payments'
import user from '@features/user'
import userFees from '@features/userFees'
import termConditions from '@features/termConditions'
import apiStatus from '@features/apiStatus'
import bankFeeds from '@features/bankFeeds'
import critical from '@features/critical'
import businessFees from '@features/businessFees'
import uiStateHelper from '@features/uiStateHelper'
import routingStateHelper from '@features/routingStateHelper'
import propellTradePayment from '@features/propellTradePayment'
//import veriff from '@features/veriff'
import merchants from '@features/merchants'
import brokerConnectUi from '@features/brokerConnectUi'

const auth = combineReducers({
  login: authLogin,
  register: authRegister,
})

const originations = combineReducers({
  choice: originationsChoice,
  form: originationsForm,
  lookup: originationsLookup,
  step: originationsStep,
  business: originationsBusiness,
})

const createRootReducer = history =>
  combineReducers({
    alerts,
    auth,
    business,
    critical,
    employees,
    layout,
    lending,
    tradeLending,
    insights,
    insightsUi,
    originations,
    payments,
    propellTradePayment,
    userFees,
    termConditions,
    apiStatus,
    router: connectRouter(history),
    merchants,
    user,
    bankFeeds,
    businessFees,
    uiStateHelper,
    routingStateHelper,
    brokerConnectUi,
    //veriff,
  })

export default createRootReducer
