const makeFormatter = minimumFractionDigits =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
  })

const formatCurrency = (minorUnits, digits = 2) =>
  makeFormatter(digits).format((minorUnits / 100).toFixed(digits))
export default formatCurrency
export const formatKikka = (minorUnits, digits = 0) =>
  makeFormatter(digits).format(parseFloat(minorUnits).toFixed(digits))
export const formatFloatCurrency = (minorUnits, digits = 2) =>
  makeFormatter(digits).format(parseFloat(minorUnits).toFixed(digits))
