import * as React from 'react'
import classnames from 'classnames'

import cls from './tag.module.scss'

const UikTag = ({ children, className, color, Component, fill, ...rest }: UikTagProps) => (
  <Component
    className={classnames(cls.wrapper, className, {
      [color]: color,
      [cls.fill]: fill,
    })}
    {...rest}
  >
    {children}
  </Component>
)

UikTag.defaultProps = {
  className: null,
  Component: 'span',
  children: null,
  color: null,
  fill: false,
}

export default UikTag
