import api from '@api'

export const getLoansPrecheck = ({ amount, loanType }) => async (dispatch, getState) => {
  try {
    // console.log('amount, loanType: ' + amount + ':' + loanType)
    const res = await api.get(
      encodeURI(`/v1/lendings/loans/pre-check?amount=${amount}&loan_type=${loanType}`)
    )
    return res.data
  } catch (error) {
    console.error(error)
    return null
  }
}

export default getLoansPrecheck
