import { createSlice } from '@redux'
import { setCustomerId } from '@features/user'

import { auth } from '@api'

export const initialState = {
  success: null,
  error: null,
  errorField: null,
  loginExists: false,
}

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    registerSuccess: (state, { payload }) => {
      return {
        ...initialState,
        success: true,
        ...payload,
      }
    },
    registerFailure: (state, { payload }) => {
      return {
        ...initialState,
        success: false,
        ...payload,
      }
    },
    setLoginExists: (state, { payload }) => {
      state.loginExists = payload
    },
  },
})

export default registerSlice.reducer
export const { setLoginExists } = registerSlice.actions

const registerSuccess = res => async dispatch => {
  const { accessToken, refreshToken, customerId } = res.data
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)

  if (customerId) {
    localStorage.setItem('customerId', customerId)
    await dispatch(setCustomerId(customerId))
  }
  await dispatch(registerSlice.actions.registerSuccess(res.data))
  return {
    success: true,
    accessToken,
  }
}

const registerFailure = error => async dispatch => {
  if (!error.response.data || !error.response.data.fieldError) {
    const payload = {
      error: 'Something went wrong, please try again',
    }

    await dispatch(registerSlice.actions.registerFailure(payload))
    return { ...payload, success: false }
  }

  const payload = {
    error: error.response.data.fieldError[1],
    errorField: error.response.data.fieldError[0],
  }

  dispatch(registerSlice.actions.registerFailure(payload))
  return payload
}

export const register = (
  email,
  password,
  firstName,
  lastName,
  mobilePhone,
  gclid,
  utmSource,
  utmMedium,
  utmCampaign,
  utmTerm,
  propellSource,
  business
) => async dispatch => {
  try {
    const res = await auth.post('/register', {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      mobilePhone: mobilePhone,
      gclid: gclid,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      propellSource,
      business,
    })
    if (res.status === 201) {
      return dispatch(registerSuccess(res))
    } else {
      console.err('register Err:' + res.status)
      return dispatch(registerFailure(res))
    }
  } catch (error) {
    console.error(error)
    return dispatch(registerFailure(error))
  }
}
