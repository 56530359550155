import api from '@api'
import { addAlert } from '@features/alerts'

const collect = values => async (dispatch, getState) => {
  const {
    business: { locationId },
  } = getState()
  const {
    email: recipientEmail,
    phone: recipientPhone,
    amount,
    reference: orderReference,
    sendWith,
    passOnFeeEnabled,
    tradeLendingFacilitySfid,
  } = values
  const minorUnits = parseInt(amount.match(/\d+/g).join(''))

  let res
  await api
    .post(`/v1/locations/${locationId}/payments/collection-request`, {
      recipientPhone: recipientPhone,
      recipientEmail: recipientEmail,
      total: amount
        ? {
            minorUnits,
            currencyCode: 'AUD',
          }
        : undefined,
      orderReference,
      sendWith: sendWith.toUpperCase(),
      passOnFeeEnabled,
      tradeLendingFacilitySfid,
    })
    .then(async ({ data }) => {
      res = data
      if (sendWith !== 'none')
        await dispatch(
          addAlert({
            message: 'Collect payment request sent',
            success: true,
            dismiss: true,
            top: true,
            dismissTimeout: 10 * 1000,
          })
        )
    })
    .catch(async err => {
      console.error(err)
      res = err
      await dispatch(
        addAlert({
          message: 'Collect payment request failed',
          error: true,
          dismiss: true,
          top: true,
          dismissTimeout: 10 * 1000,
        })
      )
    })

  return res
}
export default collect
