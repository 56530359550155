import React from 'react'
import cls from './index.module.scss'

const PropellControlledTickBox = ({ label, state, updateState, children }) => {
  return (
    <div className={cls.CustomContainer}>
      <div
        className={cls.CustomCheckbox}
        onClick={() => {
          updateState()
        }}
      >
        <input type="checkbox" checked={state ? state : false} readOnly />
        <label>{label}</label>
      </div>
      {children}
    </div>
  )
}

export default PropellControlledTickBox
