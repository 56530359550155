import { logout } from '@features/auth/logout'

const authorization = ({ dispatch }) => next => action => {
  if (
    action.error &&
    action.error.response &&
    action.error.response.status >= 400 &&
    action.error.response.status < 500
  ) {
    dispatch(logout({ unauthorized: true }))
  } else {
    return next(action)
  }
}
export default authorization
