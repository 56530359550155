import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import createRootReducer from './reducers'
import authorizationMiddleware from './middleware/authorization'
import ReactGA from 'react-ga'
export const history = createBrowserHistory()

// Initialize google analytics page view tracking
const env = process.env.REACT_APP_API_STAGE || process.env.NODE_ENV
if (env === 'production' || env === 'production-experiment') {
  ReactGA.initialize('UA-141666341-2')
  history.listen(location => {
    ReactGA.set({ page: location.pathname }) // Update the user's current page
    ReactGA.pageview(location.pathname) // Record a pageview for the given page
  })
} else {
  console.warn('Google analytics disabled in ' + env)
}

const configureAppStore = preloadedState => {
  const store = configureStore({
    reducer: createRootReducer(history),
    middleware: [routerMiddleware(history), authorizationMiddleware, ...getDefaultMiddleware()],
    preloadedState,
  })

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
      const createRootReducer = require('./reducers').default
      store.replaceReducer(createRootReducer(history))
    })
  }

  return store
}

export default configureAppStore
