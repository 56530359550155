import api from '@api'
import { setInsightsBankDecisionMetrics } from './index.js'

export const getInsightsBankDecisionMetrics = bid => async (dispatch, getState) => {
  const test = false
  try {
    const res = await api.get(`/v1/businesses/${bid}/bank_feeds/bank_connections/decision_metrics`)
    const testData = JSON.parse(
      `[{"metric_id":"DM016","name":"Other Credits","value":"63823.9","section_title":"Income"},{"metric_id":"DM014","name":"Collection and Consolidation","value":"300.0","section_title":"Responsible lending flags"},{"metric_id":"DM027","name":"Overdrawn","value":"123.5","section_title":"Responsible lending flags"},{"metric_id":"DM015","name":"Credit Card Repayments","value":"500.0","section_title":"Liabilities"},{"metric_id":"DM048","name":"All Loans","value":"5815.2","section_title":"Liabilities"},{"metric_id":"DM028","name":"Rent","value":"1100.0","section_title":"Expenses"},{"metric_id":"DM023","name":"Groceries","value":"135.9","section_title":"Expenses"},{"metric_id":"DM058","name":"Dining Out","value":"552.8","section_title":"Expenses"},{"metric_id":"DM024","name":"Insurance","value":"895.2","section_title":"Expenses"},{"metric_id":"DM032","name":"Telecommunications","value":"185.5","section_title":"Expenses"},{"metric_id":"TF003","name":"Vehicles and Transport","value":"2218.3","section_title":"Expenses"},{"metric_id":"DM052","name":"Personal Care","value":"75.0","section_title":"Expenses"},{"metric_id":"DM054","name":"Health","value":"159.7","section_title":"Expenses"},{"metric_id":"DM062","name":"Retail","value":"224.8","section_title":"Expenses"},{"metric_id":"DM056","name":"Home Improvement","value":"262.5","section_title":"Expenses"},{"metric_id":"DM055","name":"Travel","value":"1832.4","section_title":"Expenses"},{"metric_id":"DM071","name":"Uncategorised Debits","value":"42943.4","section_title":"Expenses"}]`
    )
    //console.log(' api obj :' + JSON.stringify(res.data))
    //console.log(' real obj :' + JSON.stringify(testData))
    await dispatch(setInsightsBankDecisionMetrics(test ? testData : res.data))

    //return res.data
  } catch (error) {
    console.error(error)
  }
}

export default getInsightsBankDecisionMetrics
