import api from '@api'
//import { getUnixTime, subMonths } from 'date-fns'
import { getUnixTime } from 'date-fns'
import { addAlert } from '@features/alerts'
import { setFetchOrdersState } from '@features/uiStateHelper'
import { setOrders } from './index.js'

export const getOrdersData = () => async (dispatch, getState) => {
  const {
    business: { locationId },
  } = getState()

  try {
    const res = await api.get(`/v1/locations/${locationId}/orders`, {
      params: {
        //start_date: getUnixTime(subMonths(new Date(), 1)) + 86400,
        start_date: 0, //getUnixTime(subMonths(new Date(), 1)) + 86400,
        end_date: getUnixTime(new Date()) + 86400,
      },
    })
    dispatch(setOrders(res.data))
    dispatch(setFetchOrdersState(true))
  } catch (error) {
    console.error(error)
  }
}

export const refundRequest = orderId => async (dispatch, getState) => {
  const {
    business: { locationId },
    orders,
  } = getState()

  try {
    const { id: clientKey, tenders, total: amount } = orders.find(({ id }) => id === orderId)
    const { id: tenderId } = tenders[0]

    await api.post(`/v1/locations/${locationId}/orders/${orderId}/tenders/${tenderId}/refunds`, {
      clientKey,
      amount,
    })

    return dispatch(
      addAlert({ message: `Refund request initiated`, success: true, dismiss: true, top: true })
    )
  } catch (error) {
    const msg = error?.response?.data?.error
    return dispatch(
      addAlert({
        message: msg ? msg : `Refund request could not be initiated`,
        error: true,
        dismiss: true,
        top: true,
      })
    )
  }
}
