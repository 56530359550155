//import api from '@api'
//import { setLoans } from './index.js'
//import { addAlert } from '@features/alerts'
import api from '@api'

const getPreContractsPending = () => async (dispatch, getState) => {
  var rc = null
  await api
    .get(`/v1/lendings/loans/pre-contracts/pending`)
    .then(result => {
      // dispatch(
      //   addAlert({
      //     message: 'Loans pre-contract request sent',
      //     success: true,
      //     dismiss: true,
      //     top: true,
      //     dismissTimeout: 10 * 1000,
      //   })
      // )
      rc = result
    })
    .catch(err => {
      console.error(err)
      // dispatch(
      //   addAlert({
      //     message: 'Loans  pre-contract request failed',
      //     error: true,
      //     dismiss: true,
      //     top: true,
      //     dismissTimeout: 10 * 1000,
      //   })
      // )
      rc = { err: 'pre-contract request fail' }
    })
  return rc
}
export default getPreContractsPending
