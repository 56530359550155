import { createSlice } from '@redux'

export const initialState = false

const criticalSlice = createSlice({
  name: 'critical',
  initialState,
  reducers: {
    set: () => {
      return true
    },
    clear: () => {
      return false
    },
  },
})

export const { set, clear } = criticalSlice.actions
export default criticalSlice.reducer
