import React from 'react'
import cls from './index.module.scss'
import { Flex } from '@chakra-ui/react'
import { PropellIconBack, PropellIconForward } from './icons'

const Component = ({ pages, selectedPage, setSelectedPage }) => {
  if (pages.length < 2) return null
  return (
    <div className={cls.pageIndex}>
      <Flex
        className={cls.pageIndexBox}
        onClick={e => {
          if (selectedPage > 0) setSelectedPage(selectedPage - 1)
        }}
      >
        <PropellIconBack />
      </Flex>
      {pages.map((page, index) => {
        return (
          <Flex
            className={index === selectedPage ? cls.pageIndexBoxSelected : cls.pageIndexBox}
            key={index}
            onClick={e => {
              setSelectedPage(index)
            }}
          >
            {Number(index + 1)}
          </Flex>
        )
      })}
      <Flex
        className={cls.pageIndexBox}
        onClick={e => {
          if (selectedPage < pages.length - 1) setSelectedPage(selectedPage + 1)
        }}
      >
        <PropellIconForward />
      </Flex>
    </div>
  )
}
export default Component
