import Payment from 'payment'
import numeral from 'numeral'

export const required = value => (value ? undefined : 'Required')

export const makeMaxLengthValidation = maxLength => (value = '') =>
  value
    ? value.length <= maxLength
      ? undefined
      : `Please enter a maximum of ${maxLength} characters`
    : 'Required'

export const zipInStoreCodeValidation = (value = '') =>
  /^[\d]{6}$/.test(value.replace(/ /g, '')) ? undefined : 'Please enter a valid Zip Instore code'

export const creditCardNumberValidation = (value = '') =>
  /^[\d]{15,22}$/.test(value.replace(/ /g, '')) ? undefined : 'Please enter a valid credit card number'

export const creditCardNameValidation = (value = '') =>
  /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{4,}$/.test(value)
    ? undefined
    : 'Please enter a valid credit card holder name'

export const creditCardExpiryMonthValidation = (value = '') => {
  return /^[0-1][0-9]$/.test(value)
    ? Number(value) > 12 || Number(value) < 1
      ? 'Please enter a valid month'
      : undefined
    : 'Please enter a valid credit card expiry'
}

export const creditCardExpiryYearValidation = (value = '') => {
  return /^[0-9][0-9]$/.test(value) ? undefined : 'Please enter a valid credit card expiry'
}

export const creditCardExpiryValidation = (value = '') => {
  const date = new Date()
  const todayMonth = Number(date.getMonth() + 1)
  const todayYear = Number(date.getUTCFullYear() % 2000)
  const compareWithToday = value => {
    const inputs = value.split('/')
    if (Number(inputs[0]) > 12 || Number(inputs[0]) === 0) {
      return 'Please enter a valid month'
    } else if (Number(inputs[1]) < todayYear) {
      return 'Card Expired'
    } else if (Number(inputs[1]) === todayYear) {
      if (Number(inputs[0]) < todayMonth) {
        return 'Card Expired'
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }
  const compareResult = compareWithToday(value)
  return /^[\d]{4}$/.test(value.replace(/[ /]/, ''))
    ? compareResult
    : 'Please enter a valid credit card expiry'
}

export const creditCardCvcValidation = (value = '') =>
  /^[\d]{3,5}$/.test(value) ? undefined : 'Please enter a valid credit card CVV'

export const phoneValidation = (value = '') =>
  /^[\d]{6,18}$/.test(value) && value[0] === '0' ? undefined : 'Please enter a valid phone number'

export const emailValidation = (value = '') =>
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? undefined
    : 'Please enter a valid email address'

export const getLoanAmountValidation = (value, lowLimit, upperLimit) => {
  if (value === undefined) {
    return 'Required'
  } else {
    if (value < lowLimit) return 'Below minimum transaction amount $' + lowLimit
    else if (value > upperLimit) return 'Exceed Available credit'
    else if (Number(value) % 100 !== 0) return 'Transaction amount need to be in multiple of $100'
    else return undefined
  }
}

export const currencyAmountValidation = (value, upperLimit, lowLimit) => {
  if (value === undefined) {
    return 'Required'
  } else {
    const valueInFloat = parseFloat(value.replace(/[^0-9.-]+/g, ''))
    if (valueInFloat > upperLimit) return `Above maximum transaction amount $${upperLimit}`
    else if (valueInFloat < lowLimit) return `Below minimum transaction amount $${lowLimit}`
    else return undefined
  }
}

export const currencyAmountValidationWithLowLimit = (value, lowLimit) => {
  if (value === undefined) {
    return 'Required'
  } else {
    const valueInFloat = parseFloat(value.replace(/[^0-9.-]+/g, ''))
    if (valueInFloat < lowLimit) return `Below minimum transaction amount $${lowLimit}`
    else return undefined
  }
}

export const payLoanAmountValidation = (value, upperLimit) => {
  if (value === undefined) {
    return 'Required'
  } else {
    if (value > upperLimit) return 'Exceed Settlement Amount'
    else if (value < 50) return 'Below minimum transaction amount $50'
    else return undefined
  }
}

function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export const formatCurrency = value => {
  if (!value) return value
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    currency: 'USD',
  })
  return formatter.format(value)
}

export const formatPrice = value =>
  value === undefined
    ? '' // make controlled
    : numeral(value).format('$0,0.00')

export function formatZipInStoreCode(value) {
  if (!value) {
    return value
  }

  const clearValue = clearNumber(value)
  let nextValue

  nextValue = `${clearValue.slice(0, 1)} ${clearValue.slice(1, 2)} ${clearValue.slice(
    2,
    3
  )} ${clearValue.slice(3, 4)} ${clearValue.slice(4, 5)} ${clearValue.slice(5, 6)}`

  return nextValue.trim()
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  let nextValue

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
      break
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
      break
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12
      )} ${clearValue.slice(12, 19)}`
      break
  }

  return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  let maxLength = 4

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number)
    maxLength = issuer === 'amex' ? 4 : 3
  }

  return clearValue.slice(0, maxLength)
}

export function formatPhoneNumber(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value)
  return clearValue.slice(0, 18)
}

export function formatExpirationDate(value) {
  // don't confuse auto-completers - pass well-formatted strings as-is
  // eslint-disable-next-line
  // if (value && ((value[2] === '/' && (value.length === 5 || value.length === 7)) || (/^\d+$/.test(value) && (value.length === 4 || value.length === 6)))) {
  //   // TODO test if this works first, then try to stich together formatted string again
  //   return `${value[0]}${value[1]}/${value[value.length - 2]}${value[value.length]}`
  // }

  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    //return `${clearValue.slice(0, 2)}/${clearValue.slice(clearValue.length - 2, clearValue.length)}`
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

export const customRepaymentAmountValidation = (enabled, value, lowLimit, upperLimit) => {
  if (enabled) {
    if (value === undefined) {
      return 'Please enter a valid amount'
    } else {
      if (Number(value) < Number(lowLimit)) return 'Below minimum transaction amount $' + lowLimit
      else if (Number(value) > Number(upperLimit.toFixed(2)))
        return 'Exceed settlement amount $' + upperLimit.toFixed(2)
      else return undefined
    }
  }
}
