import React from 'react'

export const Spinner = ({ color, percent }) => {
  const circleColor = color ? color : '#00C9C6'
  const factor = 1 / 16 // divide full circle radian to 16 parts
  const circleRadius = Number(6.5)
  const centerXOffset = 50 // half of full width
  const mRadius = 40 // radius outline circle
  const minRadianDegree = 2 * Math.PI * factor
  const minCirclesPositions = []
  const opacities = []
  for (var i = 0; i < 16; i++) {
    minCirclesPositions.push({
      x: centerXOffset + Math.sin(minRadianDegree * i) * mRadius,
      y: (Math.cos(minRadianDegree * i) * mRadius - 50) * -1,
    })
    opacities.push((i + 1) * factor)
  }

  return (
    <svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g style={{ visibility: 'visible' }}>
        {minCirclesPositions.map(({ x, y }, idx) => {
          const mOpacities = [...opacities.slice(idx, opacities.length), ...opacities.slice(0, idx)].reverse()
          const strings = mOpacities.map((o, idx) => (idx === 15 ? `${o}` : `${o};`))
          const mvalues = strings.join('')
          return (
            <circle
              key={idx}
              cx={`${x}`}
              cy={`${y}`}
              r={circleRadius}
              fill={circleColor}
              opacity={`${opacities[idx]}`}
            >
              <animate
                attributeName="opacity"
                values={mvalues}
                dur="1s"
                repeatCount={percent === 100 ? 0 : 'indefinite'}
              />
            </circle>
          )
        })}
      </g>
      {percent ? (
        <text
          x={50}
          y={55}
          fill={circleColor}
          textAnchor="middle"
          style={{
            display: 'block',
            fontSize: `20px`,
            lineHeight: '20px',
            fontWeight: '700',
          }}
        >
          {percent} %
        </text>
      ) : null}
    </svg>
  )
}
export default Spinner
