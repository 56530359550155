import { createSlice } from '@redux'

export const initialState = { summary: {}, merchants: [] }

const slice = createSlice({
  name: 'merchants',
  initialState,
  reducers: {
    setMerchants: (state, { payload }) => {
      return {
        ...state,
        merchants: payload,
      }
    },
    setConnectedMerchant: (state, { payload }) => {
      return {
        ...state,
        connectedMerchants: payload,
      }
    },
    setCustomers: (state, { payload }) => {
      return {
        ...state,
        customers: payload,
      }
    },
    clear: () => {
      return initialState
    },
  },
})

export const { setMerchants, setCustomers, setConnectedMerchant, clear } = slice.actions

export default slice.reducer

export {
  getMerchants,
  getMerchantsConnect,
  connectMerchant,
  getMerchantsCustomer,
  inviteCustomer,
  precheckPropellTrade,
} from './merchants'
