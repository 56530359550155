import { useState, useEffect } from 'react'
import { throttle } from 'lodash'
import { tiny, small, medium, large } from '@theme/chakra-theme'

const getWidth = breakpoint => breakpoint.match(/^[0-9]+/)[0]
const [t, s, m, l] = [tiny, small, medium, large].map(getWidth)

const getBreakpoint = width => {
  if (width < t) {
    return 0
  } else if (width >= t && width <= s) {
    return 1
  } else if (width >= s && width <= m) {
    return 2
  } else if (width >= m && width <= l) {
    return 3
  } else if (width >= l) {
    return 4
  }
}

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = throttle(() => setBreakpoint(getBreakpoint(window.innerWidth)), 200)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return breakpoint
}
export default useBreakpoint
