import { createSlice } from '@redux'

export const initialState = null

const slice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    setInsightsData: (state, { payload }) => {
      return { ...state, ...payload }
    },
    setInsightsBankData: (state, { payload }) => {
      return { ...state, ...payload }
    },
    setInsightsBankDecisionMetrics: (state, { payload }) => {
      return { ...state, decisionMetrics: payload }
    },
    setInsightsDepositData: (state, { payload }) => {
      return { ...state, depositData: payload }
    },
    setInsightsMthlyDepositData: (state, { payload }) => {
      return { ...state, monthlyDepositData: payload }
    },
    clear: () => {
      return initialState
    },
  },
})

export { default as getInsights } from './getInsights'
export { default as getInsightsBankData } from './getInsightsBankData'
export { default as getInsightsBankWklyDepositData } from './getInsightsBankWklyDepositData'
export { default as getInsightsBankDecisionMetrics } from './getInsightsBankDecisionMetrics'
export { default as getCreditWatchScores } from './getCreditWatchScores'
export { default as getCreditWatchScoresQuery } from './getCreditWatchScoresQuery'
export { default as getInsightsBankMthlyDepositData } from './getInsightsBankMthlyDepositData'
export const {
  clear,
  setInsightsData,
  setInsightsBankData,
  setInsightsBankDecisionMetrics,
  setInsightsDepositData,
  setInsightsMthlyDepositData,
} = slice.actions
export default slice.reducer
