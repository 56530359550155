import React from 'react'
import cls from './index.module.scss'
import mixpanel from 'mixpanel-browser'
import { PropellMixPanelEnabled } from '@Apps/..'

const ButtonV5 = ({ children, primary, ...props }) => {
  return (
    <div
      {...props}
      className={primary ? cls.btnPrimary : cls.btn}
      onClick={e => {
        if (PropellMixPanelEnabled) mixpanel.track('ButtonOnClick', { source: `${children}` })
        if ('onClick' in props) props.onClick(e)
      }}
    >
      {children}
    </div>
  )
}

export default ButtonV5
