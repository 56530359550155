const normalizeInput2Currency = v => {
  if (v) {
    const number = Number(v.replace(/[^0-9.]+/g, ''))
    if (number) {
      const normalizeOutput = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }).format(number)
      return normalizeOutput
    } else {
      return v
    }
  } else {
    return v
  }
}
export default normalizeInput2Currency
