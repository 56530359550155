import { initialState as alerts } from '@features/alerts'
import { initialState as business } from '@features/business'
import { initialState as employees } from '@features/employees'
import { initialState as layout } from '@Dashboard/layout'
import { initialState as login } from '@features/auth/login'
import { initialState as register } from '@features/auth/register'
import { initialState as originationsChoice } from '@features/originations/choice'
import { initialState as originationsForm } from '@features/originations/form'
import { initialState as originationsLookup } from '@features/originations/lookup'
import { initialState as originationsStep } from '@features/originations/step'
import { initialState as originationsbusinessForm } from '@features/originations/businessForm'
import { initialState as payments } from '@features/payments'
import { initialState as user } from '@features/user'
import { initialState as lending } from '@features/lendings'
import { initialState as tradeLending } from '@features/tradeLendings'
import { initialState as insights } from '@features/insights'
import { initialState as insightsUi } from '@features/insightsUi'
import { initialState as critical } from '@features/critical'
import { initialState as uiStateHelper } from '@features/uiStateHelper'
import { initialState as routingStateHelper } from '@features/routingStateHelper'
import { initialState as propellTradePayment } from '@features/propellTradePayment'
import { initialState as merchants } from '@features/merchants'
import { initialState as brokerConnectUi } from '@features/brokerConnectUi'

const initialState = {
  alerts,
  auth: {
    login,
    register,
  },
  business,
  critical,
  employees,
  layout,
  lending,
  tradeLending,
  insights,
  insightsUi,
  brokerConnectUi,
  originations: {
    choice: originationsChoice,
    form: originationsForm,
    lookup: originationsLookup,
    step: originationsStep,
    business: originationsbusinessForm,
  },
  payments,
  propellTradePayment,
  merchants,
  user,
  uiStateHelper,
  routingStateHelper,
}
export default initialState
