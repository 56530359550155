import React, { useState, useEffect } from 'react'
import {
  getInsightsBankData,
  getInsightsBankWklyDepositData,
  getInsightsBankMthlyDepositData,
  getCreditWatchScoresQuery,
  getInsights,
  getInsightsBankDecisionMetrics,
} from '@features/insights'
//import { useBankConnections } from '@hooks'
import { usePropellBankDataCtx } from '../'
import { useSelector, useAction } from '@redux'
import useBarChartData from './useBarChartData'
import useBankDepositData from './useBankDepositData'
import useDecisionMetric from './useDecisionMetric'
import useInsightsData from './useInsightsData'
import { parseISO, format } from 'date-fns'
import { adaptBarchartCardData } from './utils'
import { sleep } from '@lib'

const testWithDummy = false
const inOutDummy = [
  { vPlus: 2000, vMinus: -40, label: 'J' },
  { vPlus: 1000, vMinus: -30, label: 'F' },
  { vPlus: 130, vMinus: -50, label: 'M' },
  { vPlus: 120, vMinus: -10, label: 'A' },
  { vPlus: 300, vMinus: -40, label: 'M' },
  { vPlus: 90, vMinus: -120, label: 'J' },
  { vPlus: 50, vMinus: 0, label: 'J' },
  { vPlus: 60, vMinus: -20, label: 'A' },
  { vPlus: 70, vMinus: -10, label: 'S' },
  { vPlus: 900, vMinus: -700, label: 'O' },
  { vPlus: 700, vMinus: -600, label: 'N' },
  { vPlus: 50, vMinus: -30, label: 'D' },
]

export const PropellBpDataCtx = React.createContext(null)
export const usePropellBpDataCtx = () => {
  const {
    apiInsights,
    insightsData,
    business,
    depositData,
    dailyBalanceData,
    inOutDiffData,
    creditScoreHistories,
    adbData,
    decisionMetricsData,
    bankFeedData,
    bpPageHelper,
    dataRdy,
    fetchingCompleted,
    bpAnimationCtl,
  } = React.useContext(PropellBpDataCtx)

  return {
    apiInsights,
    insightsData,
    business,
    depositData,
    dailyBalanceData,
    inOutDiffData,
    creditScoreHistories,
    adbData,
    decisionMetricsData,
    bankFeedData,
    bpPageHelper,
    dataRdy,
    fetchingCompleted,
    bpAnimationCtl,
  }
}

const PropellBpDataProvider = ({ children }) => {
  // const currentTimeHr = new Date().getHours()
  const bid = useSelector(({ business }) => business?.id)
  const apiCreditScoreHistories = useSelector(({ insights }) => insights?.detailHistory?.creditScoreHistories)
  const apiMonthlyDepositData = useSelector(({ insights }) => insights?.monthlyDepositData)
  const apiInsights = useSelector(({ insights }) => insights)
  const business = useSelector(state => state.business)

  const {
    depositData: apiWklyDepositData,
    depositMaxValue,
    depositMinValue,
    mthlyDepositData: apiMthlyDepositData,
    mthlyDepositMaxValue,
    mthlyDepositMinValue,
    wklyAdbData,
    wklyAdbMax,
    wklyAdbMin,
    wklyAdbAvg,
  } = useBankDepositData(bid)
  const [dailyBalanceAv, setDailyBalanceAv] = useState({ value: 0, percent: 0 })
  const [dailyBalanceData, setDailyBalanceData] = useState([])
  const [balanceChartMode, setBalanceChartMode] = useState(false)
  const [depositChartMode, setDepositChartMode] = useState(false)
  const [mnthlyDepositAv, setMnthlyDepositAv] = useState({ value: 0, percent: 0 })
  const [mnthlyDepositData, setMnthlyDepositData] = useState([])
  const [wklyDepositAv, setWklyDepositAv] = useState({ value: 0, percent: 0 })
  const [wklyDepositData, setWklyDepositData] = useState([])
  const [inOutDiffData, setInOutDiffData] = useState(testWithDummy ? inOutDummy : [])
  const [creditScoreHistories, setCreditScoreHistories] = useState([])
  const [adbWklyHistories, setAdbWklyHistories] = useState([])
  const [adbWklyAvg, setAdbWklyAvg] = useState({ value: 0, percent: 0 })
  const { barChartData, barChartMaxValue, barChartMinValue } = useBarChartData(bid)
  //const decisionMetrics = useSelector(state => state?.insights?.decisionMetrics)
  //const [dishoursCnt, setDishoursCnt] = useState(0)
  const decisionMetricsData = useDecisionMetric(bid)
  const [dataRdy, setDataRdy] = useState({
    creditScoreHistoriesRdy: false,
    depositDataRdy: false,
    wklyAdbDataRdy: false,
    dailyAdbDataRdy: false,
    inoutChartDataRdy: false,
  })

  const fetchInsightsBankWklyDepositData = useAction(getInsightsBankWklyDepositData)
  const fetchInsightsBankMthlyDepositData = useAction(getInsightsBankMthlyDepositData)
  const getCreditWatchScoresQueryAction = useAction(getCreditWatchScoresQuery)
  const fetchInsightsBankData = useAction(getInsightsBankData)
  const fetchInsights = useAction(getInsights)
  const fetchInsightsBankDecisionMetrics = useAction(getInsightsBankDecisionMetrics)
  const {
    adb,
    adbNum,
    amd,
    din,
    mdb,
    totalBalance,
    totalCredits,
    totalDebits,
    allLoanDebit,
  } = useInsightsData(bid)
  const {
    bankStartFetch,
    bankFetchCompleted,
    bankFetching,
    bankConnections,
    primaryAccounts,
    primaryBankIsMfa,
  } = usePropellBankDataCtx()
  const [fetchingCompleted, setFetchingCompleted] = useState({
    getCreditWatchScoresQuery: false,
    fetchInsightsBankDeposit: false,
    fetchInsightsBank: false,
    fetchInsights: false,
    fetchInsightsBankDecisionMetrics: false,
  })
  const [bpAnimationCtl, setBpAnimationCtl] = useState(1)
  useEffect(() => {
    ;(async () => {
      bankStartFetch(true)
      await fetchInsights(bid)
      setFetchingCompleted({
        fetchInsights: true,
        getCreditWatchScoresQuery: false,
        fetchInsightsBankDeposit: false,
        fetchInsightsBank: false,
        fetchInsightsBankDecisionMetrics: false,
      })
      await getCreditWatchScoresQueryAction(bid)
      setFetchingCompleted({
        fetchInsights: true,
        getCreditWatchScoresQuery: true,
        fetchInsightsBankDeposit: false,
        fetchInsightsBank: false,
        fetchInsightsBankDecisionMetrics: false,
      })
      await fetchInsightsBankWklyDepositData(bid)
      await fetchInsightsBankMthlyDepositData(bid)
      setFetchingCompleted({
        fetchInsights: true,
        getCreditWatchScoresQuery: true,
        fetchInsightsBankDeposit: true,
        fetchInsightsBank: false,
        fetchInsightsBankDecisionMetrics: false,
      })
      await fetchInsightsBankData(bid)
      setFetchingCompleted({
        fetchInsights: true,
        getCreditWatchScoresQuery: true,
        fetchInsightsBankDeposit: true,
        fetchInsightsBank: true,
        fetchInsightsBankDecisionMetrics: false,
      })
      await fetchInsightsBankDecisionMetrics(bid)
      setFetchingCompleted({
        fetchInsights: true,
        getCreditWatchScoresQuery: true,
        fetchInsightsBankDeposit: true,
        fetchInsightsBank: true,
        fetchInsightsBankDecisionMetrics: true,
      })
      await sleep(2000)
      setBpAnimationCtl(2)
    })()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (apiWklyDepositData?.length > 0 && apiMthlyDepositData?.length > 0) {
      const obj = adaptBarchartCardData(apiWklyDepositData, depositMaxValue, depositMinValue)
      setWklyDepositData(obj.data)
      setWklyDepositAv(obj.average)

      const mthlyObj = adaptBarchartCardData(apiMthlyDepositData, mthlyDepositMaxValue, mthlyDepositMinValue)
      setMnthlyDepositData(mthlyObj.data)
      setMnthlyDepositAv(mthlyObj.average)
      setDataRdy({ ...dataRdy, depositDataRdy: true })
    }

    if (wklyAdbData) {
      const mwklyAdbData = wklyAdbData.map(r => {
        return { ...r, value: r.value }
      })
      const sorted = [...mwklyAdbData].map(e => e.value).sort((a, b) => a - b)
      const max = sorted[sorted.length - 1]
      const min = sorted[0]
      const objAdbWkly = adaptBarchartCardData(mwklyAdbData, max, min)
      setAdbWklyHistories(objAdbWkly.data)
      setAdbWklyAvg(objAdbWkly.average)
      setDataRdy({ ...dataRdy, wklyAdbDataRdy: true })
    }

    if (barChartData) {
      const dailyBalanceobj = adaptBarchartCardData(barChartData, barChartMaxValue, barChartMinValue)
      setDailyBalanceData(dailyBalanceobj.data)
      setDailyBalanceAv(dailyBalanceobj.average)
      setDataRdy({ ...dataRdy, dailyAdbDataRdy: true })
    }
    // eslint-disable-next-line
  }, [
    barChartData,
    barChartMaxValue,
    barChartMinValue,
    apiMthlyDepositData,
    mthlyDepositMaxValue,
    mthlyDepositMinValue,
    apiWklyDepositData,
    depositMaxValue,
    depositMinValue,
    wklyAdbData,
    wklyAdbMax,
    wklyAdbMin,
    wklyAdbAvg,
  ])

  useEffect(() => {
    const creditHistories = [['Date', 'Score']]
    if (apiCreditScoreHistories?.length > 0) {
      const history = [...apiCreditScoreHistories].reverse()
      history.forEach(o => {
        const pair = [o.date, parseFloat(o.score)]
        creditHistories.push(pair)
      })
    } else {
      for (var i = 0; i < 5; i++) creditHistories.push([i, parseFloat(0)])
    }
    setCreditScoreHistories(creditHistories)
    setDataRdy({ ...dataRdy, creditScoreHistoriesRdy: true })
    // eslint-disable-next-line
  }, [apiCreditScoreHistories])

  useEffect(() => {
    //console.log('apiMonthlyDepositData change 0')
    if (apiMonthlyDepositData) {
      //console.log('apiMonthlyDepositData change 1')
      if (apiMonthlyDepositData?.monthlyDeposits && apiMonthlyDepositData?.monthlyOutgoing) {
        //console.log('apiMonthlyDepositData change 2')
        const MONTHS_INITIAL = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']
        const rawDeposits = Object.entries(apiMonthlyDepositData.monthlyDeposits)
        const rawOutgoing = Object.entries(apiMonthlyDepositData.monthlyOutgoing)
        const chartData = rawDeposits.map((d, idx) => {
          const date = parseISO(d[0])
          const month = date.getMonth()
          const dateLabel = MONTHS_INITIAL[month]
          const inValue = parseFloat(d[1])
          const outValue = rawOutgoing[idx]?.length > 0 ? parseFloat(rawOutgoing[idx][1]) : 0
          const formatedDate = format(date, 'MM/yyyy')
          return { vPlus: inValue, vMinus: outValue, label: dateLabel, date: formatedDate }
        })
        //console.log('chartData ' + JSON.stringify(chartData))
        const test = false
        if (!test) setInOutDiffData(chartData)

        setDataRdy({ ...dataRdy, inoutChartDataRdy: true })
      }
    }
    // eslint-disable-next-line
  }, [apiMonthlyDepositData])

  return (
    <PropellBpDataCtx.Provider
      value={{
        apiInsights,
        business,
        insightsData: {
          adb,
          adbNum,
          amd,
          din,
          mdb,
          totalBalance,
          totalCredits,
          totalDebits,
          allLoanDebit,
        },
        depositData: {
          mnthlyDepositAv,
          mnthlyDepositData,
          wklyDepositAv,
          wklyDepositData,
        },
        dailyBalanceData: {
          barChartData,
          barChartMaxValue,
          barChartMinValue,
        },
        inOutDiffData,
        creditScoreHistories,
        adbData: {
          adbWklyHistories,
          adbWklyAvg,
          dailyBalanceAv,
          dailyBalanceData,
        },
        decisionMetricsData,
        bankFeedData: {
          bankConnections,
          primaryAccounts,
          bankFetching,
          bankStartFetch,
          bankFetchCompleted,
          primaryBankIsMfa,
        },
        bpPageHelper: {
          balanceChartMode,
          depositChartMode,
          setBalanceChartMode,
          setDepositChartMode,
        },
        dataRdy,
        fetchingCompleted,
        bpAnimationCtl,
      }}
    >
      {children}
    </PropellBpDataCtx.Provider>
  )
}
export default PropellBpDataProvider
