import api from '@api'
import { setConsolidateLoans, setConsolidatedLoansDataFlag, addLoans } from './index.js'
import { pageSize } from './index.js'

export const getLoansConsolidationRequest = () => async (dispatch, getState) => {
  var page = 1
  var doneFetching = false
  const loans = []
  try {
    await dispatch(setConsolidatedLoansDataFlag(false))
    while (!doneFetching) {
      const res = await api.get(`/v1/lendings/loans/refinance-requests?page=${page}&per_page=${pageSize}`)
      if (res.data.length > 0) loans.push(...res.data)
      if (res.data.length < pageSize) doneFetching = true
      page++
    }

    if (loans?.length > 0 && parseInt(loans[0]?.customerConsolidationTotal) > 0) {
      const underRefinancedLoans = loans
        .map(l => {
          if (l?.refinancedApplicationSfid) {
            return null
          } else {
            return {
              id: 'pending-externalLoan',
              name: 'External Loan',
              loanAmount: loans[0]?.customerConsolidationTotal,
              status: 'External(Under review)',
              applicationDate: new Date(l?.createdAt * 1000).toISOString(),
              isExternalRefinanced: false,
            }
          }
        })
        .filter(l => l !== null)
      //console.log('underRefinancedLoans ' + JSON.stringify(underRefinancedLoans))
      underRefinancedLoans.forEach(async o => {
        await dispatch(addLoans(o))
      })
      // const externalLoanObj = {
      //   id: 'pending-externalLoan',
      //   name: 'External Loan',
      //   loanAmount: loans[0]?.customerConsolidationTotal,
      //   status: 'External(Under review)',
      //   applicationDate: new Date(loans[0]?.createdAt * 1000).toISOString(),
      //   isExternalRefinanced: false,
      // }
      //await dispatch(addLoans(externalLoanObj))
    }
    await dispatch(setConsolidateLoans(loans))
    await dispatch(setConsolidatedLoansDataFlag(true))
    return loans
  } catch (error) {
    doneFetching = true
    console.error(error)
  }
}

export default getLoansConsolidationRequest
